/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OpponentAvailability = "AVAILABLE" | "DUELLING" | "INVITED" | "UNAVAILABLE";
export type NewDuelSearchResult_result = {
    readonly availability: OpponentAvailability;
    readonly invitedSince: string | null;
    readonly rating: number;
    readonly user: {
        readonly id: string;
        readonly fullNameForDuelling: string;
        readonly profileImageForDuelling: string | null;
        readonly hasAnonymousDuellingEnabled: boolean;
    };
    readonly " $refType": "NewDuelSearchResult_result";
};
export type NewDuelSearchResult_result$data = NewDuelSearchResult_result;
export type NewDuelSearchResult_result$key = {
    readonly " $data"?: NewDuelSearchResult_result$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NewDuelSearchResult_result">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewDuelSearchResult_result",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availability",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitedSince",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullNameForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAnonymousDuellingEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "PossibleOpponent",
    "abstractKey": null
} as any;
(node as any).hash = 'b4afaef598ba42ea0bee7d4460e5b37e';
export default node;

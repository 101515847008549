/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelRequestPopup_item = {
    readonly id: string;
    readonly challenger: {
        readonly " $fragmentRefs": FragmentRefs<"DuelVersusHeader_right">;
    };
    readonly opponent: {
        readonly " $fragmentRefs": FragmentRefs<"DuelVersusHeader_left">;
    } | null;
    readonly " $refType": "DuelRequestPopup_item";
};
export type DuelRequestPopup_item$data = DuelRequestPopup_item;
export type DuelRequestPopup_item$key = {
    readonly " $data"?: DuelRequestPopup_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelRequestPopup_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuelRequestPopup_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "challenger",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_right"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "opponent",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_left"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "DuelRequest",
    "abstractKey": null
} as any;
(node as any).hash = '86bf48350bd0b7f1ad4b59d06f5c23ce';
export default node;

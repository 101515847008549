/**
 * Assert that something is true. Throws an error if not.
 *
 * @param assertion
 * @param message
 */
export function assert(
  assertion: boolean,
  message?: string
): asserts assertion {
  if (!assertion) {
    throw new Error('Assertion failed! ' + (message || ''))
  }
}

/**
 * Assert that the value for _ is of type never. Should result in a compile error
 * when this assertion is invalid. At runtime, throws an error.
 *
 * @param _
 * @param message The message for the error to throw.
 */
export function never(_: never, message: string): never {
  throw new Error(message)
}

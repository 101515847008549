import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'

import { Header } from '../../containers/Header'
import { useStores } from '../../stores'
import { PlayDuelRound as PlayDuelRoundProps } from '../../stores/commonStore'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { PrimaryButton } from '../common/PrimaryButton'
import { DuelRoundsOverview } from './DuelRoundsOverview'
import { DuelVersusHeader } from './DuelVersusHeader'

import styles from './PlayDuelRound.scss'

export function PlayDuelRound(props: PlayDuelRoundProps): ReactElement {
  const { commonStore } = useStores()
  const { t, i18n } = useTranslation()
  const { onPlay } = props

  const duel = useFragment(
    graphql`
      fragment PlayDuelRound_duel on Duel {
        ownRoundNumber
        players {
          user {
            isMe
          }
          ...DuelVersusHeader_left
          ...DuelVersusHeader_right
          rounds {
            ...DuelRoundsOverview_left
            ...DuelRoundsOverview_right
          }
        }
        ...DuelRoundsOverview_duel
      }
    `,
    props.duel
  )

  const onButtonClicked = useCallback(() => {
    onPlay()
    ;(props.onClose ?? commonStore.closePopup)()
  }, [commonStore.closePopup, onPlay, props.onClose])
  const onButtonKeyPress = useEnterKeyHandler(onButtonClicked)

  const opponent = duel.players.find((player) => !player.user.isMe)
  const self = duel.players.find((player) => player.user.isMe)

  if (!opponent || !self) {
    ;(props.onClose ?? commonStore.closePopup)()

    return <div />
  }

  return (
    <>
      <Header duel wide>
        <DuelVersusHeader type='PLAY' left={opponent} right={self} round={-1} />
      </Header>

      <div className={styles.content}>
        <DuelRoundsOverview
          currentRound={duel.ownRoundNumber}
          left={opponent.rounds}
          leftTimedOut={false}
          right={self.rounds}
          rightTimedOut={false}
          duel={duel}
        />

        <div className={styles.button}>
          <PrimaryButton
            onClick={onButtonClicked}
            onKeyPress={onButtonKeyPress}
            tabIndex={0}
          >
            {t('streamItem.duelItem.playItem', {
              roundnumber: new Intl.NumberFormat(i18n.language).format(
                Math.max(1, duel.ownRoundNumber)
              ),
            })}
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}

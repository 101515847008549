import { ElementType, ReactElement } from 'react'

import { ExtendableComponentProps } from '../../types/utils'
import { classNames } from '../../utils/classNames'

export type ButtonProps<C extends ElementType> = ExtendableComponentProps<
  {
    defaultComponent: 'button'
    props: {
      icon?: boolean
    }
  },
  C
>

interface BaseButtonProps {
  buttonClassName: string
  iconButtonClassName: string
}

export function Button<C extends ElementType>(
  props: ButtonProps<C> & BaseButtonProps
): ReactElement {
  const {
    className: classNameInput,
    buttonClassName,
    iconButtonClassName,
    icon,
    children,
    componentRef: ref,
    ...rest
  } = { ...props }

  let Component: ElementType = 'button'
  if ('component' in rest) {
    Component = rest.component
    delete rest.component
  }

  const className = classNames(
    buttonClassName,
    { [iconButtonClassName]: icon },
    classNameInput
  )

  return (
    <Component className={className} {...rest} ref={ref}>
      {children}
    </Component>
  )
}

import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useMutation } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'

import { Page } from '../../../containers/Page'
import { useStores } from '../../../stores'
import { Severity } from '../../../stores/authStore'
import { AuthAlert } from '../../auth/AuthAlert'
import { AuthFooter } from '../../auth/AuthFooter'
import { AuthHeader } from '../../auth/AuthHeader'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './MultiFactorAuthenticationPage.scss'
import { useFragment, useQuery } from 'relay-hooks'
import {
  MfaType,
  MultiFactorAuthenticationPageLoginMutation,
} from '../../../generated/MultiFactorAuthenticationPageLoginMutation.graphql'
import { MultiFactorAuthenticationPageQuery } from '../../../generated/MultiFactorAuthenticationPageQuery.graphql'
import { SecondaryButton } from '../../common/SecondaryButton'
import { MultiFactorAuthenticationPageSendMfaTokenMutation } from '../../../generated/MultiFactorAuthenticationPageSendMfaTokenMutation.graphql'
import { MultiFactorAuthenticationPage_user$key } from '../../../generated/MultiFactorAuthenticationPage_user.graphql'
import { TertiaryButton } from '../../common/TertiaryButton'
import { useEnvironment } from '../../../App'
import { classNames } from '../../../utils/classNames'

interface MultiFactorAuthenticationPageProps {
  user?: MultiFactorAuthenticationPage_user$key | null
}

export const MultiFactorAuthenticationPage = observer(
  function MultiFactorAuthenticationPage(
    props: MultiFactorAuthenticationPageProps
  ) {
    const { commonStore, authStore } = useStores()
    const [mfaType, setMfaType] = useState<MfaType>('GOOGLE')
    const [token, setToken] = useState('')
    const [secret, setSecret] = useState('')
    const [submittable, setSubmittable] = useState(false)
    const [copied, setCopied] = useState(false)
    const { mfa } = useEnvironment()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const to =
      params.get('to') ??
      (location.state && 'to' in location.state ? location.state.to : null)
    const activateMfa =
      params.get('activate') === 'true' ||
      (location.state && 'activate' in location.state
        ? location.state.activate
        : null)
    const resendEmailTimeout = 30
    const [resendEmail, setResendEmail] = useState(0)
    const numericCodeRef = useRef<HTMLInputElement | null>(null)

    const user = useFragment(
      graphql`
        fragment MultiFactorAuthenticationPage_user on AuthenticatedUser {
          mfaType
        }
      `,
      props.user || null
    )

    const mfaQrCode = useQuery<MultiFactorAuthenticationPageQuery>(
      graphql`
        query MultiFactorAuthenticationPageQuery {
          mfaQrCode {
            secret
            qrCodeInline
          }
        }
      `,
      {},
      {
        fetchPolicy: 'store-and-network',
      }
    )

    const [sendMfaToken] =
      useMutation<MultiFactorAuthenticationPageSendMfaTokenMutation>(
        graphql`
          mutation MultiFactorAuthenticationPageSendMfaTokenMutation {
            sendMfaToken {
              message
              success
            }
          }
        `
      )

    const requestEmailMfaToken = useCallback((): void => {
      if (resendEmail === 0 && mfa.emailEnabled) {
        sendMfaToken({ variables: {} }).then((response) => {
          if (response.sendMfaToken.message) {
            authStore.setAuthMessage(
              response.sendMfaToken.message,
              Severity.ERROR
            )
          }
        })

        setResendEmail(resendEmailTimeout)
      }
    }, [authStore, mfa.emailEnabled, resendEmail, sendMfaToken])

    useEffect(() => {
      if (resendEmail > 0) {
        setTimeout(() => setResendEmail(resendEmail - 1), 1000)
      }
    }, [resendEmail])

    useEffect(() => {
      setSubmittable(token !== '')
    }, [token])

    useEffect(() => {
      if (mfaQrCode.data) {
        setSecret(mfaQrCode.data?.mfaQrCode.secret)
      }
    }, [mfaQrCode, activateMfa])

    useEffect(() => {
      const oldNavBarHidden = commonStore.navBarHidden
      if (to?.startsWith('/cms')) {
        commonStore.setNavBarHidden(true)
      }

      return () => {
        commonStore.setNavBarHidden(oldNavBarHidden)
      }
    }, [commonStore, to])

    useEffect(() => {
      if (user) {
        setMfaType(
          user.mfaType === 'EMAIL' && mfa.emailEnabled ? 'EMAIL' : 'GOOGLE'
        )

        if (user.mfaType === 'EMAIL') {
          requestEmailMfaToken()
        }
      }
    }, [user])

    const [validate] = useMutation<MultiFactorAuthenticationPageLoginMutation>(
      graphql`
        mutation MultiFactorAuthenticationPageLoginMutation(
          $mfaType: MfaType!
          $secret: String!
          $token: String!
        ) {
          validateMfa(mfaType: $mfaType, secret: $secret, token: $token) {
            message
            success
          }
        }
      `,
      {
        variables: {
          mfaType: mfaType,
          secret:
            activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
              ? secret
              : '',
          token: token,
        },
      }
    )

    const onSubmitClicked = useCallback(
      (event: FormEvent): void => {
        event.preventDefault()

        if (!submittable) {
          return
        }

        setSubmittable(false)

        validate({
          variables: {
            mfaType: mfaType,
            secret:
              activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
                ? secret
                : '',
            token: token,
          },
        }).then((response) => {
          if (response.validateMfa.success) {
            if (to) {
              window.location.pathname = to
            } else {
              window.location.pathname = '/'
            }
          } else {
            if (response.validateMfa.message) {
              authStore.setAuthMessage(
                response.validateMfa.message,
                Severity.ERROR
              )
            }
          }
        })
      },
      [validate, mfaType, activateMfa, secret, token, to, authStore]
    )

    const setValidationType = useCallback(
      (newMfaType: MfaType, e): void => {
        e.preventDefault()

        setMfaType(newMfaType)
        setToken('')

        if (newMfaType === 'EMAIL') {
          requestEmailMfaToken()
        }

        numericCodeRef.current?.focus()
      },
      [requestEmailMfaToken]
    )

    const copyToClipboard = useCallback((value): void => {
      navigator.clipboard.writeText(value)
      setCopied(true)
    }, [])

    const { t } = useTranslation()

    const query = new URLSearchParams(location.search)
    const errorMessage = query.get('message')

    // Set an auth error message if we received one.
    useEffect(() => {
      if (errorMessage) {
        authStore.setAuthMessage(errorMessage, Severity.ERROR)
      }
    }, [authStore, errorMessage])

    return (
      <>
        <AuthHeader />
        <Page hideWatermark narrow>
          <div className={styles.formHeader}>
            <h1 className={styles.head}>
              {activateMfa || (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)
                ? t('auth.header.activateMfa')
                : t('auth.header.mfa')}
            </h1>
          </div>

          <form
            aria-live='polite'
            method='post'
            action='#'
            className={styles.formContainer}
            onSubmit={onSubmitClicked}
          >
            <AuthAlert />

            {(activateMfa ||
              (user?.mfaType === 'EMAIL' && !mfa.emailEnabled)) &&
            mfaType === 'GOOGLE' &&
            mfaQrCode.data ? (
              <>
                <div className={classNames(styles.formGroup, styles.center)}>
                  {t('auth.action.activateGoogleMfa')}
                </div>
                <div className={classNames(styles.formGroup, styles.center)}>
                  <img
                    src={mfaQrCode.data?.mfaQrCode.qrCodeInline}
                    alt='MFA QR Code'
                  />
                </div>

                <div
                  className={classNames(
                    styles.formGroup,
                    styles.center,
                    styles.secret
                  )}
                >
                  <SecondaryButton
                    aria-label={t('auth.action.mfaCopySecretKey')}
                    onClick={() =>
                      copyToClipboard(mfaQrCode.data?.mfaQrCode.secret)
                    }
                  >
                    <div aria-hidden={true}>
                      {t('auth.field.mfaSecretKey', {
                        secret: mfaQrCode.data?.mfaQrCode.secret,
                      })}
                    </div>
                    <div className={styles.icon} aria-hidden={true}>
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                    <div
                      className={classNames(styles.copy, {
                        [styles.show]: copied,
                        [styles.hide]: !copied,
                      })}
                    >
                      <span aria-hidden={true}>{t('auth.field.copied')}</span>
                      <span className='sr-only'>
                        {t('auth.field.copiedToClipboard')}
                      </span>
                    </div>
                  </SecondaryButton>
                </div>
                <div className={classNames(styles.formGroup, styles.center)}>
                  {t('auth.action.activateGoogleMfaStep2')}
                </div>
              </>
            ) : (
              <div className={classNames(styles.formGroup, styles.center)}>
                {t(
                  'auth.action.' +
                    (mfaType === 'EMAIL' && mfa.emailEnabled
                      ? 'email'
                      : 'google') +
                    'Mfa'
                )}
              </div>
            )}

            <div className={classNames(styles.formGroup, styles.prepend)}>
              <div className={styles.prependIcon} aria-hidden={true}>
                <FontAwesomeIcon icon={faLock} />
              </div>
              <input
                ref={numericCodeRef}
                autoComplete='off'
                className={classNames(
                  styles.formControl,
                  styles.prependPadding
                )}
                placeholder={t('auth.field.mfaToken')}
                name='mfa_token'
                onChange={(e) => {
                  setToken(e.target.value)
                }}
                type='text'
              />
            </div>

            <div className={styles.formFooter}>
              <div>
                <PrimaryButton
                  aria-disabled={!submittable}
                  aria-label={t('auth.action.mfaValidateNumericCode')}
                  className={classNames(
                    styles.primaryButton,
                    styles.validateButton
                  )}
                  onClick={onSubmitClicked}
                >
                  {t('auth.action.validate')}
                </PrimaryButton>
                {mfaType === 'EMAIL' && mfa.emailEnabled && (
                  <SecondaryButton
                    aria-disabled={!(resendEmail === 0)}
                    className={styles.secondaryResend}
                    onClick={requestEmailMfaToken}
                  >
                    <span aria-hidden={true} aria-live='off'>
                      {t('auth.action.mfaResendEmail', {
                        seconds:
                          resendEmail > 0 ? ' (' + resendEmail + ')' : '',
                      })}
                    </span>
                    <span className='sr-only'>
                      {t('auth.action.mfaResendEmail')}
                    </span>
                  </SecondaryButton>
                )}
              </div>

              {(activateMfa || mfaType === 'GOOGLE') && mfa.emailEnabled && (
                <>
                  <div
                    className={classNames(
                      styles.formGroup,
                      styles.center,
                      styles.or
                    )}
                  >
                    {t('auth.field.or')}
                  </div>
                  <div>
                    <TertiaryButton
                      className={styles.secondaryButton}
                      onClick={(e) =>
                        setValidationType(
                          mfaType === 'EMAIL' && mfa.emailEnabled
                            ? 'GOOGLE'
                            : 'EMAIL',
                          e
                        )
                      }
                    >
                      {t(
                        'auth.action.mfa' +
                          (mfaType === 'EMAIL' && mfa.emailEnabled
                            ? 'App'
                            : 'Email') +
                          'Validation'
                      )}
                    </TertiaryButton>
                  </div>
                </>
              )}
            </div>
          </form>
        </Page>

        <AuthFooter />
      </>
    )
  }
)

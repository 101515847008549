import { AxiosResponse } from 'axios'
import { wait } from './wait'

export function handleThrottling(response: AxiosResponse): Promise<void> {
  let retryAfter = response.headers['retry-after'] as number | undefined
  if (!retryAfter) {
    const serverTime = new Date(response.headers.date).getTime() / 1000
    const retryAt = response.headers['x-ratelimit-reset'] as number

    retryAfter = retryAt - serverTime
  }

  return wait(retryAfter * 1000)
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DuellingNotAvailablePopupEnableDuelsMutationVariables = {};
export type DuellingNotAvailablePopupEnableDuelsMutationResponse = {
    readonly toggleDuelPreference: {
        readonly id: string;
        readonly hasDuellingEnabled: boolean;
    };
};
export type DuellingNotAvailablePopupEnableDuelsMutationRawResponse = {
    readonly toggleDuelPreference: {
        readonly id: string;
        readonly hasDuellingEnabled: boolean;
    };
};
export type DuellingNotAvailablePopupEnableDuelsMutation = {
    readonly response: DuellingNotAvailablePopupEnableDuelsMutationResponse;
    readonly variables: DuellingNotAvailablePopupEnableDuelsMutationVariables;
    readonly rawResponse: DuellingNotAvailablePopupEnableDuelsMutationRawResponse;
};



/*
mutation DuellingNotAvailablePopupEnableDuelsMutation {
  toggleDuelPreference(preference: DUELLING_ENABLED, enabled: true) {
    id
    hasDuellingEnabled
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "enabled",
                    "value": true
                },
                {
                    "kind": "Literal",
                    "name": "preference",
                    "value": "DUELLING_ENABLED"
                }
            ],
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "toggleDuelPreference",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": "toggleDuelPreference(enabled:true,preference:\"DUELLING_ENABLED\")"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DuellingNotAvailablePopupEnableDuelsMutation",
            "selections": (v0 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DuellingNotAvailablePopupEnableDuelsMutation",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "67320eb4771c13a55bf64340c7b5702c",
            "id": null,
            "metadata": {},
            "name": "DuellingNotAvailablePopupEnableDuelsMutation",
            "operationKind": "mutation",
            "text": "mutation DuellingNotAvailablePopupEnableDuelsMutation {\n  toggleDuelPreference(preference: DUELLING_ENABLED, enabled: true) {\n    id\n    hasDuellingEnabled\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bbe821eda5d7dafba4a5ad019bec4894';
export default node;

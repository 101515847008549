import { ElementType, ReactElement } from 'react'

import { Button, ButtonProps } from './Button'

import styles from './PrimaryButton.scss'

export function PrimaryButton<C extends ElementType>(
  props: ButtonProps<C>
): ReactElement {
  return (
    <Button
      {...props}
      buttonClassName={styles.primaryButton}
      iconButtonClassName={styles.iconButton}
    />
  )
}

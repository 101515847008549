/* eslint-disable relay/unused-fields */
import * as Sentry from '@sentry/browser'
import { observer } from 'mobx-react'
import { createContext, ReactElement, useContext, useEffect } from 'react'
import { RelayEnvironmentProvider } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { enablePushNotifications, init } from '../js/jsBridge'

import styles from './App.scss'
import { ErrorBoundary } from './components/common/ErrorBoundary'
import { Routing } from './components/common/Routing'
import { environment } from './environment'
import {
  AppDataQuery,
  AppDataQueryResponse,
} from './generated/AppDataQuery.graphql'
import { StoreProvider, useStores } from './stores'

export const EnvironmentContext = createContext<
  AppDataQueryResponse['settings']
>({
  appLogos: {
    fullColorLogoUrl: null,
    whiteLogoUrl: null,
  },
  backgroundImageUrl: null,
  customUpToDateScoreThreshold: false,
  editableTranslations: [],
  language: 'en-US',
  lmsReferrer: '',
  loginWithCompanyUserId: false,
  organization: 'Bright Alley',
  organizationLogoUrl: null,
  organizationWatermarkLogoUrl: null,
  pageTitle: 'PowerApp',
  product: 'POWERAPP',
  selfActivateWithCorporateId: false,
  showScore: false,
  showTopicScore: false,
  hidePracticeAllButton: false,
  supportUrl: 'https://www.powerapp.nl/faq',
  onFireSupportUrl: 'https://service.onfireonboarding.nl',
  privacyPolicyUrl: 'https://www.powerapp.nl/privacy-policy',
  anonymousDuelling: 'OFF',
  singleSignOn: {
    idpOnly: false,
    idps: [],
  },
  mfa: {
    enabled: false,
    emailEnabled: true,
    cms: 'REQUIRED',
    app: 'DISABLED',
  },
})

export function useEnvironment(): AppDataQueryResponse['settings'] {
  return useContext(EnvironmentContext)
}

export const App = observer(function App(): ReactElement {
  useEffect(init, [init])

  return (
    <div className={styles.app}>
      <ErrorBoundary>
        <RelayEnvironmentProvider environment={environment}>
          <StoreProvider>
            <InnerApp />
          </StoreProvider>
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    </div>
  )
})

function AddToHomescreenPrompt() {
  // Detects if device is on iOS
  const isIos = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase()

    return /iphone|ipad|ipod/.test(userAgent)
  }

  // Detects if device is in standalone mode
  const isInStandaloneMode = (): boolean =>
    'standalone' in window.navigator && !!window.navigator.standalone

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      // Disable no-undef because it's available globally
      // eslint-disable-next-line no-undef
      addToHomescreen({ skipFirstVisit: true })
    }
  }, [])

  return <></>
}

function InnerApp(): ReactElement {
  const { authStore, commonStore } = useStores()

  const appData = useQuery<AppDataQuery>(
    graphql`
      query AppDataQuery {
        me {
          __typename
          id
          email
          language
        }
        settings {
          appLogos {
            fullColorLogoUrl
            whiteLogoUrl
          }
          backgroundImageUrl
          customUpToDateScoreThreshold
          editableTranslations {
            language
            translations {
              key
              value
            }
          }
          language
          lmsReferrer
          loginWithCompanyUserId
          organization
          organizationLogoUrl
          organizationWatermarkLogoUrl
          pageTitle
          product
          selfActivateWithCorporateId
          showScore
          showTopicScore
          hidePracticeAllButton
          singleSignOn {
            idpOnly
            idps {
              description {
                language
                value
              }
              displayName {
                language
                value
              }
              isHybrid
              loginUrl
              name
              setPasswordInstruction {
                language
                value
              }
            }
          }
          supportUrl
          onFireSupportUrl
          privacyPolicyUrl
          anonymousDuelling
          mfa {
            enabled
            emailEnabled
            cms
            app
          }
        }
      }
    `
  )

  useEffect(() => {
    if (!appData.data) {
      return
    }

    if (appData.data.me) {
      enablePushNotifications()

      commonStore.setLanguage(appData.data.me.language)

      Sentry.setUser({
        email: appData.data.me.email,
        id: appData.data.me.id,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [authStore, commonStore, appData.data])

  useEffect(() => {
    if (appData.data) {
      document.title = appData.data.settings.pageTitle
    }
  }, [appData.data, appData.data?.settings.pageTitle])

  return appData.data ? (
    <EnvironmentContext.Provider value={appData.data.settings}>
      <AddToHomescreenPrompt />
      <Routing />
    </EnvironmentContext.Provider>
  ) : (
    <div className='loading-placeholder'>
      <img
        alt='PowerApp logo'
        src={process.env.APP_URL + '/style/favicon-310x310.png'}
      />

      <div className='loading-indicator'>
        <div className='loader' />
      </div>
    </div>
  )
}

import { useRef } from 'react'

function dependenciesHaveChanged(
  previousDependencies: unknown[],
  dependencies: unknown[]
) {
  return (
    previousDependencies.length !== dependencies.length ||
    previousDependencies.filter((value, index) => value !== dependencies[index])
      .length > 0
  )
}

export function useDebounce(
  ms: number,
  callback: () => void,
  dependencies: unknown[]
): void {
  const handle = useRef<number | null>(null)
  const previousDependencies = useRef<unknown[] | null>(null)

  if (
    handle.current === null ||
    (previousDependencies.current !== null &&
      dependenciesHaveChanged(previousDependencies.current, dependencies))
  ) {
    if (handle.current) {
      window.clearTimeout(handle.current)
    }

    previousDependencies.current = dependencies

    handle.current = window.setTimeout(callback, ms)
  }
}

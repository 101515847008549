import { ElementType, ReactElement } from 'react'

import { Button, ButtonProps } from './Button'

import styles from './TertiaryButton.scss'

export function TertiaryButton<C extends ElementType>(
  props: ButtonProps<C>
): ReactElement {
  return (
    <Button
      {...props}
      buttonClassName={styles.tertiaryButton}
      iconButtonClassName={styles.iconButton}
    />
  )
}

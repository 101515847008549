import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { Header } from '../../containers/Header'
import { DuelFinalizingPopupFinalizeDuelMutation } from '../../generated/DuelFinalizingPopupFinalizeDuelMutation.graphql'
import { useStores } from '../../stores'
import { FinalizingDuelPopup as DuelFinalizingPopupProps } from '../../stores/commonStore'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import { PrimaryButton } from '../common/PrimaryButton'

import styles from './DuelFinalizingPopup.scss'
import { DuelRoundsOverview } from './DuelRoundsOverview'
import { DuelVersusHeader } from './DuelVersusHeader'

export function DuelFinalizingPopup(
  props: DuelFinalizingPopupProps
): ReactElement {
  const { duelsConnectionIds } = props
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const duel = useFragment(
    graphql`
      fragment DuelFinalizingPopup_item on Duel {
        id
        status
        players {
          timedOut
          rounds {
            ...DuelRoundsOverview_left
            ...DuelRoundsOverview_right
          }
          user {
            isMe
          }
          ...DuelVersusHeader_left
          ...DuelVersusHeader_right
        }
        ...DuelRoundsOverview_duel
      }
    `,
    props.item
  )
  const [finalizeDuel] = useMutation<DuelFinalizingPopupFinalizeDuelMutation>(
    graphql`
      mutation DuelFinalizingPopupFinalizeDuelMutation(
        $id: ID!
        $duelsConnectionIds: [ID!]!
      ) {
        finalizeDuel(id: $id) {
          result {
            id @deleteEdge(connections: $duelsConnectionIds)
          }
        }
      }
    `,
    { variables: { id: duel.id, duelsConnectionIds } }
  )

  const { onClose } = props

  const onCloseHandler = useCallback((): void => {
    finalizeDuel().then(() => {
      location.reload() // Temporary fix for infinite "finalize" loop.
      onClose ? onClose() : commonStore.closePopup()
    })
  }, [finalizeDuel, onClose, commonStore])
  const onCloseEnterHandler = useEnterKeyHandler(onCloseHandler)

  const left = duel.players.find((player) => !player.user.isMe)
  const right = duel.players.find((player) => player.user.isMe)

  if (!left || !right) {
    ;(onClose ?? commonStore.closePopup)()
    return <div />
  }

  return (
    <>
      <Header duel wide>
        <DuelVersusHeader
          left={left}
          right={right}
          type={duel.status}
          round={-1}
        />
      </Header>

      <div className={styles.content}>
        <DuelRoundsOverview
          left={left.rounds}
          leftTimedOut={left.timedOut}
          right={right.rounds}
          rightTimedOut={right.timedOut}
          duel={duel}
        />

        <div className={styles.button}>
          <PrimaryButton
            onClick={onCloseHandler}
            onKeyPress={onCloseEnterHandler}
          >
            {t('common.Close')}
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}

import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMutation } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'

import { Header } from '../../containers/Header'
import { DuellingNotAvailablePopupEnableDuelsMutation } from '../../generated/DuellingNotAvailablePopupEnableDuelsMutation.graphql'
import { DuellingNotAvailablePopupUserQuery } from '../../generated/DuellingNotAvailablePopupUserQuery.graphql'
import { useStores } from '../../stores'
import { Avatar, AvatarContext } from '../common/Avatar'
import { GraphQlError } from '../common/GraphQlError'
import { PrimaryButton } from '../common/PrimaryButton'

import styles from './DuellingNotAvailablePopup.scss'
import { useEnvironment } from '../../App'

export function DuellingNotAvailablePopup(): ReactElement {
  const environment = useEnvironment()
  const { commonStore } = useStores()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [enableDuellingMutation, enableDuellingResult] =
    useMutation<DuellingNotAvailablePopupEnableDuelsMutation>(
      graphql`
        mutation DuellingNotAvailablePopupEnableDuelsMutation
        @raw_response_type {
          toggleDuelPreference(preference: DUELLING_ENABLED, enabled: true) {
            id
            hasDuellingEnabled
          }
        }
      `,
      { variables: {} }
    )
  const user = useQuery<DuellingNotAvailablePopupUserQuery>(graphql`
    query DuellingNotAvailablePopupUserQuery {
      me {
        fullName
        hasDuellingAvailable
        hasDuellingEnabled
        id
        profileImage
      }
    }
  `)

  const enableDuelling = useCallback(() => {
    setLoading(true)

    enableDuellingMutation({
      optimisticResponse: {
        toggleDuelPreference: {
          id: user.data?.me?.id || '',
          hasDuellingEnabled: true,
        },
      },
    })
      .then(() => {
        commonStore.closePopup()

        navigate('/duels')
      })
      .catch(() => setLoading(false))
  }, [enableDuellingMutation, user.data?.me?.id, commonStore, navigate])

  if (
    !user.data?.me ||
    (user.data.me.hasDuellingAvailable && user.data.me.hasDuellingEnabled)
  ) {
    commonStore.closePopup()
    return <div />
  }

  return (
    <>
      <Header duel wide>
        <div className={styles.header}>
          <h2 className={styles.headline} id='popup-label'>
            {t('menu.duels')}
          </h2>
          <p className={styles.sub}>{t('menu.duelsNotAvailable')}</p>

          <Avatar
            filename={user.data.me.profileImage}
            name={user.data.me.fullName}
            className={styles.avatar}
            userId={user.data.me.id}
            context={AvatarContext.default}
          />
        </div>
      </Header>

      {!user.data.me.hasDuellingAvailable ? (
        <p className={styles.explanation}>
          {t('menu.duelsNotAvailableExplanation', {
            appName: environment.pageTitle,
          })}
        </p>
      ) : (
        <>
          <p className={styles.explanation}>{t('menu.duelsNotEnabled')}</p>
          <p className={styles.disclaimer}>
            {t('menu.duelsNotEnabledDisclaimer')}
          </p>

          {enableDuellingResult.error && (
            <GraphQlError
              error={enableDuellingResult.error}
              retry={enableDuelling}
            />
          )}

          <div className={styles.buttonContainer}>
            <PrimaryButton
              disabled={loading}
              onClick={enableDuelling}
              tabIndex={0}
            >
              {t('menu.enableDuelling')}
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  )
}

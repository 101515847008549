/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type authStoreLogoutMutationVariables = {};
export type authStoreLogoutMutationResponse = {
    readonly logout: {
        readonly success: boolean;
    };
};
export type authStoreLogoutMutation = {
    readonly response: authStoreLogoutMutationResponse;
    readonly variables: authStoreLogoutMutationVariables;
};



/*
mutation authStoreLogoutMutation {
  logout {
    success
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Result",
            "kind": "LinkedField",
            "name": "logout",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "authStoreLogoutMutation",
            "selections": (v0 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "authStoreLogoutMutation",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "3c90afdf841d2fb90104097dc7c0915d",
            "id": null,
            "metadata": {},
            "name": "authStoreLogoutMutation",
            "operationKind": "mutation",
            "text": "mutation authStoreLogoutMutation {\n  logout {\n    success\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bbe3d9a94585090300945cb837be4f57';
export default node;

import { faTools } from '@fortawesome/pro-regular-svg-icons/faTools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Header } from '../../containers/Header'
import { useStores } from '../../stores'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import styles from './MaintenanceModePopup.scss'
import { PrimaryButton } from './PrimaryButton'

export function MaintenanceModePopup(): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const refresh = useCallback((): void => {
    commonStore.closePopup()

    location.reload()
  }, [commonStore])
  const refreshEnterKeyHandler = useEnterKeyHandler(refresh)

  return (
    <div className={styles.popup}>
      <Header wide>
        <h1 id='popup-label'>{t('error.maintenance.title')}</h1>

        <div className={styles.circle}>
          <FontAwesomeIcon icon={faTools} />
        </div>
      </Header>

      <div className={styles.explanation}>
        {t('error.maintenance.subtitle')}
      </div>

      <PrimaryButton
        className={styles.button}
        onClick={refresh}
        onKeyPress={refreshEnterKeyHandler}
        tabIndex={0}
      >
        {t('error.retry')}
      </PrimaryButton>
    </div>
  )
}

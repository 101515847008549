const translations = {
  popup: {
    confirm: {
      delete: 'Διαγραφή',
      keep: 'ακυρώνω',
      cancelDuelInviteTitle: 'Διαγραφή;',
      cancelDuelInviteMessage: 'Είστε σίγουροι;',
    },
    repetitionStateInfo: {
      heading: 'Δείκτης εξάσκησης',
      explanation: {
        general:
          'Ο δείκτης εξάσκησης σου προσφέρει άμεσα πληροφορίες για το αν είναι σκόπιμο να εξασκηθείς ή όχι. Έχει τρεις ξεχωριστές καταστάσεις. Φαντάσου τον σαν φανάρι.',
        alert: 'Κόκκινο: Οι γνώσεις σου χειροτέρεψαν. Ήρθε η ώρα για εξάσκηση.',
        warning:
          'Πορτοκαλί: Υπάρχουν ακόμη ερωτήσεις που σε περιμένουν. Μόλις ο δείκτης γίνει πράσινος θα έχεις ενημερώσει τις γνώσεις σου.',
        complete:
          'Πράσινο: Τέλεια, είσαι πλήρως ενημερωμένος! Επέστρεψε αργότερα.',
      },
    },
  },
  header: {
    'welcome-message': 'Γεια σου {{name}}!',
    welcome: 'Καλωσόρισες.',
    welcomeBack: 'Καλωσόρισες ξανά.',
  },
  learn: {
    instruction: 'Επέλεξε τα θέματα με τα οποία θέλεις να παίξεις.',
    practiceSelection: 'Εξασκήσου με αυτή την επιλογή',
    selectAll: 'Επιλογή όλων',
    deselectAll: 'Καταργήστε την επιλογή',
    wellDone: 'Μπράβο!',
    topicCategorySelectionIndicator: 'Θέμα {{current}}/{{countFormatted}}',
    practiceAllNecessary: {
      message:
        '<p><b>Θέλεις να ενημερώσεις γρήγορα τις γνώσεις σου;</b></p><p>Απάντησε σε αυτή τη σειρά ερωτήσεων για να επιτύχεις την πράσινη κατάσταση το συντομότερο δυνατό.</p>',
      upToDateMessage:
        '<p><b>Τα πας θαυμάσια!</b></p><p>Θέλεις να παραμείνεις ενημερωμένος; Συνέχισε να εξασκείσαι!</p>',
      practiceTopics: 'Προεπιλεγμένες σειρές εξάσκησης',
    },
    topics: {
      toDo: 'Πρέπει να κάνεις',
      upToDate: 'Ενημερωμένος',
      practiceCheckbox: 'Εξάσκηση',
      extraPracticeButton: 'Πρόσθετη εξάσκηση',
    },
    repetitionState: {
      header: {
        complete: 'Πολύ καλά. Είσαι ενημερωμένος!',
        warning: 'Συνέχισε να εξασκείσαι για να παραμείνεις ενημερωμένος.',
        alert: 'Είναι ώρα να ξεκινήσεις την εξάσκηση.',
        alertFirstLogin: 'Μπορείς να ξεκινήσεις την εξάσκηση τώρα.',
        showScore: 'Η συνολική βαθμολογία σου: {{score}}%',
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'Είσαι ενημερωμένος',
        upToDate: 'Είσαι ήδη ενημερωμένος',
        nrToUpToDate: '{{nr}} απομένουν',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'Επανάληψη',
      newQuestion: 'Νέα!',
    },
  },
  duels: {
    title: 'Αξιολόγηση μονομαχίας: {{score}}',
    readyToDuel: 'Είσαι έτοιμος για τη μονομαχία;',
    roundsOverview: 'Επισκόπηση των γύρων μονομαχίας',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Προηγούμενη σελίδα',
      doneText: 'Brainsnack ολοκληρώθηκε',
      browseNext: 'Επόμενη σελίδα',
    },
    brainItem: {
      correct: 'Σωστό',
      incorrect: 'Λάθος',
      completed: 'Ολοκλήρωσες μια ερώτηση',
      doneText: 'Ολοκληρώθηκε',
      openItem: 'Έναρξη',
      closeItem: 'Κλείσιμο',
      nextItem: 'Συνέχισε την εξάσκηση',
      showProgress: 'Εμφάνισε την πρόοδό σου',
      topics: 'Κεφαλαια',
    },
    order: {
      questionTitle: 'Ταξινόμηση',
      helpText: 'Σείρε τις απαντήσεις στη σωστή σειρά',
      correct: '{{num}} από {{max}} σωστά',
    },
    match: {
      questionTitle: 'Αντιστοίχιση',
      showingCorrectAnswer: 'Δες τις σωστές απαντήσεις.',
      helpText: 'Σείρε τις απαντήσεις στο σωστό σημείο',
      correct: '{{num}} από {{max}} σωστά',
      showCorrectAnswer: 'Εμφάνισε την σωστή απάντηση',
      showMyAnswer: 'Εμφάνισε την απάντησή μου',
    },
    slider: {
      doneText: 'Ολοκληρώθηκε',
      questionTitle: 'Ρυθμιστικό',
      helpText: 'Σύρετε τον δρομέα στη σωστή θέση',
    },
    poll: {
      questionTitle: 'Δημοσκόπηση',
      resultinfo: 'Αυτό  απάντησαν οι άλλοι',
      doneText: 'Απάντησες σε ερώτηση δημοσκόπησης',
      helpText: 'Επέλεξε μια απάντηση',
    },
    notification: {
      title: 'Γνωστοποίηση',
      pauze: 'Μπορώ να συνεχίσω;',
      continueanyway: 'Συνέχισε ούτως ή άλλως',
      doneText: 'Ανάγνωση ειδοποίησης',
      continueLearningText:
        "Εκπαιδεύεσαι με επιπλέον ερωτήσεις. Θα λάβεις λιγότερα σημεία γι 'αυτά επειδή είσαι ήδη ενημερωμένος.",
      panelbuttonlabel: 'Διακοπή περαιτέρω εξάσκησης',
    },
    multipleselect: {
      correct: '{{num}} από {{max}} σωστά',
      incorrect: ', {{incorrect}} λάθος',
      doneText: 'Η ερώτηση ολοκληρώθηκε ',
      questionTitle: 'Ερώτηση',
      helpText: 'Επέλεξε μία ή περισσότερες απαντήσεις',
    },
    multiplechoice: {
      doneText: 'Η ερώτηση ολοκληρώθηκε ',
      questionTitle: 'Ερώτηση',
      helpText: 'Επέλεξε μια απάντηση',
    },
    duelItem: {
      duel: 'Μονομαχία',
      draw: 'Ισοπαλία',
      invite: 'Πρόκληση για μονομαχία',
      acceptDuel: 'Αποδοχή μονομαχίας',
      declineDuel: 'Απόρριψη μονομαχίας ',
      yourTurn: 'Σειρά σου',
      opponentTurn: 'Στροφή του αντιπάλου',
      youWon: 'Κέρδισες!',
      youLost: 'Έχασες ',
      cancelled: 'Η μονομαχία ακυρώνεται',
      closeItem: 'Κλείσιμο',
      showDetails: 'Λεπτομέρειες παιχνιδιού',
      playItem: 'Αρ. Παιχνιδιού {{roundnumber}}',
      duelAccepted: 'Δέχτηκες μια προσκληση για μονομαχία',
      duelDeclined: 'Απέρριψες μια προσκληση για μονομαχίας',
      duelStarted: 'Ας αρχίσει η μονομαχία με {{name}}. Καλή τύχη!',
      duelInfoInvitation: 'Σε προκαλούν σε μονομαχία.',
      duelInfoMain:
        'Μια σύντομη μάχη γνώσης 5 κύκλων. Κέρδισε απαντώντας γρήγορα και σωστά. Κέρδισε τους περισσότερους κύκλους και κέρδισε τη μονομαχία και αύξησε την βαθμολογία σου. Σημείωσε ότι υπάρχει ένα όριο 72 ωρών για να παίξεις ένα κύκλο.',
      viewInvitation: 'Προβολή πρόσκλησης',
      versusLabel: 'εναντίον',
      started: 'Έναρξη!',
      doneText: 'Η μονομαχια ολοκληρώθηκε',
      tooLate: 'Τέλος χρόνου!',
      practiceWhileWaiting: 'Πρακτική κατά την αναμονή',
      practice: 'Συνέχισε την εξάσκηση',
      subheading: {
        waitForOpponent: 'Περίμενε να απαντήσει ο αντίπαλός σου',
        yourTurn: 'Είσαι έτοιμος να ξεκινήσεις ;',
        timeOutOpponent: 'Ο αντίπαλός σου πήρε πολύ χρόνο να απαντήσει',
        timeOutPlayer: 'Άργησες να απαντήσεις',
        timeOutBoth: 'Και οι δύο χρειάστηκατε πολύ χρόνο να απαντήσετε',
        wellDone: 'Πολύ καλά, συνέχισε έτσι!',
        betterNextTime: 'Καλύτερη τύχη την επόμενη φορά!',
      },
      roundNumber: 'Γύρος {{number}}',
      duelRequest: 'Νέα πρόκληση μονομαχίας!',
      duelRequestText: 'Ο/η {{name}} σε προκαλεί σε μονομαχία',
      tooLateTurn: 'Πολύ αργά',
    },
    topicPin: {
      title: 'Επισήμανση θέματος',
      pinenabled:
        'Έχεις επισημάνει το {{- topiclabel}} και βλέπεις μόνο στοιχεία που σχετίζονται με αυτό το θέμα. Τα στοιχεία που σχετίζονται με αυτό το θέμα επαναλαμβάνονται συχνότερα, ώστε να μπορείτε να τα εξασκήσεις. Επέλεξε πάλι το το επισημασμένο θέμα για να συμπεριλάβεις ερωτήσεις σχετικές και  με άλλα θέματα.',
      pindisabled:
        'Το θέμα δεν είναι πια συνδεδεμένο. Από εδώ και στο εξής, θα βλέπεις πάλι τα στοιχεία για όλα τα θέματα.',
      continue: 'Συνέχισε',
      selectTopics: 'Επιλογή θεμάτων',
      selectTopicsPractice:
        'Επέλεξε τα θέματα με τα οποία θέλεις να εξασκηθείς.',
      dontshowagain: 'Να μην εμφανίζετε ξανά',
    },
    topicInfoHeader: 'Θέματα',
  },
  login: {
    succesful: 'Επιτυχής σύνδεση.',
  },
  menu: {
    menu: 'Μενού',
    duel: 'Μονομαχία',
    duels: 'Μονομαχίες',
    newDuel: 'Νέα μονομαχία',
    activeDuels: 'Ενεργές μονομαχίες ',
    duelVersus: 'ΕΝΑΝΤΙΟΝ',
    ranking: 'Σειρά κατάταξης',
    rating: 'Αξιολόγηση μονομαχίας',
    profile: 'Προφίλ',
    autoDuelLabel: 'Αυτόματες μονομαχίες',
    'help-and-settings': 'Βοήθεια & Ρυθμίσεις',
    challengeplayer: 'Προκάλεσε έναν συνάδελφο',
    pickplayer: 'Επέλεξε έναν συνάδελφο',
    randomplayer: 'Αισθάνομαι τυχερός',
    duelWaiting: 'Ψάχνεις για αντίπαλο…',
    quickDuelFailed: 'Έχετε ήδη προσκλήσεις σε εκκρεμότητα',
    duelsNotAvailable: 'Οι μονομαχίες δεν είναι ακόμη διαθέσιμες για εσάς.',
    duelsNotAvailableExplanation:
      'Θέλεις να παίξεις μονομαχίες με συναδέλφους; Ρωτήστε τον Διαχειριστή PowerApp για αυτό.',
    duelsNotEnabled:
      'Θέλεις να παίξεις μονομαχίες με συναδέλφους; Ενεργοποίησε την επιλογή μονομαχίας παρακάτω.',
    duelsNotEnabledDisclaimer:
      'Η φωτογραφία και το όνομά σου θα είναι ορατά στους συναδέλφους σου.',
    enableDuelling: 'Ενεργοποίηση μονομαχιών με συναδέλφους!',
    duellingEnabledLabel: 'Συμμετοχή σε μονομαχίες',
    completedDuels: 'Ολοκληρώθηκε',
    select: 'Επιλογή',
    settings: 'Ρυθμίσεις',
    practice: 'Εξάσκηση',
    aboutDuels: {
      heading: 'Τι είναι η πρόσκληση σε μονομαχία;',
      explanation1: 'Έχεις προκληθεί σε μονομαχία.',
      explanation2:
        'Η μονομαχία είναι ένα σύντομο παιχνίδι γνώσεων 5 γύρων εναντίον κάποιου συναδέλφου. Κερδίζεις έναν γύρο αν απαντήσεις σωστά στην ερώτηση γρηγορότερα από τον/τη συνάδελφό σου. Αν κερδίσεις περισσότερους γύρους από τον/την αντίπαλό σου, κερδίζεις τη μονομαχία και ανεβαίνεις στην κατάταξη.',
      attention: 'Σημείωση:',
      explanation3: 'Παίξε τον κάθε γύρο εντός 7 ημερών!',
    },
    leaveAlert: {
      title: 'Είσαι βέβαιος/η;',
      text: '<p>Είσαι βέβαιος/η ότι θέλεις να σταματήσεις την εξάσκηση;</p><p>Η πρόοδός σου αποθηκεύτηκε.</p>',
      confirm: 'Διακοπή',
      continue: 'Συνέχισε την εξάσκηση',
    },
    info: 'Πληροφορίες',
  },
  ranking: {
    title: 'Κατάταξη μονομαχίας',
    subtitle:
      'Αυτή η βαθμολογία δείχνει τη θέση  που κατέχετε στην μονομαχία σας, σε σχέση με τους συναδέλφους σας.',
    loadMore: 'Φορτώστε περισσότερο',
    yourPosition: 'Η θέση σου ({{rank}})',
    topPositions: 'Κορυφαίες θέσεις',
    empty: 'Δεν υπάρχει κατάταξη, ξεκίνησε μια μονομαχία!',
    notRanked: 'Τελείωσε μια μονομαχία για να προστεθεί στην κατάταξη!',
  },
  searchFeedback: {
    title: 'Αναζήτηση συναδέλφου',
    oneResult: '{{amount}}  συνάδελφος βρέθηκε.',
    searchMessage: '{{amount}}  συνάδελφοι βρέθηκαν.',
    overflowMessage:
      'Εμφανίζονται μόνο τα {{amount}} καλύτερα αποτελέσματα αναζήτησης που ταιριάζουν.',
  },
  searchDuel: {
    title: 'Αναζήτηση αντιπάλου',
    rankingposition: 'Θέση κατάταξης {{rank}}',
    noduelpossible: 'Η μονομοαχία δεν είναι δυνατή',
    duelstillactive: 'Ήδη ενεργή μονομαχία',
    invitationsentondate: 'Η πρόσκληση στάλθηκε στις {{date}}',
    randomOpponent:
      'Αναζητούμε τον επόμενο αντίπαλό σου. Μπορείς να κλείσεις αυτή την οθόνη, όταν η μονομαχία είναι έτοιμη θα σε ενημερώσουμε.',
    searchPlaceholder: 'Αναζήτηση ονόματος',
    emptyListSearch: 'Δεν υπάρχουν αποτελέσματα αναζήτησης. ',
    emptyList:
      'Δυστυχώς, δεν υπάρχει κάποιος/α διαθέσιμος/η.<br>Δοκίμασε ξανά αργότερα. ',
  },
  activeDuels: {
    title: 'Παίξε μια διαθέσιμη μονομαχία.',
    subtitle: '{{num}}/{{max}} μονομαχίες είναι ενεργές',
    status: {
      draw: 'Ισοπαλία ',
      lost: 'Χάθηκε',
      won: 'Κερδήθηκε ',
      expired: 'Έληξε',
      invited: 'Έγινε πρόσκληση',
      waiting: 'Σε αναμονή',
      yourturn: 'Παίξε',
    },
    emptyListNew: 'Δεν υπάρχει μονομαχία, ξεκίνησε μια νέα μονομαχία!',
    emptyListCompleted:
      'Ολοκληρώθηκαν όλες οι μονομαχίες, μπράβο!<br>Πιες έναν καφέ ως επιβράβευση.',
  },
  completedDuels: {
    title: 'Δες τις ολοκληρωμένες μονομαχίες σου.',
    empty:
      'Δεν τελείωσες ακόμα κάποια μονομαχία.<br>Ξεκίνησε μια νέα μονομαχία!',
  },
  error: {
    404: 'Ο διακομιστής δεν βρέθηκε (HTTP 404).',
    500: 'Εσωτερικό σφάλμα διακομιστή (HTTP 500).',
    internetdown: 'Απώλεια σύνδεσης στο διαδίκτυο!',
    refreshButton: 'Κάνε κλικ για ανανέωση',
    internetup: 'Συνδεδεμένο με το διαδίκτυο.',
    timeoutajax: 'Το αίτημα του διακομηστή έληξε.',
    unknown: 'Άγνωστο σφάλμα',
    tryReconnect: 'Προσπάθεια επανασύνδεσης …',
    alreadyAnswered: 'Στοιχείο που έχει ήδη απαντηθεί.',
    unavailableOffline: {
      title: 'Δεν υπάρχει σύνδεση',
      subtitle: 'Αυτή τη στιγμή δεν υπάρχει σύνδεση',
      explanation:
        'Απαιτείται σύνδεση στο διαδίκτυο για τη χρήση της εφαρμογής. Δεν υπάρχει σύνδεση τη συγκεκριμένη ώρα. Έλεγξε αν μπορείς να επανασυνδεθείς στο internet ή δοκίμασε ξανά αργότερα.',
    },
    versionConflict: {
      title: 'Νέα έκδοση',
      text: 'Υπάρχει νέα έκδοση αυτής της εφαρμογής. Για να εξασφαλιστεί ότι όλοι χρησιμοποιούν την πιο πρόσφατη έκδοση θα πραγματοποιήσουμε την ενημέρωση στην πιο πρόσφατη έκδοση τώρα. ',
      refresh: 'Μετάβαση στην πιο πρόσφατη έκδοση ',
    },
  },
  common: {
    CheckAnswers: 'Έλεγχος απαντήσεων',
    CheckAnswer: 'Έλεγχος απάντησης',
    Emailaddress: 'email',
    Name: 'Όνομα',
    Checking: 'Πραγματοποιείται Έλεγχος…',
    Answer: 'Αποθήκευση της απάντησής μου',
    Close: 'Κλείσιμο',
    browsePrevious: 'Προηγούμενος',
    browseNext: 'Επόμενο',
    Accept: 'Αποδοχή',
    'Log out': 'Αποσύνδεση',
    Help: 'Βοήθεια',
    Correct: 'Σωστό',
    Incorrect: 'Λάθος',
    CorrectNotChosen: 'Θα ήταν σωστό',
    Round: 'Κύκλος',
    Version: 'Έκδοση',
    selected: 'Επιλεγμένο',
    'No Rating': 'Δεν έχει βαθμολογηθεί ακόμα',
    'No Rank': 'Καμία βαθμολογία',
    NoUserSelection: 'Δεν βρέθηκαν χρήστες',
    monthNames: 'Ιαν Φεβ Μαρ Απρ Μάΐος Ιουν Ιουλ Αυγ Σεπ Οκτ Νοε Δεκ',
    or: 'ή',
    on: 'ανοιχτό',
    off: 'κλειστό',
    Hello: 'Γεια σου',
    back: 'Πίσω',
  },
  profile: {
    title: 'Προφίλ',
    subtitle: 'Προσάρμοσε το προφίλ σου εδώ.',
    pictureEdit: 'Δες ή επεξεργάσου την εικόνα σου.',
    'file-submit': 'Αναφόρτωση',
    'picture-submit': 'Χρησιμοποίησε αυτή την περικοπή',
    pictureCancel: 'Ακύρωση',
    'picture-nofileselected': 'Δεν επιλέξατε αρχείο.',
    'picture-nofile': 'Δεν υπάρχει αρχείο.',
    'picture-invalidsize':
      'Αυτή η εικόνα είναι πολύ μεγάλη. Το μέγιστο πλάτος ή ύψος δεν μπορεί να υπερβαίνει τα 2600 pixel.',
    'picture-notimage': 'Το αρχείο που επέλεξες δεν φαίνεται να είναι εικόνα.',
    pictureConfirmDelete:
      'Είσαι βέβαιος/η ότι θέλεις να διαγραφεί η εικόνα του προφίλ σου;',
    profilePageTitle: 'Διαχειρίσου το προφίλ σου.',
    noEditDataText: 'Δεν μπορείς να αλλάξεις αυτά τα δεδομένα.',
    passwordEdit: 'Άλλαξε κωδικό',
    'picture-saved': 'Η φωτογραφία του προφίλ σου αποθηκεύεται!',
    passwordTitle: 'Άλλαξε κωδικό',
    passwordText:
      'Άλλαξε τον κωδικό πρόσβασής σου πληκτρολογώντας τον τρέχοντα κωδικό πρόσβασής σου και 2 φορές τον νέο κωδικό πρόσβασής σου. Ο νέος κωδικός πρόσβασης πρέπει να περιλαμβάνει τουλάχιστον 6 χαρακτήρες.',
    'password-old': 'Τρέχων κωδικός πρόσβασης',
    'password-new': 'Νέος κωδικός πρόσβασης',
    'password-repeat': 'Επιβεβαιώσε τον καινούριο σου κωδικό',
    'password-submit': 'Αποθηκεύστε νέο κωδικό πρόσβασης',
    'password-oldpasswordmismatch':
      'Ο τρέχων κωδικός πρόσβασής σου ήταν εσφαλμένος',
    'password-newpasswordmismatch':
      'Ο επιβεβαιωμένος κωδικός πρόσβασης δεν ταιριάζει.',
    'password-passwordtooshort':
      'Ο νέος κωδικός πρόσβασης πρέπει να περιλαμβάνει τουλάχιστον 6 χαρακτήρες.',
    'password-passwordsaved': 'Ο κωδικός πρόσβασής σου αποθηκεύεται!',
    profilePictureTitle: 'Εικόνα προφίλ',
    profilePictureText:
      'Αυτή η φωτογραφία είναι ορατή για τους συναδέλφους σας.',
    generalDataTitle: 'Προσωπικές πληροφορίες',
    generalDataText:
      'Δεν μπορείς να αλλάξεις όλα τα προσωπικά δεδομένα από μόνος/η σου.',
    profileLanguagePreference: 'Προτίμηση γλώσσας',
    profileLanguageChoice: 'Επιλογή γλώσσας',
  },
  auth: {
    header: {
      login: 'Σύνδεση',
      expired: 'Η σύνδεση έχει λήξει',
      remind: 'Δεν μπορείς να θυμηθείς τον κωδικό πρόσβασης;',
      create: 'Ενεργοποίησε τον λογαριασμό σου',
      reset: 'Ενημέρωσε τον κωδικό πρόσβασης',
      welcome: 'Καλωσόρισες!',
    },
    field: {
      email: 'Διεύθυνση Email',
      password: 'Κωδικός πρόσβασης',
      oldPassword: 'Τρέχων κωδικός πρόσβασης',
      newPassword: 'Νέος κωδικός πρόσβασης',
      newRepeatPassword: 'Επανάληψη νέου κωδικού πρόσβασης',
      rememberMe: 'Απομνημόνευση των στοιχείων μου',
    },
    action: {
      itemcompleted: 'Ολοκλήρωσες ένα στοιχείο',
      updateprofile: 'Ενημέρωση προφίλ',
      sendLink: 'Να μου αποσταλεί σύνδεση επαναφοράς κωδικού πρόσβασης',
      backToLogin: 'Θέλω να συνδεθώ',
      sendActivateLink: 'Ενεργοποίηση λογαριασμού',
      alreadyActivatedLogin:
        'Έχω ήδη ενεργοποιήσει τον λογαριασμό και θέλω να συνδεθώ',
      savePassword: 'Αποθήκευση του νέου μου κωδικού πρόσβασης',
      newPassword: 'Ο νέος σου κωδικός πρόσβασης',
      repeatNewPassword: 'Επανάληψη του νέου σου κωδικού πρόσβασης',
      newPasswordPolicyIntro: 'Ισχύς κωδικού πρόσβασης',
    },
    logintext: 'Σύνδεση',
    remindText:
      'Κανένα πρόβλημα. Θα σου στείλουμε ένα email έτσι ώστε να μπορείς να ορίσεις έναν νέο κωδικό πρόσβασης.',
    resetText: 'Μπορείς να ορίσεις τον νέο σου κωδικό πρόσβασης εδώ.',
    createText:
      'Καλωσόρισες! Δημιούργησε έναν νέο κωδικό πρόσβασης και μετά θα είσαι έτοιμος/η να συνδεθείς.',
    passwords: {
      user: 'Δυστυχώς, δεν είναι δυνατή η εύρεση ενός χρήστη με αυτή τη διεύθυνση e-mail.',
      token:
        'Αυτό το διακριτικό επαναφοράς κωδικού πρόσβασης δεν είναι έγκυρο.',
      sent: ' Έχει αποσταλεί ένα email με σύνδεση επαναφοράς.',
      reset: 'Έγινε επαναφορά του κωδικού πρόσβασης!',
      forgot: 'Δεν γνωρίζω τον κωδικό πρόσβασής μου',
      passwordExpiredTitle: 'Ο κωδικός πρόσβασης έληξε',
      submitPasswordExpiredForm: 'Αποθήκευση νέου κωδικού πρόσβασης',
      passwordExpiredText:
        'Ο κωδικός πρόσβασής σου είναι παλαιότερος από {{days}} ημέρες και έχει λήξει. Θα χρειαστεί να ορίσεις έναν νέο κωδικό πρόσβασης χρησιμοποιώντας την παρακάτω φόρμα, στη συνέχεια μπορείς να συνεχίσεις την εκμάθηση στην PowerApp',
    },
    selfActivate: {
      header: 'Eνεργοποίησε το λογαριασμό σου',
    },
  },
  faq: {
    header: 'Συνήθεις ερωτήσεις',
    q1: 'Η PowerApp είναι μια εφαρμογή μικρομάθησης. Τι σημαίνει αυτό;',
    a1: 'Η μικρομάθηση σημαίνει ότι είναι αποτελεσματικότερο να μαθαίνεις με σύντομα μαθήματα αν ο στόχος σου είναι να διατηρήσεις περισσότερες γνώσεις σε βάθος χρόνου.',
    q2: 'Γιατί η βαθμολογία μου μερικές φορές είναι χαμηλότερη από αυτή που περίμενα;',
    a2: 'Η βαθμολογία σου μειώνεται γιατί με την πάροδο του χρόνου ξεχνάς αυτά που έχεις μάθει.',
    q3: 'Πότε μειώνεται η βαθμολογία μου;',
    a3: 'Όταν απαντάς ανακριβώς στις ερωτήσεις ή παραμένεις ανενεργός/ή για κάποιο διάστημα.',
    q4: 'Γιατί επαναλαμβάνονται τα ίδια στοιχεία;',
    a4: 'Γιατί η επανάληψη σε ισαπέχοντα διαστήματα είναι ο πλέον αποτελεσματικός τρόπος εκμάθησης.',
    q5: 'Τι είναι η μονομαχία;',
    a5: 'Ένα κουίζ γενικών γνώσεων 5 ερωτήσεων στο οποίο παίζεις εναντίον άλλων συναδέλφων σου.',
    q6: 'Γιατί πρέπει να περιμένω τη σειρά μου όταν παίζω μονομαχία;',
    a6: 'Πρέπει να περιμένεις τον/τη συνάδελφό σου να τελειώσει τον γύρο του/της',
    q7: 'Που μπορώ να βρω περισσότερες πληροφορίες και απαντήσεις στις ερωτήσεις μου;',
    a7: 'Το παρακάτω κουμπί θα σε μεταφέρει στον ιστότοπό μας.',
    toTheFaq: 'Στις συνήθεις ερωτήσεις',
    toTheWebsite: 'Στον ιστότοπο',
  },
}

export default translations

import Axios from 'axios'
import { useState, useEffect, ComponentType, ReactElement } from 'react'

import { url } from '../url'

export interface Language {
  name: string
  code: string
}

export interface WithLanguages {
  languages: Language[]
}

export function withLanguages<P extends WithLanguages>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof WithLanguages>> {
  return function WithLanguagesHoc(
    props: Omit<P, keyof WithLanguages>
  ): ReactElement {
    const [languages, setLanguages] = useState<Language[]>([])

    useEffect(() => {
      let alive = true

      Axios.get<Language[]>(url('/languages.json'), {
        responseType: 'json',
      }).then((response) => {
        alive && setLanguages(response.data)
      })

      return (): void => {
        alive = false
      }
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <WrappedComponent {...(props as any)} languages={languages} />
  }
}

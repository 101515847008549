import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { classNames } from '../../utils/classNames'

import styles from './SelectSingle.scss'

type MainMenuItemProps<T> = {
  icon: IconProp
  inside?: boolean
  items: T[]
  onSelect(item: T): void
  renderItem(item: T, onSelect: (selectedItem: T) => void): ReactElement
  title: string
  itemClass?: string
  ariaListboxLabel?: string
} & Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'onSelect'
>

export function SelectSingle<T>(props: MainMenuItemProps<T>): ReactElement {
  const element = useRef<HTMLDivElement>(null)
  const {
    className,
    itemClass,
    ariaListboxLabel,
    title,
    icon,
    inside,
    items,
    onSelect: onSelectProp,
    renderItem,
    ...rest
  } = props

  const [opened, setOpened] = useState(false)
  const toggleOpened = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      setOpened(!opened)
    },
    [opened]
  )
  const toggleOpenedEnterHandler = useEnterKeyHandler(toggleOpened)

  const onSelect = useCallback(
    (item: T): void => {
      onSelectProp(item)
      setOpened(false)
    },
    [onSelectProp]
  )

  useEffect(() => {
    const eventListener = (event: MouseEvent) => {
      if (!element.current) {
        return
      }

      if (
        !(event.target instanceof Node) ||
        element.current.contains(event.target)
      ) {
        return
      }

      setOpened(false)
    }

    document.addEventListener('click', eventListener)

    return (): void => {
      document.removeEventListener('click', eventListener)
    }
  })

  return (
    <>
      <div className={className || styles.selectItemOuter} ref={element}>
        <a
          role='button'
          href='#'
          className={classNames(styles.selectItem, {
            [styles.inside]: inside,
          })}
          onClick={toggleOpened}
          onKeyDown={toggleOpenedEnterHandler}
          tabIndex={0}
          aria-expanded={opened}
          aria-haspopup='listbox'
          {...rest}
        >
          <FontAwesomeIcon className={styles.icon} icon={icon} />

          <span
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>

          <FontAwesomeIcon
            className={styles.iconRight}
            icon={opened ? faChevronUp : faChevronDown}
          />
        </a>

        {opened && inside && (
          <ol className={itemClass ? itemClass : styles.selectList}>
            {items.map((item) => renderItem(item, onSelect))}
          </ol>
        )}
      </div>

      {opened && !inside && (
        <ol
          role='listbox'
          className={itemClass ? itemClass : styles.selectList}
          aria-label={ariaListboxLabel}
        >
          {items.map((item) => renderItem(item, onSelect))}
        </ol>
      )}
    </>
  )
}

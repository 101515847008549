import { ElementType, ReactElement } from 'react'

import { Button, ButtonProps } from './Button'

import styles from './SecondaryButton.scss'

export function SecondaryButton<C extends ElementType>(
  props: ButtonProps<C>
): ReactElement {
  return (
    <Button
      {...props}
      buttonClassName={styles.secondaryButton}
      iconButtonClassName={styles.iconButton}
    />
  )
}

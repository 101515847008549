import { observer } from 'mobx-react'
import { ReactElement } from 'react'

import onfireLogo from '../../../../../images/logo/blue-onfire.png'
import powerappLogo from '../../../../../images/logo/full-color.png'

import styles from './AuthFooter.scss'
import { useEnvironment } from '../../App'

export const AuthFooter = observer(function AuthFooter(): ReactElement {
  const environment = useEnvironment()

  // TODO: Do we need a combined logo for 'both'?
  const logo = environment.product === 'ONFIRE' ? onfireLogo : powerappLogo
  const organizationLogo =
    environment.organizationLogoUrl || environment.organizationWatermarkLogoUrl
  const uploadedLogo =
    environment.appLogos.fullColorLogoUrl ?? environment.appLogos.whiteLogoUrl

  return (
    <div className={styles.logoContainer}>
      {!uploadedLogo && environment.organizationLogoUrl && (
        <img alt={environment.pageTitle} className={styles.logo} src={logo} />
      )}

      {uploadedLogo && organizationLogo && (
        <img
          className={styles.logo}
          alt={environment.organization}
          src={organizationLogo}
        />
      )}
    </div>
  )
})

import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useMutation } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { Header } from '../../containers/Header'
import { DuelRequestPopupRespondMutation } from '../../generated/DuelRequestPopupRespondMutation.graphql'
import { useStores } from '../../stores'
import { DuelRequestPopup as DuelRequestPopupType } from '../../stores/commonStore'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'
import { TertiaryButton } from '../common/TertiaryButton'

import styles from './DuelRequestPopup.scss'
import { DuelVersusHeader } from './DuelVersusHeader'

export type DuelRequestPopupProps = DuelRequestPopupType & {
  setAttachment(
    children: ReactElement,
    props?: Partial<ComponentProps<typeof TertiaryButton>>
  ): void
}

export function DuelRequestPopup(props: DuelRequestPopupProps): ReactElement {
  const { duelsConnectionIds, duelRequestsConnectionIds } = props
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const item = useFragment(
    graphql`
      fragment DuelRequestPopup_item on DuelRequest {
        id
        challenger {
          ...DuelVersusHeader_right
        }
        opponent {
          ...DuelVersusHeader_left
        }
      }
    `,
    props.item
  )

  const [respondToDuelRequest] = useMutation<DuelRequestPopupRespondMutation>(
    graphql`
      mutation DuelRequestPopupRespondMutation(
        $id: ID!
        $duelsConnectionIds: [ID!]!
        $duelRequestsConnectionIds: [ID!]!
        $response: DuelRequestUpdate!
      ) {
        respondToDuelRequest(id: $id, to: $response) {
          __typename
          # Use these to insert the duel in the active duels list.
          result {
            duel
              @appendNode(
                connections: $duelsConnectionIds
                edgeTypeName: "DuelEdge"
              ) {
              id
              status
              ...ActiveDuelSlot_duel
              ...DuelFinalizingPopup_item
              ...PlayDuelRound_duel
            }
            request {
              id @deleteEdge(connections: $duelRequestsConnectionIds)
            }
          }
        }
      }
    `
  )

  const [loading, setLoading] = useState(false)

  const { onClose } = props

  const onAccept = useCallback(() => {
    setLoading(true)

    respondToDuelRequest({
      variables: {
        id: item.id,
        response: 'ACCEPT',
        duelsConnectionIds,
        duelRequestsConnectionIds,
      },
    }).finally(() => {
      onClose ? onClose() : commonStore.closePopup()
    })
  }, [
    respondToDuelRequest,
    item.id,
    duelsConnectionIds,
    duelRequestsConnectionIds,
    onClose,
    commonStore,
  ])
  const onDecline = useCallback(() => {
    setLoading(true)

    respondToDuelRequest({
      variables: {
        id: item.id,
        response: 'REJECT',
        duelsConnectionIds,
        duelRequestsConnectionIds,
      },
    }).finally(() => {
      onClose ? onClose() : commonStore.closePopup()
    })
  }, [
    respondToDuelRequest,
    item.id,
    duelsConnectionIds,
    duelRequestsConnectionIds,
    onClose,
    commonStore,
  ])
  const onHelp = useCallback(() => {
    commonStore.openPopup({ type: 'about-duels' }, true)
  }, [commonStore])

  const onAcceptKeyPressHandler = useEnterKeyHandler(onAccept)
  const onDeclineKeyPressHandler = useEnterKeyHandler(onDecline)
  const onHelpKeyPressHandler = useEnterKeyHandler(onHelp)

  props.setAttachment(<FontAwesomeIcon icon={faQuestion} />, {
    onClick: onHelp,
    onKeyPress: onHelpKeyPressHandler,
  })

  if (!item.opponent) {
    return <div />
  }

  return (
    <>
      <Header duel wide>
        <DuelVersusHeader
          type='INVITED'
          left={item.opponent}
          right={item.challenger}
          round={0}
        />
      </Header>

      <div className={styles.content}>
        <div className={styles.button}>
          <PrimaryButton
            disabled={loading}
            onClick={onAccept}
            onKeyPress={onAcceptKeyPressHandler}
            tabIndex={0}
          >
            {t('streamItem.duelItem.acceptDuel')}
          </PrimaryButton>
        </div>

        <div className={styles.button}>
          <SecondaryButton
            disabled={loading}
            onClick={onDecline}
            onKeyPress={onDeclineKeyPressHandler}
            tabIndex={0}
          >
            {t('streamItem.duelItem.declineDuel')}
          </SecondaryButton>
        </div>
      </div>
    </>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelVersusHeader_right = {
    readonly rank: number | null;
    readonly user: {
        readonly id: string;
        readonly nameForDuelling: string;
        readonly isMe: boolean;
        readonly profileImageForDuelling: string | null;
        readonly hasAnonymousDuellingEnabled: boolean;
    };
    readonly timedOut?: boolean | undefined;
    readonly " $refType": "DuelVersusHeader_right";
};
export type DuelVersusHeader_right$data = DuelVersusHeader_right;
export type DuelVersusHeader_right$key = {
    readonly " $data"?: DuelVersusHeader_right$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelVersusHeader_right">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuelVersusHeader_right",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMe",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImageForDuelling",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasAnonymousDuellingEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "kind": "InlineFragment",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timedOut",
                    "storageKey": null
                }
            ],
            "type": "DuelPlayer",
            "abstractKey": null
        }
    ],
    "type": "Player",
    "abstractKey": "__isPlayer"
} as any;
(node as any).hash = 'd41836c214debb7469f23f8bf5ba95df';
export default node;

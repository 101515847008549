/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoginType = "LTI" | "SAML" | "WEB";
export type RoutingOnlyGuests_user = {
    readonly loginType: LoginType;
    readonly " $refType": "RoutingOnlyGuests_user";
};
export type RoutingOnlyGuests_user$data = RoutingOnlyGuests_user;
export type RoutingOnlyGuests_user$key = {
    readonly " $data"?: RoutingOnlyGuests_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RoutingOnlyGuests_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutingOnlyGuests_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "loginType",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = 'd33df7d9147658dab5b50834e4546b92';
export default node;

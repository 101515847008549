import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'

import styles from './LoadingIndicator.scss'

export function LoadingIndicator(): ReactElement {
  return (
    <div className={styles.loadingIndicator}>
      <FontAwesomeIcon icon={faSpinnerThird} spin />{' '}
    </div>
  )
}

const translations = {
  popup: {
    confirm: {
      delete: 'Usuń',
      keep: 'Anuluj',
      cancelDuelInviteTitle: 'Usuń?',
      cancelDuelInviteMessage: 'Czy jesteś pewien?',
    },
    repetitionStateInfo: {
      heading: 'Wskaźnik postępu',
      explanation: {
        general:
          'Wskaźnik postępu pozwala Ci szybko zorientować się, czy nadszedł czas na utrwalanie wiedzy. Pokazuje on trzy statusy. Przypomina to sygnalizację świetlną.',
        alert: 'Czerwony: poziom Twojej wiedzy obniżył się. Czas na ćwiczenie.',
        warning:
          'Pomarańczowy: odpowiedz na pozostałe pytania. Gdy wskaźnik zmieni kolor na zielony, to znaczy, że Twoja wiedza jest aktualna.',
        complete: 'Zielony: wspaniale, jesteś na bieżąco! Wróć później.',
      },
    },
  },
  header: {
    'welcome-message': 'Cześć {{name}}!',
    welcome: 'Witaj.',
    welcomeBack: 'Witaj ponownie.',
  },
  login: {
    succesful: 'Logowanie powidło się.',
  },
  learn: {
    instruction: 'Wybierz tematy, które chcesz odtworzyć.',
    practiceSelection: 'Przećwicz wybrane',
    selectAll: 'Zaznacz wszystko',
    deselectAll: 'Usuń zaznaczenie',
    wellDone: 'Dobra robota!',
    topicCategorySelectionIndicator: 'Temat {{current}}/{{countFormatted}}',
    practiceAllNecessary: {
      message:
        '<p><b>Chcesz szybko zaktualizować swoją wiedzę?</b></p><p>Odpowiedz na tę serię pytań, aby osiągnąć zielony status najszybciej, jak to możliwe.</p>',
      upToDateMessage:
        '<p><b>Świetnie Ci idzie!</b></p><p>Chcesz być na bieżąco? Kontynuuj utrwalanie wiedzy!</p>',
      practiceTopics: 'Ćwicz wybrane serie pytań',
    },
    topics: {
      toDo: 'Do wykonania',
      upToDate: 'Na bieżąco',
      practiceCheckbox: 'Utrwalanie wiedzy',
      extraPracticeButton: 'Dodatkowe utrwalanie wiedzy',
    },
    repetitionState: {
      header: {
        complete: 'Świetnie. Jesteś na bieżąco!',
        warning: 'Kontynuuj utrwalanie wiedzy, aby być na bieżąco.',
        alert: 'Czas rozpocząć utrwalanie wiedzy.',
        alertFirstLogin: 'Możesz rozpocząć utrwalanie wiedzy teraz.',
        showScore: 'Twój ogólny wynik: {{score}}%',
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'Jesteś na bieżąco',
        upToDate: 'Jesteś już na bieżąco',
        nrToUpToDate: '{{nr}} do wykonania',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'Powtórzone',
      newQuestion: 'Nowe!',
    },
  },
  duels: {
    title: 'Ocena pojedynku: {{score}}',
    readyToDuel: 'Czy jesteś gotowy(-a) na pojedynek?',
    roundsOverview: 'Przegląd rund pojedynków',
  },
  activeDuels: {
    status: {
      draw: 'Remis',
      lost: 'Przegrałeś',
      won: 'Wygrałeś',
      expired: 'Wygasła',
      invited: 'Zaproszony',
      waiting: 'Oczekuje',
      yourturn: 'Graj',
    },
    subtitle: 'Pojedynki {{num}}/{{max}} są aktywne',
    title: 'Zagraj w dostępny pojedynek.',
    emptyListNew: 'Nie ma pojedynku, rozpocznij nowy pojedynek!',
    emptyListCompleted:
      'Wszystkie pojedynki zakończone, dobra robota!<br>Napij się kawy w nagrodę. ',
  },
  completedDuels: {
    title: 'Zobacz zakończone pojedynki.',
    empty: 'Jeszcze nie skończyłeś pojedynków.<br>Rozpocznij nowy pojedynek!',
  },
  common: {
    Accept: 'Akceptuj',
    CheckAnswer: 'Sprawdź odpowiedź',
    CheckAnswers: 'Sprawdź odpowiedzi',
    Answer: 'Zapisz moją odpowiedź',
    Hello: 'Cześć',
    back: 'Powrót',
    Checking: 'Sprawdzanie...',
    Close: 'Zamknij',
    browseNext: 'Dalej',
    browsePrevious: 'Wstecz',
    Correct: 'Poprawnie',
    Emailaddress: 'Adres mailowy',
    Help: 'Pomoc',
    Incorrect: 'Niepoprawnie',
    CorrectNotChosen: 'Byłoby poprawne',
    'Log out': 'Wyloguj',
    Name: 'Imię, nazwisko',
    'No Rank': 'Brak rankingu',
    'No Rating': 'Brak ratingu',
    NoUserSelection: 'Nie znaleziono użytkowników',
    Round: 'Runda',
    Version: 'Wersja',
    monthNames: 'Sty Lut Mar Kwi Maj Cze Lip Sie Wrz Paź Lis Gru',
    off: 'wyłącz',
    on: 'włącz',
    or: 'lub',
    selected: 'wyselekcjonowany',
  },
  error: {
    404: 'Nie znaleziono serwera (HTTP 404).',
    500: 'Error wewn. serwera (HTTP 500).',
    alreadyAnswered: 'Już udzieliłeś na to odpowiedź',
    internetdown: 'Brak połączenia z internetem!',
    internetup: 'Połączony z internetem',
    refreshButton: 'Kliknij w celu odnowienia',
    timeoutajax:
      'Nastąpił błąd z połączeniem. Kliknij na ok w celu odnowienia strony',
    tryReconnect: 'Trwa wyszukiwanie połączenia...',
    unavailableOffline: {
      title: 'Brak połączenia',
      subtitle: 'Obecnie nie ma połączenia',
      explanation:
        'Do korzystania z aplikacji wymagane jest połączenie internetowe. W tej chwili nie ma połączenia. Sprawdź, czy możesz ponownie połączyć się z internetem lub spróbuj ponownie później. ',
    },
    unknown: 'Nieznany błąd',
    versionConflict: {
      title: 'Nowa wersja',
      text: 'Nowa wersja tej aplikacji jest dostępna. Aby upewnić się, czy wszyscy używają najnowszej wersji, wykonamy uaktualnienie do najnowszej wersji. ',
      refresh: 'Przejdź do najnowszej wersji ',
    },
  },
  menu: {
    activeDuels: 'Aktywne pojedynki',
    autoDuelLabel: 'Automatyczne pojedynki',
    challengeplayer: 'Wyzwanie dla kolegi/koleżanki',
    duel: 'Pojedynkowanie',
    duelVersus: 'VS',
    duelWaiting: 'Wyszukiwanie przeciwnika...',
    duels: 'Pojedynki',
    'help-and-settings': 'Pomoc & Ustawienia',
    menu: 'Menu',
    newDuel: 'Nowy pojedynek',
    pickplayer: 'Wybierz kolegę/koleżankę',
    profile: 'Profil',
    quickDuelFailed: 'Wszystkie zaproszenia są wyłączone',
    randomplayer: 'Dowolnie wybrany przeciwnik',
    ranking: 'Ranking pojedynku',
    rating: 'Rating pojedynku',
    duelsNotAvailable: 'Pojedynki nie są aktualnie dostępne dla Ciebie.',
    duelsNotAvailableExplanation:
      'Czy chcesz w nie zagrać? Jeśli tak, to proszę skontaktuj się z administratorem PowerApp.',
    duelsNotEnabled:
      'Chcesz zagrać w pojedynek ze współpracownikami? Włącz opcję pojedynku poniżej.',
    duelsNotEnabledDisclaimer:
      'Twoje zdjęcie i imię będą widoczne dla współpracowników.',
    enableDuelling: 'Włącz pojedynek ze współpracownikami!',
    duellingEnabledLabel: 'Bierz udział w pojedynku',
    completedDuels: 'Zakończone',
    select: 'Wybierz',
    settings: 'Ustawienia',
    practice: 'Utrwalanie wiedzy',
    aboutDuels: {
      heading: 'Czym jest zaproszenie do pojedynku?',
      explanation1: 'Zostałeś (-aś) wyzwany(-a) na pojedynek.',
      explanation2:
        'Pojedynek to krótki test na wiedzę ze współpracownikiem w 5 rundach. Wygrywasz rundę, jeśli prawidłowo odpowiesz na pytanie szybciej niż Twój współpracownik. Jeśli wygrasz więcej rund niż Twój przeciwnik, wygrasz pojedynek i awansujesz w rankingach.',
      attention: 'Uwaga:',
      explanation3: 'Zagraj w każdej rundzie w ciągu 7 dni!',
    },
    leaveAlert: {
      title: 'Jesteś pewny(-a)?',
      text: '<p>Na pewno chcesz przerwać utrwalanie wiedzy?</p><p>Twoje postępy zostały zapisane.</p>',
      confirm: 'Zatrzymaj',
      continue: 'Kontynuuj ćwiczenia',
    },
    info: 'Informacje',
  },
  profile: {
    subtitle: 'Edytuj swój profil tutaj.',
    pictureEdit: 'Zobacz lub edytuj swoje zdjęcie.',
    'picture-submit': 'Użyj tego przyciętego zdjęcia',
    pictureConfirmDelete: 'Czy na pewno chcesz usunąć zdjęcie profilowe?',
    generalDataText:
      'Nie możesz samodzielnie zmienić wszystkich danych osobowych.',
    profilePageTitle: 'Zarządzaj swoim profilem',
    noEditDataText: 'Nie możesz zmienić tych danych.',
    'file-submit': 'Wczytywanie',
    generalDataTitle: 'Dane osobowe',
    'password-new': 'Nowe hasło',
    'password-newpasswordmismatch': 'Powtórzone hasło nie zgadza się',
    'password-old': 'Obecne hasło',
    'password-oldpasswordmismatch': 'Obecne hasło nie zgadza się',
    'password-passwordsaved': 'Hasło zostało zapisane!',
    'password-passwordtooshort':
      'Nowe hasło powinno zawierać co najmniej 6 znaków',
    'password-repeat': 'Powtórz hasło',
    'password-submit': 'Zapisz nowe hasło',
    passwordEdit: 'Zmień hasło',
    passwordText:
      'Zmień hasło wprowadzając wpierw obecne, a następnie dwukrotnie nowe hasło.',
    passwordTitle: 'Dopasuj hasło',
    'picture-invalidsize':
      ' Ten obraz jest zbyt duży. Maksymalna wysokość lub szerokość nie może przekraczać 2600 pikseli.',
    'picture-nofile': 'Nie wczytałeś pliku.',
    'picture-nofileselected': 'Nie zaznaczyłeś pliku.',
    'picture-notimage': 'Ten plik nie jest obrazem.',
    'picture-saved': 'Zdjęcie profilowe zostało dopasowane',
    pictureCancel: 'Anuluj',
    profileLanguageChoice: 'Wybierz język',
    profileLanguagePreference: 'Preferowany język',
    profilePictureText:
      'Zdjęcie widoczne jest również dla twoich kolegów/koleżanek.',
    profilePictureTitle: 'Zdjęcie profilowe',
    title: 'Profil',
  },
  ranking: {
    loadMore: 'Załaduj więcej',
    subtitle:
      ' Ten rating pokazuje jak dobry jesteś w pojedynkach w porównaniu ze swoimi kolegami/koleżankami.',
    title: 'Ranking pojedynków',
    topPositions: 'Najlepsze pozycje',
    yourPosition: 'Twoja pozycja ({{rank}})',
    empty: 'Brak rankingu, rozpocznij pojedynek!',
    notRanked: 'Ukończ pojedynek, aby zająć miejsce w rankingu!',
  },
  searchDuel: {
    duelstillactive: 'Jest jeszcze jeden aktywny pojedynek',
    invitationsentondate: 'Zaproszenie zostało wysłane w dniu {{date}}',
    noduelpossible: 'Brak możliwości pojedynkowania',
    rankingposition: 'Pozycja w rankingu {{rank}}',
    searchPlaceholder: 'Szukaj pod imieniem, nazwiskiem',
    title: 'Szukaj przeciwnika',
    randomOpponent:
      'Szukamy następnego przeciwnika. Możesz zamknąć ten ekran, damy Ci znać, kiedy pojedynek będzie gotowy.',
    emptyListSearch: 'Brak wyników wyszukiwania. ',
    emptyList:
      'Niestety, nie ma nikogo dostępnego.<br>Spróbuj ponownie później. ',
  },
  searchFeedback: {
    oneResult: 'Znaleziono rezultat {{amount}}.',
    overflowMessage: 'Wyświetlane są wyłącznie najlepsze rezultaty {{amount}}.',
    searchMessage: 'Znaleziono rezultaty {{amount}}.',
    title: 'Poszukaj kolegę/koleżankę',
  },
  streamItem: {
    brainItem: {
      closeItem: 'Zamknij',
      completed: 'Zakończyłeś pytanie.',
      correct: 'Prawidłowo',
      doneText: 'Zakończone',
      incorrect: 'Nieprawidłowo',
      openItem: 'Start',
      topics: 'Tematy',
      nextItem: 'Kontynuuj ćwiczenia',
      showProgress: 'Pokaż postępy',
    },
    brainSnack: {
      browseNext: 'Kolejna strona',
      browsePrevious: 'Poprzednia strona',
      defaultTitle: 'Przekąska dla mózgu',
      doneText: 'Przeczytałeś przekąskę dla mózgu',
    },
    duelItem: {
      acceptDuel: 'Akceptuj pojedynek',
      cancelled: 'Pojedynek został anulowany',
      closeItem: 'Zamknij',
      declineDuel: 'Odrzuć pojedynek',
      doneText: 'Pojedynek zakończony',
      draw: 'Remis',
      duel: 'Pojedynek',
      duelAccepted: 'Zaproszenie na pojedynek od {{name}} zostało przyjęte.',
      duelDeclined: 'Zaproszenie na pojedynek od {{name}} zostało odrzucone.',
      duelInfoInvitation: 'Otrzymałeś wyzwanie na pojedynek.',
      duelInfoMain:
        ' Krótka walka z kolegą/koleżanką w 5 rundach. Rundę wygrywasz w momencie udzielenia poprawnej odpowiedzi szybciej niż kolega/koleżanka. Jeśli wygrasz więcej rund niż przeciwnik, wygrasz pojedynek, a twoja pozycja wzrośnie',
      duelStarted: 'Pojedynek z {{name}} rozpoczęty. Powodzenia!',
      invite: 'Wyzwanie na pojedynek',
      opponentTurn: 'Kolej przeciwnika',
      playItem: 'Runda gry {{roundnumber}}',
      practice: 'Poćwicz dalej',
      practiceWhileWaiting: 'Poćwicz w czasie oczekiwania',
      showDetails: 'Sprawdź szczegóły',
      started: 'Fight!',
      subheading: {
        betterNextTime: 'Być może uda się kolejnym razem!',
        timeOutBoth: 'Obydwoje czekaliście zbyt długo',
        timeOutOpponent: 'Twój przeciwnik czekał zbyt długo',
        timeOutPlayer: 'Zbyt długo czekałeś',
        waitForOpponent: 'Zaczekaj na przeciwnika',
        wellDone: 'Dobrze, oby tak dalej!',
        yourTurn: 'Jesteś gotów?',
      },
      tooLate: 'Za późno!',
      versusLabel: 'vs',
      viewInvitation: 'Zobacz zaproszenie',
      youLost: 'Przegrałeś',
      youWon: 'Wygrałeś!',
      yourTurn: 'Twoja kolej',
      roundNumber: 'Runda {{number}}',
      duelRequest: 'Nowe wyzwanie na pojedynek!',
      duelRequestText: '{{name}} wyzywa Cię na pojedynek',
      tooLateTurn: 'Za późno',
    },
    match: {
      correct: '{{num}} z {{max}} odpowiedzi poprawnych',
      helpText: 'Przeciągnij odpowiedzi w odpowiednie miejsce',
      questionTitle: 'Dopasuj',
      showingCorrectAnswer: 'Zobacz prawidłowe odpowiedzi.',
      showCorrectAnswer: 'Pokaż poprawną odpowiedź',
      showMyAnswer: 'Pokaż moją odpowiedź',
    },
    multiplechoice: {
      doneText: 'Gotowe',
      helpText: 'Wybierz jedną odpowiedź',
      questionTitle: 'Pytanie',
    },
    multipleselect: {
      correct: '{{num}} z {{max}} odpowiedzi poprawnych',
      doneText: 'Gotowe',
      helpText: 'Wybierz jedną lub więcej odpowiedzi',
      incorrect: ', {{incorrect}} niepoprawnie',
      questionTitle: 'Pytanie',
    },
    notification: {
      continueLearningText:
        ' Ćwiczysz dodatkowe pytania. Otrzymasz za to mniej punktów, ponieważ już się w to wgłębiłeś.',
      continueanyway: 'Mimo tego kontynuuj',
      doneText: 'Powiadomienie przeczytano',
      panelbuttonlabel: 'Zakończ ćwiczenia',
      pauze: 'Mogę kontynuować naukę?',
      title: 'Powiadomienie',
    },
    order: {
      correct: '{{num}} z {{max}} odpowiedzi poprawnych',
      helpText: 'Przeciągnij odpowiedzi i ustaw je w odpowiedniej kolejności',
      questionTitle: 'Zamów',
    },
    poll: {
      doneText: 'Zakończyłeś pytanie sondażowe.',
      helpText: 'Wybierz odpowiedź',
      questionTitle: 'Sondaż',
      resultinfo: 'Inne osoby udzieliły takiej odpowiedzi',
    },
    slider: {
      doneText: 'Gotowe',
      helpText: 'Przeciągnij slider na odpowiednią pozycję',
      questionTitle: 'Suwak',
    },
    topicPin: {
      continue: 'Kontynuuj',
      dontshowagain: 'Nie wyświetlaj już tego',
      pindisabled:
        'Temat ten nie jest już podpięty. Od tej pory będziesz dostawać zagadnienia z wszystkich tematów.',
      pinenabled:
        'Przypiąłeś {{- topiclabel}} i widzisz tylko elementy powiązane z tym tematem. Elementy związane z tym tematem są powtarzane częściej, abyś mógł je ćwiczyć. Kliknij ponownie przypięty temat, aby dołączyć pytania dotyczące innych tematów.',
      title: 'Przypnij temat',
      selectTopics: 'Wybierz tematy',
      selectTopicsPractice: 'Wybierz tematy, które chcesz przećwiczyć.',
    },
    topicInfoHeader: 'Tematy',
  },
  auth: {
    header: {
      login: 'Logowanie',
      expired: 'Link wygasł',
      remind: 'Nie pamiętasz hasła?',
      create: 'Aktywuj swoje konto',
      reset: 'Zaktualizuj swoje hasło',
      welcome: 'Witaj!',
    },
    field: {
      email: 'Adres e-mail',
      password: 'Hasło',
      oldPassword: 'Obecne hasło',
      newPassword: 'Nowe hasło',
      newRepeatPassword: 'Powtórz nowe hasło',
      rememberMe: 'Zapamiętaj mnie',
    },
    action: {
      itemcompleted: 'Ukończono pozycję',
      updateprofile: 'Aktualizuj profil',
      sendLink: 'Wyślij link do resetowania hasła',
      backToLogin: 'Chcę się zalogować',
      sendActivateLink: 'Aktywuj konto',
      alreadyActivatedLogin:
        'Konto zostało już aktywowane i chcę się zalogować',
      savePassword: 'Zapisz moje nowe hasło',
      newPassword: 'Twoje nowe hasło',
      repeatNewPassword: 'Potwierdź nowe hasło',
      newPasswordPolicyIntro: 'Siła hasła',
    },
    selfActivate: {
      header: 'Aktywuj konto',
    },
    logintext: 'Logowanie',
    remindText:
      'Nie ma sprawy. Wyślemy Ci wiadomość e-mail, abyś mógł/mogła ustawić nowe hasło.',
    resetText: 'Nowe hasło możesz ustawić tutaj.',
    createText: 'Witaj! Utwórz nowe hasło i będziesz mógł/mogła się zalogować.',
    passwords: {
      user: 'Przepraszamy, nie możemy znaleźć użytkownika z tym adresem e-mail.',
      token: 'Ten token resetowania hasła jest nieprawidłowy.',
      sent: ' Wiadomość e-mail z linkiem resetującym została wysłana.',
      reset: 'Hasło zostało zresetowane!',
      forgot: 'Nie znam swojego hasła',
      passwordExpiredTitle: 'Hasło wygasło',
      submitPasswordExpiredForm: 'Zapisz nowe hasło',
      passwordExpiredText:
        'Twoje hasło jest starsze niż {{days}} dni i wygasło. Musisz ustawić nowe hasło, korzystając z poniższego formularza, a następnie możesz kontynuować naukę w PowerApp',
    },
  },
  faq: {
    header: 'Często zadawane pytania',
    q1: 'PowerApp to aplikacja do mikrokształcenia, co to oznacza?',
    a1: 'Mikrokształcenie oznacza, że bardziej efektywne jest uczenie się w krótkich sesjach, jeśli Twoim celem jest przyswojenie większej ilości wiedzy z biegiem czasu.',
    q2: 'Dlaczego mój wynik jest czasami niższy niż się spodziewałem(-am)?',
    a2: 'Twój wynik maleje, ponieważ z czasem zapominasz, czego się nauczyłeś(-aś).',
    q3: 'Kiedy mój wynik maleje?',
    a3: 'Gdy na pytania udzielono nieprawidłowej odpowiedzi lub gdy były one przez jakiś czas nieaktywne.',
    q4: 'Dlaczego te same elementy się powtarzają?',
    a4: 'Ponieważ powtarzanie w pewnych odstępach czasu jest najskuteczniejszym sposobem nauki.',
    q5: 'Co to jest pojedynek?',
    a5: 'Quiz z 5 pytaniami, w którym rywalizujesz ze współpracownikiem.',
    q6: 'Dlaczego muszę czekać na swoją kolej podczas pojedynku?',
    a6: 'Musisz poczekać, aż współpracownik skończy swoją kolejkę.',
    q7: 'Gdzie mogę znaleźć więcej informacji i odpowiedzi na moje pytania?',
    a7: 'Przycisk poniżej przeniesie Cię na naszą stronę internetową.',
    toTheFaq: 'Do FAQ (najczęściej zadawane pytania)',
    toTheWebsite: 'Do witryny internetowej',
  },
}

export default translations

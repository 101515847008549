import loadImage from 'blueimp-load-image'

/**
 * Take an upload file and convert it to a data URL.
 *
 * @param {File} file
 * @return {Promise}
 */
export async function toDataUrl(file: File): Promise<string> {
  return new Promise<string>(
    (
      resolve: (result: string) => void,
      reject: (reason: string) => void
    ): void => {
      loadImage(
        file,
        (eventOrImage: Event | HTMLCanvasElement | HTMLImageElement) => {
          if ('type' in eventOrImage) {
            reject(eventOrImage.type)
            return
          }

          if (eventOrImage instanceof HTMLImageElement) {
            // Should never happen, we use the canvas option.
            reject('Unexpected image load result')
            return
          }

          resolve(eventOrImage.toDataURL())
        },
        {
          canvas: true,
          maxHeight: 600,
          maxWidth: 600,
          orientation: true,
        }
      )
    }
  )
}

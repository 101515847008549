import { ReactElement, useEffect } from 'react'

import { classNames } from '../../utils/classNames'

import styles from './Alert.scss'

interface AlertProps {
  autoClear?: () => void
  message: string
  type: 'success' | 'error'
}

export function Alert(props: AlertProps): ReactElement {
  useEffect(() => {
    return props.autoClear
  }, [props.autoClear])

  return (
    <div className={classNames(styles.alert, styles[props.type])} role='alert'>
      {props.message}
    </div>
  )
}

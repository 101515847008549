import i18n from 'i18next'

const mapping: Record<string, string> = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  cz: 'cs-CZ',
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-LI': 'de-DE',
  'de-LU': 'de-DE',
  de: 'de-DE',
  el: 'el-GR',
  'en-AU': 'en-US',
  'en-BZ': 'en-US',
  'en-CA': 'en-US',
  'en-CB': 'en-US',
  'en-GB': 'en-US',
  'en-IE': 'en-US',
  'en-JM': 'en-US',
  'en-NZ': 'en-US',
  'en-PH': 'en-US',
  'en-TT': 'en-US',
  'en-ZA': 'en-US',
  'en-ZW': 'en-US',
  en: 'en-US',
  'fr-BE': 'fr-FR',
  'fr-CA': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-LU': 'fr-FR',
  'fr-MC': 'fr-FR',
  fr: 'fr-FR',
  hu: 'hu-HU',
  'it-CH': 'it-IT',
  it: 'it-IT',
  'nl-BE': 'nl-NL',
  nl: 'nl-NL',
  pl: 'pl-PL',
  'pt-PT': 'pt-BR',
  pt: 'pt-BR',
  ro: 'ro-RO',
  'ro-MD': 'ro-RO',
}

export function isLanguageSupported(language: string): boolean {
  return !!i18n.getDataByLanguage(language)
}

export async function detectLanguage(): Promise<string> {
  const languages = navigator.languages
    ? navigator.languages
    : [navigator.language]
  for (let language of languages) {
    if (language in mapping) {
      language = mapping[language]
    }

    if (isLanguageSupported(language)) {
      return language
    }
  }

  // Default case is English.
  return 'en-US'
}

import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import { PrimaryButton } from './PrimaryButton'
import styles from './VersionConflictPopup.scss'

export function VersionConflictPopup(): ReactElement {
  const { t } = useTranslation()
  const [reloading, setReloading] = useState(false)

  const refresh = useCallback((): void => {
    setReloading(true)

    location.reload()
  }, [])
  const refreshEnterKeyHandler = useEnterKeyHandler(refresh)

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('error.versionConflict.title')}</h1>

      <p>{t('error.versionConflict.text')}</p>

      <PrimaryButton
        disabled={reloading}
        onClick={refresh}
        onKeyPress={refreshEnterKeyHandler}
        tabIndex={0}
      >
        {t('error.versionConflict.refresh')}
      </PrimaryButton>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type DuelFinalizingPopup_item = {
    readonly id: string;
    readonly status: DuelStatus;
    readonly players: ReadonlyArray<{
        readonly timedOut: boolean;
        readonly rounds: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_left" | "DuelRoundsOverview_right">;
        }> | null;
        readonly user: {
            readonly isMe: boolean;
        };
        readonly " $fragmentRefs": FragmentRefs<"DuelVersusHeader_left" | "DuelVersusHeader_right">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_duel">;
    readonly " $refType": "DuelFinalizingPopup_item";
};
export type DuelFinalizingPopup_item$data = DuelFinalizingPopup_item;
export type DuelFinalizingPopup_item$key = {
    readonly " $data"?: DuelFinalizingPopup_item$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelFinalizingPopup_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuelFinalizingPopup_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "DuelPlayer",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timedOut",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelPlayerRound",
                    "kind": "LinkedField",
                    "name": "rounds",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_left"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_right"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isMe",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_left"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_right"
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DuelRoundsOverview_duel"
        }
    ],
    "type": "Duel",
    "abstractKey": null
} as any;
(node as any).hash = '567bf4c85fa02dca37736d72710ceb63';
export default node;

import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { AvatarPopupDeleteAvatarMutation } from '../../generated/AvatarPopupDeleteAvatarMutation.graphql'
import { AvatarPopupUserQuery } from '../../generated/AvatarPopupUserQuery.graphql'

import { useStores } from '../../stores'
import { classNames } from '../../utils/classNames'
import { toDataUrl } from '../../utils/toDataUrl'
import { Avatar, AvatarContext } from '../common/Avatar'

import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'

import styles from './AvatarPopup.scss'

export function AvatarPopup(): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const [confirmingDeletion, setConfirmingDeletion] = useState(false)
  const deleteButton = useRef<HTMLButtonElement>(null)

  const [deleteAvatar, deleteAvatarResult] =
    useMutation<AvatarPopupDeleteAvatarMutation>(
      graphql`
        mutation AvatarPopupDeleteAvatarMutation {
          changeAvatar(file: null) {
            id
            profileImage
          }
        }
      `,
      { variables: {} }
    )
  const user = useQuery<AvatarPopupUserQuery>(graphql`
    query AvatarPopupUserQuery {
      me {
        id
        fullName
        profileImage
      }
    }
  `)

  const documentClicked = useCallback((event: MouseEvent) => {
    if (!deleteButton.current) {
      return
    }

    if (
      !(event.target instanceof Node) ||
      deleteButton.current.contains(event.target)
    ) {
      return
    }

    setConfirmingDeletion(false)
  }, [])
  useEffect(() => {
    document.addEventListener('click', documentClicked)

    return () => {
      document.removeEventListener('click', documentClicked)
    }
  }, [documentClicked])

  const onFileChosen = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (
        !event.currentTarget.files ||
        event.currentTarget.files.length === 0
      ) {
        return
      }

      const file = event.currentTarget.files[0]
      toDataUrl(file).then((image) =>
        commonStore.openPopup(
          {
            image,
            mime: file.type,
            filename: file.name,
            type: 'crop-avatar',
          },
          true
        )
      )
    },
    [commonStore]
  )
  const onDeleteClicked = useCallback(() => {
    if (!confirmingDeletion) {
      setConfirmingDeletion(true)

      return
    }

    deleteAvatar().finally(commonStore.closePopup)
  }, [commonStore, confirmingDeletion, deleteAvatar])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('profile.profilePictureTitle')}</h1>
      <h2>{t('profile.pictureEdit')}</h2>

      {user.data?.me && (
        <Avatar
          filename={user.data.me.profileImage}
          name={user.data.me.fullName}
          userId={user.data.me.id}
          context={AvatarContext.default}
        />
      )}

      <div className={styles.buttons}>
        <PrimaryButton component='label' icon>
          <input
            className={styles.input}
            type='file'
            accept='image/*'
            onChange={onFileChosen}
          />

          <FontAwesomeIcon icon={faImage} />
        </PrimaryButton>

        {user.data?.me && user.data?.me.profileImage && (
          <>
            {confirmingDeletion && (
              <div
                className={classNames(
                  styles.tooltip,
                  styles.bsTooltipTop,
                  styles.show
                )}
                role='tooltip'
                x-placement='top'
              >
                <div className={styles.arrow} />
                <div className={styles.tooltipInner}>
                  {t('profile.pictureConfirmDelete')}
                </div>
              </div>
            )}
            <SecondaryButton
              icon
              disabled={deleteAvatarResult.loading}
              onClick={onDeleteClicked}
              componentRef={deleteButton}
              tabIndex={0}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </SecondaryButton>
          </>
        )}
      </div>
    </div>
  )
}

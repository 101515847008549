import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './NumberBubble.scss'

interface NumberBubbleProps {
  className?: string
  scaling?: boolean
  sup?: string
  text: number | string
}

export function NumberBubble(props: NumberBubbleProps): ReactElement {
  const { i18n } = useTranslation()

  const dynamicClassnames =
    styles.numberBubble + (props.className ? ' ' + props.className : '')

  const direction = i18n.dir(i18n.language)

  const text =
    typeof props.text === 'string'
      ? props.text
      : Intl.NumberFormat(i18n.language).format(props.text)

  return (
    <div className={dynamicClassnames}>
      {props.scaling ? (
        <svg
          // The calculation below ensures the text auto-fitting works properly.
          viewBox={`0 0 ${(String(props.text).length - 1) * 8 + 24} 24`}
        >
          {props.sup &&
            (direction === 'ltr' ? (
              <text textAnchor='middle' x='50%' y='45%'>
                <tspan className={styles.smaller}>{props.sup}</tspan>
                <tspan className={styles.invisible}>{text}</tspan>
              </text>
            ) : (
              <text textAnchor='middle' x='50%' y='45%'>
                <tspan className={styles.invisible}>{text}</tspan>
                <tspan className={styles.smaller}>{props.sup}</tspan>
              </text>
            ))}

          <text textAnchor='middle' x='50%' y={props.sup ? '80%' : '70%'}>
            {props.sup && direction === 'ltr' && (
              <tspan className={styles.invisible + ' ' + styles.smaller}>
                {props.sup}
              </tspan>
            )}

            {text}

            {props.sup && direction === 'rtl' && (
              <tspan className={styles.invisible + ' ' + styles.smaller}>
                {props.sup}
              </tspan>
            )}
          </text>
        </svg>
      ) : (
        text
      )}
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelStatus = "DRAW" | "LOST" | "WAITING" | "WON" | "YOURTURN";
export type DuelRoundsOverview_duel = {
    readonly status: DuelStatus;
    readonly " $refType": "DuelRoundsOverview_duel";
};
export type DuelRoundsOverview_duel$data = DuelRoundsOverview_duel;
export type DuelRoundsOverview_duel$key = {
    readonly " $data"?: DuelRoundsOverview_duel$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_duel">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuelRoundsOverview_duel",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        }
    ],
    "type": "Duel",
    "abstractKey": null
} as any;
(node as any).hash = '94c3f6bb8ff4bd1f4d3fe534b79e9636';
export default node;

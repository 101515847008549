/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PasswordInputValidationQueryVariables = {
    password: string;
    password2?: string | null | undefined;
    email?: string | null | undefined;
    token?: string | null | undefined;
};
export type PasswordInputValidationQueryResponse = {
    readonly validatePassword: ReadonlyArray<({
        readonly __typename: "StrengthPasswordValidation";
        readonly strength: number;
        readonly strengthDescription: string;
    } | {
        readonly __typename: "ConfirmationPasswordValidation";
    } | {
        readonly __typename: "ReusePasswordValidation";
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) & {
        readonly message: string | null;
        readonly valid: boolean;
    }>;
};
export type PasswordInputValidationQuery = {
    readonly response: PasswordInputValidationQueryResponse;
    readonly variables: PasswordInputValidationQueryVariables;
};



/*
query PasswordInputValidationQuery(
  $password: String!
  $password2: String
  $email: String
  $token: String
) {
  validatePassword(email: $email, token: $token, password: $password, passwordConfirmation: $password2) {
    message
    valid
    __typename
    ... on StrengthPasswordValidation {
      strength
      strengthDescription
    }
    ... on ConfirmationPasswordValidation {
      __typename
    }
    ... on ReusePasswordValidation {
      __typename
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "email"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "password"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "password2"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "token"
    } as any, v4 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Variable",
                    "name": "password",
                    "variableName": "password"
                },
                {
                    "kind": "Variable",
                    "name": "passwordConfirmation",
                    "variableName": "password2"
                },
                {
                    "kind": "Variable",
                    "name": "token",
                    "variableName": "token"
                }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "validatePassword",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "valid",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                },
                {
                    "kind": "InlineFragment",
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "strength",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "strengthDescription",
                            "storageKey": null
                        }
                    ],
                    "type": "StrengthPasswordValidation",
                    "abstractKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "PasswordInputValidationQuery",
            "selections": (v4 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "PasswordInputValidationQuery",
            "selections": (v4 /*: any*/)
        },
        "params": {
            "cacheID": "fa869c28b0abbf0783cc958c1710462a",
            "id": null,
            "metadata": {},
            "name": "PasswordInputValidationQuery",
            "operationKind": "query",
            "text": "query PasswordInputValidationQuery(\n  $password: String!\n  $password2: String\n  $email: String\n  $token: String\n) {\n  validatePassword(email: $email, token: $token, password: $password, passwordConfirmation: $password2) {\n    message\n    valid\n    __typename\n    ... on StrengthPasswordValidation {\n      strength\n      strengthDescription\n    }\n    ... on ConfirmationPasswordValidation {\n      __typename\n    }\n    ... on ReusePasswordValidation {\n      __typename\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c2e7fb0331c8e794d0891d5d31461828';
export default node;

import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane'
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventHandler, ReactElement, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useFragment } from 'relay-hooks'
import { NewDuelSearchResult_result$key } from '../../generated/NewDuelSearchResult_result.graphql'

import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { Avatar, AvatarContext } from '../common/Avatar'

import styles from './NewDuelSearchResult.scss'

export interface NewDuelSearchResultProps {
  onInvite?: EventHandler<SyntheticEvent>
  result: NewDuelSearchResult_result$key | null
}

export function NewDuelSearchResult(
  props: NewDuelSearchResultProps
): ReactElement {
  const { t, i18n } = useTranslation()

  const enterHandler = useEnterKeyHandler(props.onInvite)

  const result = useFragment(
    graphql`
      fragment NewDuelSearchResult_result on PossibleOpponent {
        availability
        invitedSince
        rating
        user {
          id
          fullNameForDuelling
          profileImageForDuelling
          hasAnonymousDuellingEnabled
        }
      }
    `,
    props.result
  )
  const isAvailable = result?.availability === 'AVAILABLE'

  return (
    <li
      className={classNames(styles.searchResult, {
        [styles.unavailable]: !isAvailable,
        [styles.invited]: result?.availability === 'INVITED',
        [styles.placeholder]: !result,
      })}
      onClick={isAvailable ? props.onInvite : undefined}
      onKeyPress={isAvailable ? enterHandler : undefined}
      role='button'
      tabIndex={isAvailable ? 0 : undefined}
      aria-disabled={result?.availability !== 'AVAILABLE'}
    >
      <div className={styles.avatar} aria-hidden={true}>
        {result && (
          <Avatar
            filename={result.user.profileImageForDuelling}
            name={result.user.fullNameForDuelling}
            userId={result.user.id}
            context={AvatarContext.duelling}
            hasAnonymousDuellingEnabled={
              result.user.hasAnonymousDuellingEnabled
            }
          />
        )}
      </div>

      <div className={styles.details}>
        <div className={styles.name}>
          {result?.user.fullNameForDuelling || t('common.Name')}
        </div>

        <div className={styles.rating} aria-label={t('menu.rating')}>
          <FontAwesomeIcon icon={faStar} />{' '}
          {new Intl.NumberFormat(i18n.language).format(result?.rating || 1500)}
        </div>
      </div>

      <div className={styles.indicator}>
        <FontAwesomeIcon
          icon={faPaperPlane}
          aria-label={
            !result || result.availability === 'UNAVAILABLE'
              ? t('searchDuel.noduelpossible')
              : result.availability === 'INVITED'
              ? t('searchDuel.invitationsentondate', {
                  date: result.invitedSince,
                })
              : result.availability === 'DUELLING'
              ? t('searchDuel.duelstillactive')
              : ''
          }
        />

        {result?.availability === 'INVITED' && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        )}
        {(!result ||
          result.availability === 'UNAVAILABLE' ||
          result.availability === 'DUELLING') && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>
    </li>
  )
}

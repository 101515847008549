import { observer } from 'mobx-react'
import { ReactElement } from 'react'
import { useStores } from '../../stores'

import { classNames } from '../../utils/classNames'

import styles from './ProgressBar.scss'

interface ProgressBarProps {
  percentage: number
  alternativeText?: string
  alternativeTextStyle?: string
  hideText?: boolean
  ariaHidden?: boolean
}

export const ProgressBar = observer(function ProgressBar(
  props: ProgressBarProps
): ReactElement {
  const { commonStore } = useStores()
  const percentage = Math.ceil(props.percentage)

  return (
    <div className={styles.container} aria-hidden={props.ariaHidden}>
      <div className={styles.progress}>
        <div
          className={styles.progressBar}
          role='progressbar'
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: percentage + '%' }}
          aria-hidden={!props.hideText}
        />
      </div>

      {!props.hideText && (
        <div
          className={classNames(styles.percentage, props.alternativeTextStyle)}
        >
          {props.alternativeText
            ? props.alternativeText
            : Intl.NumberFormat(commonStore.language, {
                style: 'percent',
              }).format(percentage / 100)}
        </div>
      )}
    </div>
  )
})

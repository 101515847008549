import 'offline-js'

import { CommonStore } from '../stores/commonStore'

class Status {
  private commonStore: CommonStore

  public constructor(commonStore: CommonStore) {
    this.commonStore = commonStore

    this.initEvents()
  }

  /**
   * Initialise the status checkers.
   */
  private initEvents(): void {
    // Handle the Offline.js plugin
    // Offline.options.checkOnLoad = true;
    // Offline.options.reconnect = false;

    // Should we monitor AJAX requests to help decide if we have a connection?
    // Offline.options.interceptRequests = false;
    // Offline.options.reconnect = true;

    // Attach to Offline.js events.
    Offline.on('down', (): void => {
      this.commonStore.setOnline(false)
    })
    Offline.on('up', (): void => {
      this.commonStore.setOnline(true)
    })
    Offline.on('reconnect:stopped', (): void => {
      this.commonStore.setOnline(true)
    })

    // We can check now. Maybe we loaded in offline mode using our service worker.
    Offline.check()
  }
}

export { Status }

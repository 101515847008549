/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DuellingNotAvailablePopupUserQueryVariables = {};
export type DuellingNotAvailablePopupUserQueryResponse = {
    readonly me: {
        readonly fullName: string;
        readonly hasDuellingAvailable: boolean;
        readonly hasDuellingEnabled: boolean;
        readonly id: string;
        readonly profileImage: string | null;
    } | null;
};
export type DuellingNotAvailablePopupUserQuery = {
    readonly response: DuellingNotAvailablePopupUserQueryResponse;
    readonly variables: DuellingNotAvailablePopupUserQueryVariables;
};



/*
query DuellingNotAvailablePopupUserQuery {
  me {
    fullName
    hasDuellingAvailable
    hasDuellingEnabled
    id
    profileImage
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingAvailable",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDuellingEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "DuellingNotAvailablePopupUserQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "DuellingNotAvailablePopupUserQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "8935f86280d63e3ad40f10791b87fe1b",
            "id": null,
            "metadata": {},
            "name": "DuellingNotAvailablePopupUserQuery",
            "operationKind": "query",
            "text": "query DuellingNotAvailablePopupUserQuery {\n  me {\n    fullName\n    hasDuellingAvailable\n    hasDuellingEnabled\n    id\n    profileImage\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '05142447bcf56dc109c85f3157ea4048';
export default node;

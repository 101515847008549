/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelRoundsOverview_left = ReadonlyArray<{
    readonly answerTime: number;
    readonly isCorrect: boolean;
    readonly roundNumber: number;
    readonly " $refType": "DuelRoundsOverview_left";
}>;
export type DuelRoundsOverview_left$data = DuelRoundsOverview_left;
export type DuelRoundsOverview_left$key = ReadonlyArray<{
    readonly " $data"?: DuelRoundsOverview_left$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_left">;
}>;



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "plural": true
    },
    "name": "DuelRoundsOverview_left",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answerTime",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCorrect",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roundNumber",
            "storageKey": null
        }
    ],
    "type": "DuelPlayerRound",
    "abstractKey": null
} as any;
(node as any).hash = 'aa23f9f5f7b7797bd9b525b6ad3b4cc4';
export default node;

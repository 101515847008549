/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NewDuelPopupSearchOpponentsQueryVariables = {
    search?: string | null | undefined;
};
export type NewDuelPopupSearchOpponentsQueryResponse = {
    readonly possibleOpponents: {
        readonly data: ReadonlyArray<{
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"NewDuelSearchResult_result">;
        }>;
    };
};
export type NewDuelPopupSearchOpponentsQuery = {
    readonly response: NewDuelPopupSearchOpponentsQueryResponse;
    readonly variables: NewDuelPopupSearchOpponentsQueryVariables;
};



/*
query NewDuelPopupSearchOpponentsQuery(
  $search: String
) {
  possibleOpponents(search: $search) {
    data {
      id
      ...NewDuelSearchResult_result
    }
  }
}

fragment NewDuelSearchResult_result on PossibleOpponent {
  availability
  invitedSince
  rating
  user {
    __typename
    id
    fullNameForDuelling
    profileImageForDuelling
    hasAnonymousDuellingEnabled
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "search"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "NewDuelPopupSearchOpponentsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PossibleOpponentPaginator",
                    "kind": "LinkedField",
                    "name": "possibleOpponents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PossibleOpponent",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "NewDuelSearchResult_result"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "NewDuelPopupSearchOpponentsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PossibleOpponentPaginator",
                    "kind": "LinkedField",
                    "name": "possibleOpponents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PossibleOpponent",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "availability",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invitedSince",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rating",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "fullNameForDuelling",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "profileImageForDuelling",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasAnonymousDuellingEnabled",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1700efec05058e88db469053672643fc",
            "id": null,
            "metadata": {},
            "name": "NewDuelPopupSearchOpponentsQuery",
            "operationKind": "query",
            "text": "query NewDuelPopupSearchOpponentsQuery(\n  $search: String\n) {\n  possibleOpponents(search: $search) {\n    data {\n      id\n      ...NewDuelSearchResult_result\n    }\n  }\n}\n\nfragment NewDuelSearchResult_result on PossibleOpponent {\n  availability\n  invitedSince\n  rating\n  user {\n    __typename\n    id\n    fullNameForDuelling\n    profileImageForDuelling\n    hasAnonymousDuellingEnabled\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4dd6123263b833162265c2a6ce48b479';
export default node;

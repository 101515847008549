import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GraphQLError } from 'graphql'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/classNames'
import styles from './GraphQlError.scss'
import { PrimaryButton } from './PrimaryButton'

type ExtendedError = Error & {
  source?: {
    errors: (GraphQLError & {
      debugMessage?: string
      trace?: { call: string; file: string; line: number }[]
    })[]
  }
}

interface GraphQlErrorProps {
  error: Error

  retry(): void
}

export function GraphQlError(props: GraphQlErrorProps): ReactElement {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const retry = useCallback(() => {
    setLoading(true)
    props.retry()
  }, [props])

  useEffect(() => setLoading(false), [props.error])

  if (!props.error) {
    return <></>
  }

  const error = props.error as ExtendedError
  const source = error.source?.errors[0]
  const errorToShow = source || error

  let message = errorToShow.message
  if (process.env.NODE_ENV !== 'production' && 'debugMessage' in errorToShow) {
    message += ` (${errorToShow.debugMessage})`
  }

  return (
    <div>
      <p className={styles.lead}>
        {loading ? (
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        ) : (
          <FontAwesomeIcon icon={faExclamationCircle} title={message} />
        )}{' '}
        {t('error.serverError')}
      </p>

      {process.env.NODE_ENV !== 'production' &&
        'trace' in errorToShow &&
        errorToShow.trace && (
          <pre className={classNames(styles.code)}>
            <code>
              {message + '\n\n'}

              {errorToShow.trace
                .map((trace) => `${trace.call}@${trace.file}:${trace.line}`)
                .join('\n')}
            </code>
          </pre>
        )}

      <div className={styles.buttonContainer}>
        <PrimaryButton
          className={styles.button}
          disabled={loading}
          onClick={retry}
        >
          {t('error.retry')}
        </PrimaryButton>
      </div>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AvatarCropPopupSubmitAvatarMutationVariables = {
    file: unknown;
};
export type AvatarCropPopupSubmitAvatarMutationResponse = {
    readonly changeAvatar: {
        readonly id: string;
        readonly profileImage: string | null;
    };
};
export type AvatarCropPopupSubmitAvatarMutation = {
    readonly response: AvatarCropPopupSubmitAvatarMutationResponse;
    readonly variables: AvatarCropPopupSubmitAvatarMutationVariables;
};



/*
mutation AvatarCropPopupSubmitAvatarMutation(
  $file: Upload!
) {
  changeAvatar(file: $file) {
    id
    profileImage
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "file"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "file",
                    "variableName": "file"
                }
            ],
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "changeAvatar",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c4a705b6846edc611d0bb212088f12ac",
            "id": null,
            "metadata": {},
            "name": "AvatarCropPopupSubmitAvatarMutation",
            "operationKind": "mutation",
            "text": "mutation AvatarCropPopupSubmitAvatarMutation(\n  $file: Upload!\n) {\n  changeAvatar(file: $file) {\n    id\n    profileImage\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '7cfa9c616947bf4e437a5ab59ff6c5e8';
export default node;

import { ReactElement } from 'react'
import { Header } from '../../containers/Header'
import { Page } from '../../containers/Page'
import { LoadingIndicator } from './LoadingIndicator'

export function PageLoadingFallback(): ReactElement {
  return (
    <>
      <Header />

      <Page>
        <LoadingIndicator />
      </Page>
    </>
  )
}

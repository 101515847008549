import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'
import { useStores } from '../../stores'
import { ConfirmStopPracticingPopup as ConfirmStopPracticingPopupProps } from '../../stores/commonStore'

import styles from './ConfirmStopPracticingPopup.scss'

export function ConfirmStopPracticingPopup(
  props: ConfirmStopPracticingPopupProps
): ReactElement {
  const { t } = useTranslation()
  const { commonStore } = useStores()

  const confirm = useCallback((): void => {
    props.callback(true)
    commonStore.closePopup()
  }, [commonStore, props])
  const cancel = useCallback((): void => {
    props.callback(false)
    commonStore.closePopup()
  }, [commonStore, props])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('menu.leaveAlert.title')}</h1>

      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: t('menu.leaveAlert.text') }}
      />

      <PrimaryButton className={styles.primaryButton} onClick={cancel}>
        {t('menu.leaveAlert.continue')}
      </PrimaryButton>

      <SecondaryButton className={styles.secondaryButton} onClick={confirm}>
        {t('menu.leaveAlert.confirm')}
      </SecondaryButton>
    </div>
  )
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DuelRoundsOverview_right = ReadonlyArray<{
    readonly answerTime: number;
    readonly isCorrect: boolean;
    readonly roundNumber: number;
    readonly " $refType": "DuelRoundsOverview_right";
}>;
export type DuelRoundsOverview_right$data = DuelRoundsOverview_right;
export type DuelRoundsOverview_right$key = ReadonlyArray<{
    readonly " $data"?: DuelRoundsOverview_right$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_right">;
}>;



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "plural": true
    },
    "name": "DuelRoundsOverview_right",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answerTime",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCorrect",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roundNumber",
            "storageKey": null
        }
    ],
    "type": "DuelPlayerRound",
    "abstractKey": null
} as any;
(node as any).hash = '18c0bb2111caebaac66af51420fae934';
export default node;

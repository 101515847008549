import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'

import { DirectionAwareIcon } from './DirectionAwareIcon'

export function BackIcon(
  props: Omit<FontAwesomeIconProps, 'icon'>
): ReactElement {
  return (
    <DirectionAwareIcon
      ltrIcon={faChevronLeft}
      rtlIcon={faChevronRight}
      {...props}
    />
  )
}

import { KeyboardEvent, KeyboardEventHandler } from 'react'

export function handleEnterKey<T = Element>(
  callback?: KeyboardEventHandler<T>
): KeyboardEventHandler<T> {
  return (event: KeyboardEvent<T>): void => {
    if (event.key === 'Enter') {
      callback && callback(event)
    }
  }
}

export function useEnterKeyHandler<T = Element>(
  callback?: KeyboardEventHandler<T>
): KeyboardEventHandler<T> {
  return handleEnterKey(callback)
}

import bgBg from './bg-BG'
import csCz from './cs-CZ'
import deDe from './de-DE'
import elGr from './el-GR'
import enUs from './en-US'
import faIr from './fa-IR'
import frFr from './fr-FR'
import huHu from './hu-HU'
import itIt from './it-IT'
import neutral from './neutral'
import nlNl from './nl-NL'
import plPl from './pl-PL'
import ptBr from './pt-BR'
import roRo from './ro-RO'

export const locales = {
  neutral: { translation: neutral },

  'bg-BG': { translation: bgBg },
  'cs-CZ': { translation: csCz },
  'de-DE': { translation: deDe },
  'el-GR': { translation: elGr },
  'en-US': { translation: enUs },
  'fa-IR': { translation: faIr },
  'fr-FR': { translation: frFr },
  'hu-HU': { translation: huHu },
  'it-IT': { translation: itIt },
  'nl-NL': { translation: nlNl },
  'pl-PL': { translation: plPl },
  'pt-BR': { translation: ptBr },
  'ro-RO': { translation: roRo },
}

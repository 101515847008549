import { action, makeObservable, observable } from 'mobx'
import { commitMutation, graphql } from 'relay-runtime'

import { restartAppAfterLogout } from '../../js/jsBridge'
import { environment } from '../environment'
import { SelfActivatePageMutationResponse } from '../generated/SelfActivatePageMutation.graphql'

import { WebService } from '../services/WebService'

export const enum Severity {
  SUCCESS,
  ERROR,
}

export class AuthStore {
  static readonly TOKEN_STORAGE_KEY = 'pa_webservice_token'

  @observable
  public authCorporateId: string

  @observable
  public authLoading: boolean

  @observable
  public authMessage?: string

  @observable
  public authMessageSeverity: Severity = Severity.SUCCESS

  @observable
  public authPassword: string

  @observable
  public authRememberMe: boolean

  @observable
  public authUsername: string

  @observable
  public loadingUser: boolean

  @observable
  public token?: string

  public webservice: WebService

  public constructor(webservice: WebService) {
    this.webservice = webservice
    this.webservice.authStore = this

    this.authCorporateId = ''
    this.authLoading = false
    this.authPassword = ''
    this.authRememberMe = false
    this.authUsername = ''
    this.loadingUser = true

    makeObservable(this)
  }

  @action
  public attemptLoginSuccess = (
    // user: AppUserQueryResponse['me'],
    token?: string | null
  ): void => {
    this.authLoading = false
    this.authPassword = ''

    if (token) {
      this.setToken(token)
    }
  }

  @action
  public attemptLoginError = (error: string | undefined): void => {
    this.authLoading = false
    this.setAuthMessage(error, Severity.ERROR)
  }

  @action
  public clearToken(): void {
    this.token = ''

    localStorage.removeItem(AuthStore.TOKEN_STORAGE_KEY)
  }

  @action
  public logout(
    oldUserLoginType: ('LTI' | 'WEB' | 'SAML') | undefined | null
  ): boolean {
    if (!oldUserLoginType || oldUserLoginType != 'SAML') {
      commitMutation(environment, {
        mutation: graphql`
          mutation authStoreLogoutMutation {
            logout {
              success
            }
          }
        `,
        variables: {},
        optimisticUpdater(updater) {
          updater.getRoot().setValue(null, 'me')
        },
        onCompleted() {
          environment.commitUpdate((updater) => {
            updater.getRoot().setValue(null, 'me')
          })
        },
      })
      this.clearToken()
    } else {
      this.clearToken()

      location.href = process.env.APP_URL + '/logoutsaml'

      return false
    }

    restartAppAfterLogout()

    return true
  }

  @action
  public setAuthCorporateId(value: string): void {
    this.authCorporateId = value
  }

  @action
  public setAuthPassword(value: string): void {
    this.authPassword = value
  }

  @action
  public setAuthMessage(message: string | undefined, severity: Severity): void {
    this.authMessage = message
    this.authMessageSeverity = severity
  }

  @action
  public setAuthRememberMe(checked: boolean): void {
    this.authRememberMe = checked
  }

  @action
  public setAuthUsername(value: string): void {
    this.authUsername = value
  }

  @action
  public setToken(token?: string): void {
    this.token = token

    // Persist in localStorage.
    if (token) {
      localStorage.setItem(AuthStore.TOKEN_STORAGE_KEY, token)
    }
  }

  @action
  public requestNewPasswordSuccess = (message: string): void => {
    this.authLoading = false
    this.authMessage = message
    this.authMessageSeverity = Severity.SUCCESS
  }

  @action
  public requestSelfActivationSuccess = (
    response: SelfActivatePageMutationResponse['requestSelfActivation']
  ): void => {
    this.authLoading = false
    this.authMessage = response.message
    this.authMessageSeverity = response.success
      ? Severity.SUCCESS
      : Severity.ERROR
  }

  @action
  public setAuthLoading(loading: boolean): void {
    this.authLoading = loading
  }
}

interface Constructable<T> {
  new (): T
}

export default class Singleton {
  /**
   * The singleton instance.
   */
  private static _instance?: Singleton

  /**
   * Get the singleton instance.
   */
  public static getInstance<T extends Singleton>(this: Constructable<T>): T {
    // Ugly hack alert! See https://github.com/Microsoft/TypeScript/issues/5863#issuecomment-365918436
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const derived = this as any as typeof Singleton

    return (derived._instance || (derived._instance = new this())) as T
  }
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AvatarPopupUserQueryVariables = {};
export type AvatarPopupUserQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly fullName: string;
        readonly profileImage: string | null;
    } | null;
};
export type AvatarPopupUserQuery = {
    readonly response: AvatarPopupUserQueryResponse;
    readonly variables: AvatarPopupUserQueryVariables;
};



/*
query AvatarPopupUserQuery {
  me {
    id
    fullName
    profileImage
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthenticatedUser",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileImage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AvatarPopupUserQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AvatarPopupUserQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "8fd738e0c07cdab2667e75d3fdb5ec63",
            "id": null,
            "metadata": {},
            "name": "AvatarPopupUserQuery",
            "operationKind": "query",
            "text": "query AvatarPopupUserQuery {\n  me {\n    id\n    fullName\n    profileImage\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '662b9345d46160e5f4bd7a066e372208';
export default node;

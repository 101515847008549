const translation = {
  popup: {
    confirm: {
      delete: 'حذف',
      keep: 'بگذار بماند',
      cancelDuelInviteTitle: 'حذف دعوت به مسابقه',
      cancelDuelInviteMessage:
        'آیا مطمئن‌اید که می‌خواهید دعوت به مسابقه را حذف کنید؟',
    },
    comeBackLater: {
      heading: 'بعدا برگردید',
      explanation1:
        'شما تمام موارد موجود را تکمیل کرده اید. لطفاً بعداً برگردید تا ورود به پاور اپ را ادامه دهید.',
      explanation2: 'به روزرسانی بعدی شما در تاریخ:',
      buttonText: 'بازگشت به نمای کلی',
    },
    finishedOnboarding: {
      proceedHeading: 'یادگیری مداوم در پاور اپ',
      stayHeading: 'ورود به آن‌فایر انجام شد',
      proceedExplanation1:
        'تبریک! شما ثبت نام خود در آن‌فایر را کامل کرده‌اید. اکنون می توانید در پاور اپ ادامه دهید، جایی که ما پیوسته در سازمان خود یاد می‌گیریم.',
      proceedExplanation2:
        'آیا می خواهید به پاور اپ ادامه دهید یا مدتی در آن‌فایر بمانید؟ همیشه می‌توانید از منوی اصلی ادامه دهید.',
      stayExplanation: 'تبریک، شما ثبت نام خود را در آن‌فایر کامل کرده‌اید',
      proceedToPowerAppButtonText: 'به پاور اپ',
      stayInOnfireButtonText: 'در آن‌فایر بمان',
      closeButtonText: 'ببند',
    },
    privacyPolicy: {
      heading: 'سیاست حفظ حریم خصوصی',
      explanation1:
        'با استفاده از این برنامه شما با سیاست حفظ حریم خصوصی ما موافقت می‌کنید.',
      buttonText: 'تایید',
    },
    repetitionStateInfo: {
      heading: 'نشانگر تمرین',
      explanation: {
        general:
          'نشانگر تمرین، یک دورنمای فوری به شما می‌دهد که آیا زمان تمرین است یا نه. سه حالت جداگانه دارد. آن را مانند یک چراغ ترافیک در نظر بگیرید.',
        alert: 'قرمز: دانش‌ شما افت کرده است. زمان تمرین است.',
        warning:
          'نارنجی: پرسش‌هایی همچنان منتظر پاسخ شما هستند. هنگامی که نشانگر سبز می‌شود،‌ دانش شما به‌روز شده است.',
        complete: 'سبز: عالی، دانش شما به روز شده است! لطفا بعدا مراجعه کنید.',
      },
    },
  },
  header: {
    'welcome-message': 'سلام {{name}}!',
    welcome: 'خوش آمدید.',
    welcomeBack: 'خوش آمدید.',
  },
  learn: {
    instruction: 'موضوعات مورد تمرین خود را انتخاب کنید.',
    practiceSelection: 'موارد منتخب را تمرین کنید',
    selectAll: 'انتخاب همه',
    deselectAll: 'انتخاب را حذف کنید',
    wellDone: 'آفرین!',
    instructionLinear:
      'لحظه خاصی را انتخاب کنید که می خواهید اطلاعات بیشتری ببینید',
    instructionTimeline: 'برای دیدن اطلاعات بیشتر، یک موضوع خاص را انتخاب کنید',
    endOfTimeline: 'پایان جدول زمانی',
    switchToPowerApp: 'یادگیری مداوم را در پاور اپ ادامه دهید',
    congratsFinished: 'تبریک، کار شما تمام شد!',
    toThePowerapp: 'به پاور اپ',
    availableFrom: 'دسترسی از/با',
    available: 'در دسترس',
    availabilityUnknown: 'ناشناخته',
    startedAt: 'آغاز شده:',
    completedAt: 'تکمیل شده:',
    topicCategorySelectionIndicator: 'موضوع {{current}}/{{countFormatted}}',
    practiceAllNecessary: {
      message:
        '<p><b>آیا می‌خواهید سریع دانش‌ خود را به روز کنید؟</b></p><p>این سری از پرسش‌ها را پاسخ دهید تا هر چه سریع‌تر به وضعیت سبز دست یابید.</p>',
      upToDateMessage:
        '<p><b>کار شما عالی است!</b></p><p>می‌خواهید به‌روز بمانید؟ تمرین را ادامه دهید!</p>',
      practiceTopics: 'سری‌های از قبل انتخاب شده را تمرین کنید',
    },
    topics: {
      toDo: 'برای انجام',
      upToDate: 'به‌روز رسانی',
      practiceCheckbox: 'تمرین',
      extraPracticeButton: 'تمرین بیشتر',
    },
    repetitionState: {
      header: {
        complete: 'خیلی خوب. شما به روز هستید!',
        warning: 'برای به روز ماندن، تمرین را ادامه دهید.',
        alert: 'زمان آغاز تمرین است.',
        alertFirstLogin: 'اکنون می‌توانید تمرین را آغاز کنید.',
        showScore: 'امتیاز کلی شما: {{score}}%',
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'شما به روز هستید',
        upToDate: 'شما از قبل به روز هستید',
        nrToUpToDate: '{{nr}} برای رفتن',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'تکراری',
      newQuestion: 'جدید!',
    },
  },
  duels: {
    title: 'رتبه‌بندی مسابقه: {{score}}',
    readyToDuel: 'آیا آماده مسابقه هستید؟',
    roundsOverview: 'بررسی مراحل مسابقه',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'خوراک فکری',
      browsePrevious: 'صفحه قبلی',
      doneText: 'خوراک فکری انجام شد.',
      browseNext: 'صفحه بعد',
      challengingText: 'این اطلاعات را بخوانید',
      completedText: 'این اطلاعات را به خاطر بسپارید',
    },
    brainItem: {
      correct: 'درست',
      incorrect: 'نادرست',
      almostCorrect: 'تقریباً درست است',
      completed: 'شما یک پرسش را تکمیل کردید',
      doneText: 'انجام شده',
      openItem: 'شروع کنید',
      closeItem: 'ببند',
      topics: 'موضوع‌ها',
      nextItem: 'به تمرین ادامه دهید',
      nextOnboardingItem: 'ادامه به مورد بعدی',
      showProgress: 'پیشرفت را نشان دهید',
    },
    order: {
      questionTitle: 'به ترتیب بگذارید',
      helpText: 'پاسخ‌ها را بر اساس ترتیب‌ درست مرتب کنید.',
      correct: '{{num}} از {{max}} درست است ',
      challengingText: 'پاسخ‌ها را بر اساس ترتیب درست مرتب کنید.',
      completedText: 'شما قبلا به این پرسش پاسخ داده‌اید',
    },
    match: {
      questionTitle: 'وصل کنید',
      helpText: 'پاسخ‌ها را به نقطه‌ی درست مرتب کنید.',
      correct: '{{num}} از {{max}} درست است.',
      showCorrectAnswer: 'پاسخ درست را نشان بده',
      showingCorrectAnswer: 'پاسخ‌های درست را ببینید.',
      showMyAnswer: 'پاسخ من را نشان بده',
      challengingText: 'پاسخ‌ها را به نقطه درست مرتب کنید',
      completedText: 'شما قبلا به این پرسش پاسخ داده‌اید',
    },
    slider: {
      doneText: 'انجام شد',
      questionTitle: 'زبانه متحرک',
      helpText: 'زبانه متحرک را به موقعیت درست بکشید',
      challengingText: 'تخمین بزنید',
      completedText: 'شما قبلا به این پرسش پاسخ داده‌اید',
    },
    poll: {
      questionTitle: 'نظرسنجی',
      resultinfo: 'این پاسخ دیگران است',
      doneText: 'شما به یک سوال نظرسنجی پاسخ دادید',
      helpText: 'یک پاسخ را انتخاب کنید',
      challengingText: 'نظر خود را بیان کنید',
      completedText: 'شما نظر خود را بیان کرده‌اید',
    },
    notification: {
      title: 'اطلاعیه',
      pauze: 'می‌توانم ادامه بدم؟',
      continueanyway: 'به هر حال ادامه بده',
      doneText: 'اطلاعیه خوانده شد',
      continueLearningText:
        'شما در حال تمرین با پرسش‌های اضافه هستید. برای این پرسش‌ها امتیاز کمتری دریافت خواهید کرد زیرا الان به روز هستید.',
      panelbuttonlabel: 'تمرین اضافه را متوقف کن',
    },
    multipleselect: {
      correct: '{{num}} از {{max}} درست است',
      incorrect: '، {{incorrect}} نادرست است',
      doneText: 'پرسش تکمیل شد',
      questionTitle: 'سوال',
      helpText: 'یک یا چند پاسخ را انتخاب کنید',
      challengingText: 'به پرسش پاسخ دهید',
      completedText: 'شما قبلا به این پرسش پاسخ داده‌اید',
    },
    multiplechoice: {
      doneText: 'پرسش تکمیل شد',
      questionTitle: 'پرسش',
      helpText: 'یک پاسخ را انتخاب کنید',
      challengingText: 'به پرسش پاسخ دهید',
      completedText: 'شما قبلا به این پرسش پاسخ داده‌اید',
    },
    duelItem: {
      duel: 'مسابقه',
      draw: 'مساوی',
      invite: 'چالش مسابقه',
      acceptDuel: 'مسابقه را قبول کن',
      declineDuel: 'رد مسابقه',
      yourTurn: 'نوبت شماست',
      opponentTurn: 'نوبت حریف است',
      youWon: 'برنده شدید!',
      youLost: 'باختید!',
      cancelled: 'مسابقه لغو شد',
      closeItem: 'ببند',
      showDetails: 'جزئیات مراحل مسابقه',
      playItem: 'مرحله {{roundnumber}} را بازی کنید',
      roundNumber: 'مرحله {{number}}',
      duelAccepted: 'شما دعوت مسابقه را از {{name}} پذیرفتید.',
      duelDeclined: 'شما دعوت مسابقه را از {{name}} رد کردید.',
      duelStarted: 'بگذارید مسابقه با {{name}} شروع شود. موفق باشید!',
      duelRequest: 'چالش مسابقه تازه!',
      duelRequestText: '{{name}} شما را به یک مسابقه دعوت می کند',
      duelInfoInvitation: 'شما برای یک مسابقه به چالش کشیده شده اید.',
      duelInfoMain:
        'یک نبرد کوتاه علمی 5 مرحله‌ای. با پاسخ سریع و درست برنده شوید. بیشترین دور را ببرید و در مسابقه پیروز شوید و امتیاز خود را افزایش دهید. توجه داشته باشید که برای انجام یک مرحله کامل محدودیت 72 ساعته وجود دارد.',
      viewInvitation: 'مشاهده دعوت',
      versusLabel: 'در برابر',
      started: 'شروع کن',
      doneText: 'مسابقه تکمیل شد',
      tooLate: 'اتمام وقت!',
      tooLateTurn: 'خیلی دیر',
      practiceWhileWaiting: 'هنگام انتظار تمرین کنید',
      practice: 'به تمرین ادامه دهید',
      subheading: {
        waitForOpponent: 'منتظر پاسخ حریف خود باشید',
        yourTurn: 'برای رقابت آماده‌اید؟',
        timeOutOpponent: 'پاسخ حریف شما بیش از اندازه طول کشید',
        timeOutPlayer: 'پاسخ شما بیش از اندازه طول کشید',
        timeOutBoth: 'پاسخ هر دو شما بیش از اندازه طول کشید',
        wellDone: 'آفرین، ادامه دهید!',
        betterNextTime: 'دفعه آینده بخت بیشتری یارتان باد!',
      },
    },
    topicPin: {
      title: 'موضوع را در صدر قرار دهید',
      pinenabled:
        'شما {{- topiclabel}} را در صدر قرار دادید و فقط موارد مرتبط با این موضوع را می‌بینید. موارد مرتبط با این موضوع بیشتر تکرار می‌شوند تا بتوانید آن‌ها را تمرین کنید. دوباره روی موضوع اول کلیک کنید تا پرسش‌های مربوط به سایر موضوعات را شامل شود.',
      pindisabled:
        'موضوع دیگری در صدر قرار داده نشده است. از این به بعد ، دوباره مواردی را برای همه موضوعات مشاهده خواهید کرد.',
      continue: 'ادامه بده',
      dontshowagain: 'دوباره نشان نده',
      selectTopics: 'موضوعات را انتخاب کنید',
      selectTopicsPractice: 'موضوع‌های مورد تمرین خود را انتخاب کنید.',
    },
    topicInfoHeader: 'موضوعات',
    challengingTextStarted: 'ادامه از محل توقف پیشین',
  },
  login: { succesful: 'ورود به سیستم موفقیت‌آمیز بود.' },
  menu: {
    menu: 'فهرست',
    duel: 'مسابقه',
    duels: 'مسابقات',
    duelsNotAvailable: 'مسابقه هنوز برای شما در دسترس نیست.',
    duelsNotAvailableExplanation:
      'آیا می‌خواهید با همکاران خود مسابقه دهید؟ این را از مسول {{appName}} درخواست کنید.',
    duelsNotEnabled:
      'آیا می‌خواهید با همکاران خود مسابقه کنید؟ گزینه مسابقه زیر را فعال کنید.',
    duelsNotEnabledDisclaimer:
      'عکس و نام شما برای همکاران‌تان قابل دید خواهد بود.',
    enableDuelling: 'مسابقه کردن با همکاران را فعال کنید!',
    newDuel: 'مسابقه تازه',
    activeDuels: 'مسابقات فعال',
    duelVersus: 'در برابر',
    ranking: 'رتبه‌بندی',
    completedDuels: 'کامل شد',
    rating: 'رتبه‌بندی مسابقه',
    profile: 'نمایه',
    autoDuelLabel: 'مسابقات خودکار',
    duellingEnabledLabel: 'در مسابقه شرکت کنید',
    'help-and-settings': 'راهنما و تنظیمات',
    challengeplayer: 'یک همکار را به چالش بکشید',
    pickplayer: 'یک همکار را انتخاب کنید',
    randomplayer: 'حس می‌کنم بخت با من یار است.',
    select: 'انتخاب کنید',
    settings: 'تنظیمات',
    practice: 'تمرین',
    duelWaiting: 'در حال جستجو برای حریف ...',
    quickDuelFailed: 'از قبل دعوت‌های پاسخ داده نشده دارید',
    aboutDuels: {
      heading: 'دعوت به مسابقه چیست؟',
      explanation1: 'شما به یک مسابقه دعوت شده‌اید.',
      explanation2:
        'مسابقه یک دانش مختصر در برابر یک همکار در یک دور پنج‌گانه است. اگر سریع‌تر از همکار خود به س پرسش‌ها پاسخ درست دهید، یک دور برنده‌اید. اگر در دورهای بیشتری از حریف خود پیروز شوید، در مسابقه پیروز می‌شوید و در رده‌بندی صعود می کنید.',
      attention: 'توجه داشته باشید:',
      explanation3: 'هر دور را در عرض هفت روز بازی کنید!',
    },
    leaveAlert: {
      title: 'مطمئن‌اید؟',
      text: '<p> آیا مطمئن‌اید که می‌خواهید تمرین را متوقف کنید؟ </p> <p> پیشرفت شما ذخیره شده است. </p>',
      confirm: 'خروج',
      continue: 'ادامه تمرین',
    },
    onboarding: 'نمای کلی',
    timeline: 'جدول زمانی',
    info: 'اطلاعات',
  },
  ranking: {
    title: 'رتبه‌بندی مسابقه',
    subtitle: 'این رتبه، جایگاه شما را نسبت به همکاران‌تان نشان می‌دهد.',
    yourPosition: 'جایگاه شما ({{rank}})',
    topPositions: 'موقعیت‌های بالا',
    loadMore: 'بارگذاری بیشتر',
    empty: 'هنوز رتبه‌ای وجود ندارد. یک همکار را با مسابقه به چالش بکشید!',
    notRanked: 'یک مسابقه را به پایان برسانید و در رتبه‌بندی امتیاز بگیرید!',
  },
  searchFeedback: {
    title: 'همکار را جستجو کنید',
    oneResult: '{{amount}} همکار پیدا شد',
    searchMessage: 'همکاران پیدا شدند{{amount}}',
    overflowMessage:
      'فقط بهترین نتایج جستجو برای {{amount}} نشان داده شده است.',
  },
  searchDuel: {
    title: 'جستجوی حریف',
    rankingposition: 'موقعیت رتبه‌بندی {{rank}}',
    noduelpossible: 'مسابقه ممکن نیست',
    duelstillactive: 'در حال حاضر یک مسابقه فعال است',
    invitationsentondate: 'دعوت نامه در تاریخ {{date}} ارسال شد',
    searchPlaceholder: 'نامی را جستجو کنید',
    emptyList: 'متأسفانه هیچ کس در دسترس نیست. بعدا دوباره تلاش کنید.',
    emptyListSearch: 'نتیجه‌ای برای جستجو وجود ندارد/یافت نشد',
    randomOpponent:
      'به دنبال حریف بعدی شما هستیم. می‌توانید این صفحه را ببندید، وقتی مسابقه تان آماده شد به شما اطلاع خواهیم داد.',
  },
  activeDuels: {
    title: 'یک مسابقه موجود را بازی کنید.',
    subtitle: 'مسابقه ای {{num}} / {{max}} فعال هستند',
    emptyListNew: 'هنوز هیچ مسابقه ای ندارید. یک مسابقه تازه را شروع کنید!',
    emptyListCompleted:
      'شما تمام مسابقه های خود را تمام کرده‌اید، آفرین! یک فنجان قهوه به خود جایزه دهید.',
    status: {
      draw: 'قرعه‌کشی',
      expired: 'منقضی شده',
      lost: 'شکست خوردید',
      invited: 'دعوت شد',
      waiting: 'در انتظار',
      won: 'برنده شد',
      yourturn: 'بازی',
    },
  },
  completedDuels: {
    title: 'مسابقات تکمیل شده خود را ببینید.',
    empty: 'هنوز مسابقه تکمیل نشده ندارید. ابتدا یک مسابقه را تمام کنید!',
  },
  error: {
    '404': 'سرور یافت نشد (HTTP 404).',
    '500': 'خطای داخلی سرور (HTTP 500).',
    internetdown: 'اتصال به اینترنت قطع شد!',
    versionConflict: {
      title: 'نسخه جدید',
      text: 'نسخه تازه‌ای از این برنامه در دسترس است. برای اطمینان از اجرا شدن آخرین نسخه از سوی همگی، اکنون شما را به تازه‌ترین نسخه به‌روز می‌کنیم.',
      refresh: 'به تازه‌ترین نسخه بروید',
    },
    refreshButton: 'برای تازه کردن کلیک کنید',
    internetup: 'به اینترنت وصل شدید.',
    timeoutajax: 'زمان درخواست سرور به پایان رسیده است.',
    unknown: 'خطای ناشناخته',
    tryReconnect: 'تلاش برای اتصال مجدد ...',
    alreadyAnswered: 'این مورد قبلا پاسخ داده شده است',
    unavailableOffline: {
      title: 'ارتباط اینترنتی وجود ندارد',
      subtitle: 'در حال حاضر هیچ ارتباطی وجود ندارد',
      explanation:
        'اتصال به اینترنت برای استفاده از برنامه مورد نیاز است. بررسی کنید که آیا می‌توانید دوباره به اینترنت وصل شوید یا بعداً دوباره تلاش کنید.',
    },
  },
  common: {
    CheckAnswer: 'پاسخ را بررسی کنید',
    CheckAnswers: 'پاسخ‌ها را بررسی کنید',
    Emailaddress: 'آدرس ایمیل',
    Name: 'نام',
    Checking: 'در حال چک کردن...',
    Answer: 'پاسخ من را ذخیره کن',
    Close: 'ببند',
    browsePrevious: 'قبلی',
    browseNext: 'بعدی',
    Accept: 'تایید',
    'Log out': 'خروج',
    Help: 'کمک',
    Hello: 'سلام',
    Correct: 'درست',
    Incorrect: 'نادرست',
    CorrectNotChosen: 'می‌تواند درست باشد',
    Round: 'مرحله',
    Version: 'نسخه',
    selected: 'انتخاب شد',
    'No Rating': 'هنوز رتبه‌بندی نشده است',
    'No Rank': 'بدون رتبه بندی',
    NoUserSelection: 'کاربر/کاربری پیدا نشد',
    monthNames:
      'ژانویه فوریه مارس آوریل مه ژوئن ژوئیه آگوست سپتامبر اکتبر نوامبر دسامبر',
    or: 'یا',
    on: 'بر',
    off: 'خاموش',
    back: 'بازگشت',
  },
  profile: {
    title: 'نمایه',
    subtitle: 'نمایه خود را اینجا تنظیم کنید.',
    pictureEdit: 'عکس خود را مشاهده یا ویرایش کنید.',
    'file-submit': 'بارگذاری',
    'picture-submit': 'از این محصول استفاده کنید',
    pictureCancel: 'لغو',
    pictureConfirmDelete:
      'آیا مطمئن‌اید که می‌خواهید عکس نمایه خود را حذف کنید؟',
    'picture-nofileselected': 'فایلی را انتخاب نکردید.',
    'picture-nofile': 'هیچ پرونده‌ای وجود ندارد.',
    'picture-invalidsize':
      'این عکس خیلی بزرگ است. حداکثر عرض یا ارتفاع نمی تواند بیش از 2600 پیکسل باشد.',
    'picture-notimage': 'به نظر نمی‌رسد فایلی که انتخاب کردید عکس باشد.',
    passwordEdit: 'گذرواژه را تغییر دهید',
    'picture-saved': 'عکس نمایه شما ذخیره شد!',
    passwordTitle: 'گذرواژه را تغییر دهید',
    passwordText:
      'برای تغییر گذرواژه، یک بار گذرواژه‌ کنونی و دو بار گذرواژه تازه را وارد کنید. گذرواژه تازه باید دست‌کم 6 نویسه داشته باشد.',
    'password-old': 'گذرواژه کنونی',
    'password-new': 'گذرواژه تازه',
    'password-repeat': 'گذرواژه تازه را تأیید کنید',
    'password-submit': 'گذرواژه تازه را ذخیره کنید',
    'password-oldpasswordmismatch': 'گذرواژه کنونی شما نادرست است',
    'password-newpasswordmismatch': 'گذرواژه تأیید شده همخوانی ندارد.',
    'password-passwordtooshort': 'گذرواژه تازه باید دست‌کم 6 نویسه داشته باشد.',
    'password-passwordsaved': 'گذرواژه شما ذخیره شد!',
    profilePictureTitle: 'عکس نمایه',
    profilePictureText: 'این عکس برای همکاران شما قابل مشاهده است.',
    generalDataTitle: 'اطلاعات شخصی',
    generalDataText: 'شما نمی‌توانید همه داده‌های شخصی را خودتان تغییر دهید.',
    profilePageTitle: 'نمایه خود را مدیریت کنید.',
    noEditDataText: 'شما نمی‌توانید این داده‌ها را تغییر دهید.',
    profileLanguagePreference: 'اولویت زبان',
    profileLanguageChoice: 'انتخاب زبان',
    notificationsTitle: 'اطلاعیه',
    emailNotifications: 'اطلاعیه های ایمیلی',
    pushNotifications: 'اطلاعیه های موبایل',
  },
  auth: {
    header: {
      login: 'ورود',
      expired: 'لینک منقضی شد',
      remind: 'گذرواژه خود را به یاد نمی‌آورید؟',
      create: 'حساب خود را فعال کنید',
      reset: 'گذرواژه خود را به روز کنید',
      welcome: 'خوش آمدید!',
    },
    field: {
      email: 'آدرس ایمیل',
      password: 'گذرواژه',
      oldPassword: 'گذرواژه کنونی',
      newPassword: 'گذرواژه تازه',
      newRepeatPassword: 'گذرواژه تازه را تکرار کنید',
      acceptPrivacyPolicy: 'من <2> سیاست حفظ حریم خصوصی </ 2> را می‌پذیرم.',
      viewPrivacyPolicy: 'سیاست حفظ حریم خصوصی را ببینید.',
      rememberMe: 'مرا به یاد بسپار',
    },
    action: {
      itemcompleted: 'شما یک مورد را تکمیل کرده‌اید',
      updateprofile: 'بروزرسانی نمایه',
      sendLink: 'لینک تنظیم مجدد گذرواژه را برایم ارسال کنید',
      backToLogin: 'می‌خواهم وارد شوم',
      sendActivateLink: 'حساب را فعال کنید',
      alreadyActivatedLogin:
        'من قبلاً حساب را فعال کرده‌ام و می‌خواهم وارد سیستم شوم',
      savePassword: 'گذرواژه تازه من را ذخیره کن',
      newPassword: 'گذرواژه تازه شما',
      repeatNewPassword: 'گذرواژه تازه خود را تکرار کنید',
      newPasswordPolicyIntro: 'قدرت گذرواژه:',
    },
    selfActivate: { header: 'حساب خود را فعال کنید' },
    logintext: 'ورود',
    remindText:
      'اشکال ندارد. برای شما ایمیل می‌فرستیم تا بتوانید گذرواژه جدید بسازید.',
    resetText: 'می‌توانید گذرواژه تازه خود را اینجا بسازید.',
    createText:
      'خوش آمدید! لطفاً یک گذرواژه تازه بسازید و سپس آماده ورود به سیستم هستید.',
    passwords: {
      user: 'متأسفم‌، کاربری با این آدرس ایمیل پیدا نکردیم.',
      token: 'این لبینک تنظیم مجدد گذرواژه نامعتبر است.',
      sent: 'لینک تنظیم مجدد ایمیل شد.',
      reset: 'گذرواژه تنظیم مجدد شد!',
      forgot: 'من گذرواژه خود را نمی‌دانم',
      passwordExpiredTitle: 'گذرواژه منقضی شد',
      submitPasswordExpiredForm: 'گذرواژه تازه را ذخیره کنید',
      passwordExpiredText:
        'گذرواژه شما قدیمی‎تر از {{days}} بوده و منقضی شده است. شما باید با استفاده از فرم زیر گذرواژه تازه‌ای بسازید، پس از آن می‌توانید سپس در پاور اپ به یادگیری ادامه دهید',
    },
  },
  faq: {
    header: 'پرسش‌های متداول',
    q1: 'پاور اپ برنامه یادگیری خرد‌ است، این به چه معناست؟',
    a1: 'یادگیری خرد‌ یعنی با هدف حفظ دانش بیشتر در درازمدت، یادگیری در جلسات کوتاه کارآمدتر است.',
    q2: 'چرا نمره من گاهی کمتر از حد انتظار است؟',
    a2: 'نمره شما کاهش می‌یابد زیرا با گذشت زمان آنچه را آموخته‎اید فراموش می‌کنید.',
    q3: 'چه زمانی نمره من کاهش می‌یابد؟',
    a3: 'وقتی به پرسش‌ها پاسخ نادرست داده‎، یا برای مدتی غیرفعال بوده‌ باشید.',
    q4: 'چرا موارد مشابه تکرار می‎شوند؟',
    a4: 'زیرا تکرار با فواصل موثرترین روش یادگیری‌ست.',
    q5: 'مسابقه چیست؟',
    a5: 'یک مسابقه علمی 5 پرسشه‌ که در آن با یک همکار رقابت می‌کنید.',
    q6: 'چرا هنگام بازی مسابقه باید منتظر نوبت خودم باشم؟',
    a6: 'باید منتظر بمانید تا همکار شما نوبت خود را تمام کند',
    q7: 'از کجا می‌توانم اطلاعات و پاسخ بیشتری به سوالات خود پیدا کنم؟',
    a7: 'دکمه زیر شما را به وب‌سایت ما می‌برد.',
    toTheFaq: 'به سوالات متداول',
    toTheWebsite: 'به وب‌سایت',
    onBoarding: {
      q1: 'از برنامه آن‌فایر برای چه استفاده می‌کنم؟',
      a1: 'به عنوان یک محل گرد‌آوری مناسب همه اطلاعات و وظایف.',
      q2: 'چرا باید منتظر اطلاعات تازه باشم؟',
      a2: 'اکنون همه چیز مرتبت نیست، بنابراین شما را تحت فشار قرار نمی‌دهیم.',
      q3: 'از کجا می‌توانم اطلاعات بیشتری بیابم؟',
      a3: 'دکمه زیر شما را به وب‌سایت ما می‌برد.',
    },
  },
}

export default translation

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TFunction } from 'i18next'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import {
  DuelRoundsOverview_left,
  DuelRoundsOverview_left$key,
} from '../../generated/DuelRoundsOverview_left.graphql'
import {
  DuelRoundsOverview_right,
  DuelRoundsOverview_right$key,
} from '../../generated/DuelRoundsOverview_right.graphql'

import { NumberBubble } from '../common/NumberBubble'

import styles from './DuelRoundsOverview.scss'
import { classNames } from '../../utils/classNames'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import {
  DuelRoundsOverview_duel$key,
  DuelStatus,
} from '../../generated/DuelRoundsOverview_duel.graphql'

interface DuelRoundsOverviewProps {
  duel: DuelRoundsOverview_duel$key | null
  currentRound?: number
  left: DuelRoundsOverview_left$key | null
  leftTimedOut: boolean
  right: DuelRoundsOverview_right$key | null
  rightTimedOut: boolean
}

interface ScoreDetails {
  className: string
  icon: IconDefinition | undefined
  text: string
}

const enum PlayerType {
  Current,
  Opponent,
}

function formatTime(locale: string, time?: number): string {
  if (typeof time === 'undefined') {
    return '-'
  }

  const seconds = Math.round(time / 10) / 100

  try {
    return Intl.NumberFormat(locale, {
      style: 'unit',
      unit: 'second',
    }).format(seconds)
  } catch {
    // Not all browsers support the unit option
    return Intl.NumberFormat(locale).format(seconds) + ' s'
  }
}

type RoundDetails = DuelRoundsOverview_left[0] | DuelRoundsOverview_right[0]
function getScoreDetails(
  playerType: PlayerType,
  duelStatus: DuelStatus | null,
  base: RoundDetails | undefined,
  baseCorrectCount: number,
  compare: RoundDetails | undefined,
  compareCorrectCount: number,
  round: number,
  timedOut: boolean,
  compareTimedOut: boolean,
  currentRound: number | undefined,
  t: TFunction,
  language: string
): ScoreDetails {
  const d: ScoreDetails = {
    className: '',
    icon: base && base.isCorrect ? faCheck : base ? faTimes : undefined,
    text: '-',
  }
  if (base) {
    d.className = getScoreClassName(
      playerType,
      duelStatus,
      base,
      baseCorrectCount,
      compare,
      compareCorrectCount,
      timedOut,
      compareTimedOut
    )

    d.text = formatTime(language, base.answerTime)

    if (typeof currentRound !== 'undefined' && currentRound <= round + 1) {
      d.className = ''
      d.icon = faQuestion
      d.text = '_ _ : _ _s'
    }
  } else if (timedOut) {
    d.icon = faStopwatch
    d.className = styles.timeOut
    d.text = t('streamItem.duelItem.tooLateTurn')
  }
  return d
}

function getScoreClassName(
  playerType: PlayerType,
  duelStatus: DuelStatus | null,
  base: RoundDetails | undefined,
  baseCorrectCount: number,
  compare: RoundDetails | undefined,
  compareCorrectCount: number,
  timedOut: boolean,
  compareTimedOut: boolean
): string {
  // In case of a timeout the circle should be yellow with a stopwatch in it.
  if (timedOut) {
    return styles.timeOut
  }

  // If there's no data yet, show nothing.
  if (!base) {
    return ''
  }

  // If the answer was wrong, the circle should be red with a cross in it.
  if (!base.isCorrect) {
    return styles.wrong
  }

  // All correct & green bullet conditions:
  if (
    (compare &&
      ((duelStatus === 'WON' &&
        // If the answer was correct and you won with more correct answers than the opponent.
        (baseCorrectCount > compareCorrectCount ||
          // If the answer was correct and you won with an equal amount of correct answers and you were faster.
          (baseCorrectCount === compareCorrectCount &&
            base.answerTime <= compare.answerTime) ||
          (!compare.isCorrect && playerType === PlayerType.Opponent))) ||
        (duelStatus === 'LOST' &&
          // If the answer was correct and you lost with fewer correct answers than the opponent.
          ((baseCorrectCount < compareCorrectCount && !compare.isCorrect) ||
            playerType === PlayerType.Opponent)) ||
        // If the answer was correct and you lost with an equal or less amount of correct answers as the opponent, the circle
        // should be green if you were faster (or the opponent answered incorrectly on this question).
        (baseCorrectCount <= compareCorrectCount &&
          (!compare.isCorrect || base.answerTime <= compare.answerTime)))) ||
    // This handles the case where there is no compare. If the duel was a draw, there should always
    // be both a base and a compare, or neither.
    (compareTimedOut && duelStatus !== 'DRAW')
  ) {
    return styles.correct
  }

  // In all other cases where you answered correctly (see two if statements above), the circle should be yellow.
  return styles.timeOut
}

export function DuelRoundsOverview(
  props: DuelRoundsOverviewProps
): ReactElement {
  const { t, i18n } = useTranslation()

  const left = useFragment(
    graphql`
      fragment DuelRoundsOverview_left on DuelPlayerRound @relay(plural: true) {
        answerTime
        isCorrect
        roundNumber
      }
    `,
    props.left || []
  )
  const right = useFragment(
    graphql`
      fragment DuelRoundsOverview_right on DuelPlayerRound
      @relay(plural: true) {
        answerTime
        isCorrect
        roundNumber
      }
    `,
    props.right || []
  )

  const duel = useFragment(
    graphql`
      fragment DuelRoundsOverview_duel on Duel {
        status
      }
    `,
    props.duel
  )

  const rows: ReactElement[] = []
  for (let i = 0; i < 5; ++i) {
    if (i > 0) {
      rows.push(
        <tr className={styles.spacer} key={`spacer${i}`}>
          <td colSpan={5} />
        </tr>
      )
    }

    const leftRound = i < left.length ? left[i] : undefined
    const leftCorrectCount = left.filter((round) => round.isCorrect).length
    const rightRound = i < right.length ? right[i] : undefined
    const rightCorrectCount = right.filter((round) => round.isCorrect).length
    const leftScoreDetails = getScoreDetails(
      PlayerType.Opponent,
      duel?.status ?? null,
      leftRound,
      leftCorrectCount,
      rightRound,
      rightCorrectCount,
      i,
      props.leftTimedOut && left.length === i,
      props.rightTimedOut,
      props.currentRound,
      t,
      i18n.language
    )
    const rightScoreDetails = getScoreDetails(
      PlayerType.Current,
      duel?.status ?? null,
      rightRound,
      rightCorrectCount,
      leftRound,
      leftCorrectCount,
      i,
      props.rightTimedOut && right.length === i,
      props.leftTimedOut,
      props.currentRound,
      t,
      i18n.language
    )

    rows.push(
      <tr className={styles.round} key={i}>
        <td className={classNames(styles.result, leftScoreDetails.className)}>
          <span className={styles.resultIndicator}>
            {leftScoreDetails.icon && (
              <FontAwesomeIcon icon={leftScoreDetails.icon} />
            )}
          </span>
        </td>
        <td className={classNames(styles.time, leftScoreDetails.className)}>
          {leftScoreDetails.text}
        </td>
        <td className={styles.roundNumber}>
          <NumberBubble className={styles.circle} text={i + 1} />
        </td>
        <td className={classNames(styles.time, rightScoreDetails.className)}>
          {rightScoreDetails.text}
        </td>
        <td
          className={classNames(
            styles.result,
            styles.mine,
            rightScoreDetails.className
          )}
        >
          <span className={styles.resultIndicator}>
            {rightScoreDetails.icon && (
              <FontAwesomeIcon icon={rightScoreDetails.icon} />
            )}
          </span>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <legend className={styles.legend}>{t('duels.roundsOverview')}</legend>

      <table className={styles.rounds}>
        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}

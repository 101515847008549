import { observer } from 'mobx-react'
import { ReactElement, useCallback } from 'react'

import { Alert } from '../common/Alert'
import { useStores } from '../../stores'
import { Severity } from '../../stores/authStore'

export const AuthAlert = observer(function AuthAlert(): ReactElement {
  const { authStore } = useStores()
  const clearMessage = useCallback(() => {
    authStore.setAuthMessage(undefined, Severity.SUCCESS)
  }, [authStore])

  if (!authStore.authMessage) {
    return <></>
  }

  return (
    <Alert
      autoClear={clearMessage}
      message={authStore.authMessage}
      type={
        authStore.authMessageSeverity === Severity.SUCCESS ? 'success' : 'error'
      }
    />
  )
})

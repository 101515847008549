import { ReactElement } from 'react'

import styles from './EmptyList.scss'

interface EmptyListProps {
  renderItem(index: number): ReactElement
}

export function EmptyList(props: EmptyListProps): ReactElement {
  const children: ReactElement[] = []
  for (let i = 0; i < 3; ++i) {
    children.push(props.renderItem(i))
  }

  return (
    <div aria-hidden={true} className={styles.emptyList}>
      {children}
    </div>
  )
}

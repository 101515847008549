const translations = {
  popup: {
    confirm: {
      delete: 'Изтриите',
      keep: 'Отменям',
      cancelDuelInviteTitle: 'Изтриите?',
      cancelDuelInviteMessage: 'Сигурни ли сте?',
    },
    repetitionStateInfo: {
      heading: 'Индикатор за практикуване',
      explanation: {
        general:
          'Индикаторът за практикуване ви дава незабавна информация дали е време за упражнение или не. Той има три отделни етапа. Приемете го като светофар.',
        alert: 'Червено: Вашите знания са се влошили. Време е за упражнение.',
        warning:
          'Оранжево: Все още има чакащи въпроси. След като индикаторът стане зелен, знанията ви са актуални.',
        complete:
          'Зелено: Идеално, знанията ви са напълно актуални! Върнете се по-късно.',
      },
    },
  },
  header: {
    'welcome-message': 'Здравейте, {{name}}!',
    welcome: 'Добре дошли.',
    welcomeBack: 'Добре дошли отново.',
  },
  learn: {
    instruction: 'Изберете темите, в които искате да играете.',
    practiceSelection: 'Практикувайте този избор',
    selectAll: 'Избиране на всички',
    deselectAll: 'Премахване на селекцията',
    wellDone: 'Много добре!',
    topicCategorySelectionIndicator: 'Тема {{current}}/{{countFormatted}}',
    practiceAllNecessary: {
      message:
        '<p><b>Искате ли бързо да обновите знанията си?</b></p><p>Отговорете на тази поредица от въпроси, за да постигнете зелен статус възможно най-бързо.</p>',
      upToDateMessage:
        '<p><b>Справяте се отлично!</b></p><p>Искате знанията ви да са актуални? Продължавайте да се упражнявате!</p>',
      practiceTopics: 'Предварително избрана поредица за упражнения',
    },
    topics: {
      toDo: 'Задачи за изпълнение',
      upToDate: 'Завършени',
      practiceCheckbox: 'Практикуване',
      extraPracticeButton: 'Допълнителна практика',
    },
    repetitionState: {
      header: {
        complete: 'Много добре. Знанията ви са актуални!',
        warning: 'Продължете да се упражнявате, за да не изоставате.',
        alert: 'Време е да започнете да се упражнявате.',
        alertFirstLogin: 'Можете да започнете да се упражнявате сега.',
        showScore: 'Вашият общ резултат: {{score}}%',
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'Знанията ви са актуални',
        upToDate: 'Знанията ви вече са актуални',
        nrToUpToDate: 'остават още {{nr}}',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'Повторен',
      newQuestion: 'Нов!',
    },
  },
  duels: {
    title: 'Класация на дуела: {{score}}',
    readyToDuel: 'Готови ли сте за дуел?',
    roundsOverview: 'Общ преглед на кръговете на дуела',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Предишна страница',
      doneText: 'Brainsnack завършен',
      browseNext: 'Следваща страница',
    },
    brainItem: {
      correct: 'Правилно',
      incorrect: 'Неправилно',
      completed: 'Отговорихте на въпрос',
      doneText: 'Готово',
      openItem: 'Начало',
      closeItem: 'Затваряне',
      topics: 'Теми',
      nextItem: 'Продължете да практикувате',
      showProgress: 'Показване на Вашия напредък',
    },
    order: {
      questionTitle: 'Ред',
      helpText: 'Преместете отговорите в правилната последователност',
      correct: 'Правилен {{num}} на {{max}}',
    },
    match: {
      questionTitle: 'Въпрос',
      showingCorrectAnswer: 'Вижте правилните отговори.',
      helpText: 'Преместете отговорите на правилното място',
      correct: 'Правилен {{num}} на {{max}}',
      showCorrectAnswer: 'Показване на правилния отговор',
      showMyAnswer: 'Показване на моя отговор',
    },
    slider: {
      doneText: 'Готово',
      questionTitle: 'Плъзгач',
      helpText: 'Преместете плъзгача на правилната позиция',
    },
    poll: {
      questionTitle: 'Анкета',
      resultinfo: 'Това са отговорите на другите',
      doneText: 'Отговорихте на въпрос от анкетата',
      helpText: 'Изберете един отговор',
    },
    notification: {
      title: 'Известие',
      pauze: 'Мога ли да продължа?',
      continueanyway: 'Продължаване въпреки това',
      doneText: 'Известието е прочетено',
      continueLearningText:
        'Вие се упражнявате с допълнителни въпроси.Ще получавате по-малко точки за тях, защото вече сте достигнали нужния брой.',
      panelbuttonlabel: 'Прекратяване на допълнителните упражнения',
    },
    multipleselect: {
      correct: 'Правилен {{num}} на {{max}}',
      incorrect: ', неправилен {{incorrect}}',
      doneText: 'Въпросът е финализиран',
      questionTitle: 'Въпрос',
      helpText: 'Изберете един или повече отговора',
    },
    multiplechoice: {
      doneText: 'Въпросът е финализиран',
      questionTitle: 'Въпрос',
      helpText: 'Изберете един отговор',
    },
    duelItem: {
      duel: 'Дуел',
      draw: 'Равенство',
      invite: 'Предизвикателство за дуел',
      acceptDuel: 'Приемане на дуела',
      declineDuel: 'Отхвърляне на дуела',
      yourTurn: 'Вие сте на ход',
      opponentTurn: 'Редът на противника',
      youWon: 'Победихте!',
      youLost: 'Загубихте',
      cancelled: 'Дуелът е отменен',
      closeItem: 'Затваряне',
      showDetails: 'Подробности за рунда',
      playItem: 'Играйте рунд {{roundnumber}}',
      duelAccepted: 'Приехте покана за дуел от {{name}}.',
      duelDeclined: 'Отхвърлихте покана за дуел от {{name}}.',
      duelStarted: 'Нека дуелът с {{name}} започне.Успех!',
      duelInfoInvitation: 'Предизвикани сте на дуел.',
      duelInfoMain:
        'Кратка битка за знания в 5 рунда.Спечелете, като отговорите бързо и вярно.Спечелете повечето рундове, спечелете дуела и увеличете оценката си.Имайте предвид, че има 72-часов лимит за рунда.',
      viewInvitation: 'Преглед на поканата',
      versusLabel: 'срещу',
      started: 'Старт!',
      doneText: 'Дуелът е завършен',
      tooLate: 'Времето изтече!',
      practiceWhileWaiting: 'Упражнявайте се, докато чакате',
      practice: 'Продължавайте да се упражнявате',
      subheading: {
        waitForOpponent: 'Изчакайте отговора на противника',
        yourTurn: 'Готови ли сте?',
        timeOutOpponent:
          'На Вашия опонент беше необходимо прекалено дълго време, за да отговори',
        timeOutPlayer:
          'Беше Ви необходимо прекалено дълго време, за да отговорите',
        timeOutBoth:
          'И на двамата Ви беше необходимо прекалено дълго време, за да отговорите',
        wellDone: 'Добра работа, продължавайте в този дух!',
        betterNextTime: 'Успех следващия път!',
      },
      roundNumber: 'Кръг {{number}}',
      duelRequest: 'Ново предизвикателство за дуел!',
      duelRequestText: '{{name}} ви предизвиква на дуел',
      tooLateTurn: 'Твърде късно',
    },
    topicPin: {
      title: 'Прикачете темата',
      pinenabled:
        'Прикачихте {{- topiclabel}} и виждате само елементи, свързани с тази тема.Елементите, свързани с тази тема, се повтарят по-често, за да можете да се упражнявате по тях. Кликнете отново върху прикачената тема, за да включите въпроси, свързани с други теми.',
      pindisabled:
        'Темата вече не е прикачена.Отсега нататък отново ще виждате елементи за всички теми.',
      continue: 'Продължаване',
      dontshowagain: 'Не показвайте това отново',
      selectTopics: 'Избор на теми',
      selectTopicsPractice: 'Изберете темите, с които искате да практикувате.',
    },
    topicInfoHeader: 'Теми',
  },
  login: {
    succesful: 'Успешно влизане.',
  },
  menu: {
    menu: 'Меню',
    duel: 'Дуел',
    duels: 'Дуели',
    newDuel: 'Нов дуел',
    activeDuels: 'Активни дуели',
    duelVersus: 'СРЕЩУ',
    ranking: 'Класиране',
    rating: 'Оценка на дуела',
    profile: 'Профил',
    autoDuelLabel: 'Автоматични дуели',
    'help-and-settings': 'Помощ и настройки',
    challengeplayer: 'Предизвикайте колега',
    pickplayer: 'Изберете колега',
    randomplayer: 'Чувствам се късметлия',
    duelWaiting: 'Търсене на опонент...',
    quickDuelFailed: 'Вече имате изчакващи покани',
    duelsNotAvailable: 'Участието в дуел все още не е налично за Вас.',
    duelsNotAvailableExplanation:
      'Искате ли да се дуелирате с колегите? Свържете се с вашият {{appName}} администратор.',
    duelsNotEnabled:
      'Искате ли да участвате в дуели с колеги? Активирайте опцията за дуел по-долу.',
    duelsNotEnabledDisclaimer:
      'Снимката и името Ви ще бъдат видими за Вашите колеги.',
    enableDuelling: 'Активирайте дуелите с колеги!',
    duellingEnabledLabel: 'Участвайте в дуели',
    completedDuels: 'Завършено',
    select: 'Избор',
    settings: 'Настройки',
    practice: 'Практикуване',
    aboutDuels: {
      heading: 'Какво е покана за дуел?',
      explanation1: 'Предизвикани сте на дуел.',
      explanation2:
        'Дуелът е кратък тест за знания срещу колега в 5 кръга. Печелите даден кръг, ако отговорите правилно на въпроса по-бързо от колегата си. Ако спечелите повече кръгове от опонента си, печелите дуела и се придвижвате нагоре в класацията.',
      attention: 'Забележка:',
      explanation3: 'Трябва да изиграете всеки кръг в рамките на 7 дни!',
    },
    leaveAlert: {
      title: 'Сигурни ли сте?',
      text: '<p>Сигурни ли сте, че искате да спрете да практикувате?</p><p>Вашият напредък е записан.</p>',
      confirm: 'Спиране',
      continue: 'Продължете да практикувате',
    },
    info: 'Информация',
  },
  ranking: {
    title: 'Класация на дуелите',
    subtitle:
      'Тази оценка показва Вашия ранг в дуела спрямо тези на Вашите колеги.',
    loadMore: 'Заредете повече',
    yourPosition: 'Вашата позиция ({{rank}})',
    topPositions: 'Топ позиции',
    empty: 'Няма класиране – стартирайте дуел!',
    notRanked: 'Завършете дуел, за да участвате в класирането!',
  },
  searchFeedback: {
    title: 'Търсене на колега',
    oneResult: 'Намерен {{amount}} колега.',
    searchMessage: 'Намерени {{amount}} колеги.',
    overflowMessage:
      'Показват се само {{amount}} най-добри резултата от търсенето.',
  },
  searchDuel: {
    title: 'Търсене на опонент',
    rankingposition: 'Позиция в класирането {{rank}}',
    noduelpossible: 'Дуелът не е възможен',
    duelstillactive: 'Дуелът вече е активен',
    invitationsentondate: 'Поканата е изпратена на {{date}}',
    searchPlaceholder: 'Търсене по име',
    randomOpponent:
      'Търсим следващия Ви опонент. Може да затворите този екран – ще Ви уведомим, когато Вашият дуел е готов.',
    emptyListSearch: 'Няма резултати от търсенето. ',
    emptyList:
      'За съжаление, никой не е наличен.<br>Опитайте отново по-късно. ',
  },
  activeDuels: {
    title: 'Участвайте в наличен дуел.',
    subtitle: '{{num}}/{{max}} дуели са активни',
    status: {
      draw: 'Равенство',
      lost: 'Загубени',
      won: 'Спечелени',
      expired: 'С изтекла валидност',
      invited: 'С изпратена покана',
      waiting: 'В изчакване',
      yourturn: 'Игра',
    },
    emptyListNew: 'Няма дуел – стартирайте нов дуел!',
    emptyListCompleted:
      'Всички дуели са завършени, добра работа!<br>Изпийте чаша кафе като награда. ',
  },
  completedDuels: {
    title: 'Прегледайте завършените дуели.',
    empty: 'Все още не сте завършили нито един дуел.<br>Започнете нов дуел!',
  },
  error: {
    404: 'Сървърът не е намерен (HTTP 404).',
    500: 'Вътрешна грешка на сървъра (HTTP 500).',
    internetdown: 'Загубена връзка с интернет!',
    versionConflict: {
      title: 'Нова версия',
      text: 'Има нова версия на това приложение. За да сме сигурни, че всички изпълняват най-новата версия, ще Ви актуализираме до най-новата версия сега. ',
      refresh: 'Към най-новата версия ',
    },
    refreshButton: 'Кликнете, за да обновите',
    internetup: 'Има връзка с интернет.',
    timeoutajax: 'Времето за изчакване на сървъра е изтекло.',
    unknown: 'Неизвестна грешка',
    tryReconnect: 'Опит за повторно свързване...',
    alreadyAnswered: 'На този елемент вече е отговорено.',
    unavailableOffline: {
      title: 'Няма връзка',
      subtitle: 'В момента няма връзка',
      explanation:
        'За да използвате приложението, е необходима връзка с интернет. В момента няма връзка. Проверете дали можете да се свържете отново с интернет или опитайте пак по-късно. ',
    },
  },
  common: {
    CheckAnswer: 'Проверка на отговор',
    CheckAnswers: 'Проверка на отговорите',
    Answer: 'Записване на моя отговор',
    Hello: 'Здравейте',
    back: 'Назад',
    Emailaddress: 'Електронна поща',
    Name: 'Име',
    Checking: 'Проверка...',
    Close: 'Затваряне',
    browsePrevious: 'Предишен',
    browseNext: 'Следващ',
    Accept: 'Приемане',
    'Log out': 'Изход',
    Help: 'Помощ',
    Correct: 'Правилно',
    Incorrect: 'Неправилно',
    CorrectNotChosen: 'Би било правилно',
    Round: 'Рунд',
    Version: 'Версия',
    selected: 'Избрани',
    'No Rating': 'Все още няма оценка',
    'No Rank': 'Няма класиране',
    NoUserSelection: 'Няма намерени потребители',
    monthNames: 'Ян Фев Мар Апр Май Юни Юли Авг Сеп Окт Нов Дек',
    or: 'или',
    on: 'включване',
    off: 'изключване',
  },
  profile: {
    title: 'Профил',
    subtitle: 'Настройте профила си тук.',
    pictureEdit: 'Прегледайте или редактирайте снимката си.',
    'picture-submit': 'Използване на този отрязък',
    pictureConfirmDelete:
      'Сигурни ли сте, че искате да изтриете профилната си снимка?',
    profilePageTitle: 'Управлявайте профила си.',
    noEditDataText: 'Не може да промените тези данни.',
    'file-submit': 'Качване',
    pictureCancel: 'Отмяна',
    'picture-nofileselected': 'Не сте избрали файл.',
    'picture-nofile': 'Няма файл.',
    'picture-invalidsize':
      'Изображението е твърде голямо.Максималната ширина или височина не може да надвишава 2600 пиксела.',
    'picture-notimage': 'Избраният от Вас файл не е изображение.',
    passwordEdit: 'Промяна на паролата',
    'picture-saved': 'Снимката на профила Ви е запазена!',
    passwordTitle: 'Промяна на паролата',
    passwordText:
      'Променете паролата си, като въведете текущата си парола и 2 пъти новата си парола. Новата парола трябва да има поне 6 знака.',
    'password-old': 'Текуща парола',
    'password-new': 'Нова парола',
    'password-repeat': 'Потвърждаване на новата парола',
    'password-submit': 'Запазване на новата парола',
    'password-oldpasswordmismatch': 'Текущата Ви парола е неправилна',
    'password-newpasswordmismatch': 'Потвърдената парола не съвпада.',
    'password-passwordtooshort':
      'Новата парола трябва да съдържа най-малко 6 знака.',
    'password-passwordsaved': 'Паролата Ви е запазена!',
    profilePictureTitle: 'Профилна снимка',
    profilePictureText: 'Вашите колеги могат да виждат тази снимка.',
    generalDataTitle: 'Лична информация',
    generalDataText: 'Не може да промените всички лични данни сами.',
    profileLanguagePreference: 'Езикови предпочитания',
    profileLanguageChoice: 'Избор на език',
  },
  auth: {
    header: {
      login: 'Влизане',
      expired: 'Връзката е изтекла',
      remind: 'Не можете да си спомните паролата?',
      create: 'Активирайте акаунта си',
      reset: 'Актуализирайте паролата си',
      welcome: 'Добре дошли!',
    },
    field: {
      email: 'Имейл адрес',
      password: 'Парола',
      oldPassword: 'Текуща парола',
      newPassword: 'Нова парола',
      newRepeatPassword: 'Повторение на новата парола',
      rememberMe: 'Запомни ме',
    },
    action: {
      itemcompleted: 'Завършихте елемент',
      updateprofile: 'Актуализиране на профила',
      sendLink: 'Изпрати ми връзка за нулиране на паролата',
      backToLogin: 'Искам да вляза',
      sendActivateLink: 'Активиране на акаунта',
      alreadyActivatedLogin: 'Вече активирах акаунта и искам да вляза',
      savePassword: 'Записване на моята нова парола',
      newPassword: 'Вашата нова парола',
      repeatNewPassword: 'Повторете новата парола',
      newPasswordPolicyIntro: 'Сила на паролата',
    },
    selfActivate: {
      header: 'Активирайте профила си',
    },
    logintext: 'Влизане',
    remindText:
      'Няма проблем. Ще Ви изпратим имейл, за да можете да зададете нова парола.',
    resetText: 'Може да настроите новата парола тук.',
    createText: 'Добре дошли! Създайте нова парола и сте готови да влезете.',
    passwords: {
      user: 'За съжаление, не можем да намерим потребител с този имейл адрес.',
      token: 'Този маркер за нулиране на паролата е невалиден.',
      sent: ' Изпратен е имейл с връзка за нулиране.',
      reset: 'Паролата е нулирана!',
      forgot: 'Не знам паролата си',
      passwordExpiredTitle: 'Паролата е изтекла',
      submitPasswordExpiredForm: 'Записване на новата парола',
      passwordExpiredText:
        'Паролата е по-стара от {{days}} дни и е изтекла. Ще трябва да зададете нова парола чрез формуляра по-долу, след което може да продължите да учите в приложението PowerApp',
    },
  },
  faq: {
    header: 'Често задавани въпроси',
    q1: 'Приложението PowerApp е приложение за микрообучение – какво означава това?',
    a1: 'Микрообучение означава, че е по-ефективно да се обучавате в кратки сесии, ако целта Ви е да натрупате повече знания с течение на времето.',
    q2: 'Защо резултатът ми понякога е по-нисък от очаквания?',
    a2: 'Резултатът Ви намалява, защото с течение на времето забравяте това, което сте научили.',
    q3: 'Кога намалява резултатът ми?',
    a3: 'Когато сте отговорили неправилно на въпроси или не сте активни от известно време.',
    q4: 'Защо се повтарят едни и същи елементи?',
    a4: 'Защото повтарянето през определени интервали от време е най-ефективният начин за учене.',
    q5: 'Какво е дуел?',
    a5: 'Тест за знания с 5 въпроса, в който се съревновавате с колега.',
    q6: 'Защо трябва да изчаквам реда си, когато участвам в дуел?',
    a6: 'Трябва да изчакате колегата си да завърши своя ред',
    q7: 'Къде мога да намеря повече информация и отговори на въпросите си?',
    a7: 'Бутонът по-долу ще Ви отведе на нашия уеб сайт.',
    toTheFaq: 'Към Ч.З.В.',
    toTheWebsite: 'Към уеб сайта',
  },
}

export default translations

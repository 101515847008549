import { configure } from 'mobx'
import { createContext, useContext, ReactElement, ComponentType } from 'react'

import { WebService } from '../services/WebService'
import { ChildrenProps } from '../utils/types'

import { AuthStore } from './authStore'
import { CommonStore } from './commonStore'
import { LearnStore } from './learnStore'

configure({ enforceActions: 'observed' })

export interface WithStores {
  authStore: AuthStore
  commonStore: CommonStore
  learnStore: LearnStore
}

const webservice = WebService.getInstance()
const learnStore = new LearnStore(webservice)
export const stores = {
  authStore: new AuthStore(webservice),
  commonStore: new CommonStore(webservice),
  learnStore,
}

const StoresContext = createContext<WithStores>(stores)

export function StoreProvider(props: ChildrenProps): ReactElement {
  const { children } = props

  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  )
}

export function withStores<P extends WithStores>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof WithStores>> {
  return function WithStoresHoc(
    props: Omit<P, keyof WithStores>
  ): ReactElement {
    const providedStores = useStores()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <WrappedComponent {...(props as any)} {...providedStores} />
  }
}

export function useStores(): WithStores {
  return useContext(StoresContext)
}

import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Sentry from '@sentry/browser'
import { observer } from 'mobx-react'
import {
  ChangeEvent,
  Context,
  FormEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useMutation, useRelayEnvironment } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { EnvironmentContext } from '../../../App'

import { Page } from '../../../containers/Page'
import { AuthenticationLoginPageLoginMutation } from '../../../generated/AuthenticationLoginPageLoginMutation.graphql'
import { useStores } from '../../../stores'
import { Severity } from '../../../stores/authStore'
import { AuthAlert } from '../../auth/AuthAlert'
import { AuthFooter } from '../../auth/AuthFooter'
import { AuthHeader } from '../../auth/AuthHeader'
import { PrimaryButton } from '../../common/PrimaryButton'

import styles from './AuthenticationLoginPage.scss'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'

type SsoIdpConfiguration = (typeof EnvironmentContext extends Context<infer T>
  ? T
  : never)['singleSignOn']['idps'][0]

function getCorrectTranslation(
  translationOptions: SsoIdpConfiguration['displayName'],
  languages: string[]
): string {
  for (const language of languages) {
    const translation = translationOptions.find(
      (name) => name.language === language
    )
    if (translation) {
      return translation.value
    }
  }

  return translationOptions[0].value
}

export const AuthenticationLoginPage = observer(function AuthenticationLogin() {
  const { authStore, commonStore } = useStores()
  const [submittable, setSubmittable] = useState(false)
  const navigate = useNavigate()

  const environment = useContext(EnvironmentContext)

  const getEditableTranslation = (key: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (
      (
        environment.editableTranslations.find(
          (translation) => translation.language === commonStore.language
        ) ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        environment.editableTranslations.find(
          (translation) => translation.language === 'en-US'
        )!
      ).translations?.find((translation) => translation.key === key)?.value ||
      ''
    )
  }

  useEffect(() => {
    setSubmittable(
      authStore.authPassword.length > 0 && authStore.authUsername.length > 0
    )
  }, [authStore.authPassword, authStore.authUsername])

  const onPasswordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      authStore.setAuthPassword(event.target.value)
    },
    [authStore]
  )

  const onRememberMeChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      authStore.setAuthRememberMe(event.target.checked)
    },
    [authStore]
  )

  const onUsernameChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      authStore.setAuthUsername(event.target.value)
    },
    [authStore]
  )

  const relayEnvironment = useRelayEnvironment()
  const [login] = useMutation<AuthenticationLoginPageLoginMutation>(graphql`
    mutation AuthenticationLoginPageLoginMutation(
      $username: String!
      $password: String!
      $rememberMe: Boolean
      $deviceName: String!
      $requestAccessToken: Boolean!
    ) {
      login(username: $username, password: $password, rememberMe: $rememberMe) {
        message
        result {
          __typename
          ... on LoggedIn {
            accessToken(deviceName: $deviceName)
              @include(if: $requestAccessToken)
            user {
              __typename
              id
              email
              language
              ...NavigationBar_user
            }
          }
          ... on PasswordExpired {
            daysValid
            email
            token
          }
        }
      }
    }
  `)
  const onSubmitClicked = useCallback(
    (event: FormEvent): void => {
      event.preventDefault()

      setSubmittable(false)
      authStore.setAuthLoading(true)

      login({
        variables: {
          username: authStore.authUsername,
          password: authStore.authPassword,
          rememberMe: authStore.authRememberMe,
          deviceName: navigator.userAgent,
          requestAccessToken: !!process.env.WEBPACK_DEV_SERVER,
        },
      })
        .then((response) => {
          if (response.login.result) {
            if (response.login.result.__typename === 'LoggedIn') {
              authStore.attemptLoginSuccess(response.login.result.accessToken)

              commonStore.setLanguage(response.login.result.user.language)

              const id = response.login.result.user.id
              Sentry.setUser({
                email: response.login.result.user.email,
                id,
              })

              relayEnvironment.commitUpdate((store) => {
                const record = store.get(id)
                if (record) {
                  store.getRoot().setLinkedRecord(record, 'me')
                }
              })
            } else {
              // __typename === 'PasswordExpired'
              authStore.setAuthPassword('')
              navigate(
                `/password/expired/${response.login.result.email}/${response.login.result.daysValid}/${response.login.result.token}`
              )
            }
          } else if (response.login.message) {
            authStore.attemptLoginError(response.login.message)
          }
        })
        .finally(() => authStore.setAuthLoading(false))
    },
    [authStore, commonStore, login, navigate, relayEnvironment]
  )

  const { t } = useTranslation()

  // Allow override of IDP only mode for direct login, so admins can still
  // log in.
  const query = new URLSearchParams(location.search)
  const idpOnlyOverride = query.get('directLogin') === 'true'
  const errorMessage = query.get('message')
  const emailAddressPlaceholder =
    t('auth.field.email') +
    (environment.loginWithCompanyUserId
      ? '/' + getEditableTranslation('field_companyuserid')
      : '')
  const emailAddressFieldIcon = environment.loginWithCompanyUserId
    ? faUser
    : faEnvelope

  // Set an auth error message if we received one.
  useEffect(() => {
    if (errorMessage) {
      authStore.setAuthMessage(errorMessage, Severity.ERROR)
    }
  }, [authStore, errorMessage])

  // If there's one SSO IdP, and we're in IdP-only mode, skip the form
  // altogether.
  const ssoSettings = environment.singleSignOn
  if (
    ssoSettings.idpOnly &&
    !idpOnlyOverride &&
    !errorMessage &&
    ssoSettings.idps.length === 1
  ) {
    location.href = ssoSettings.idps[0].loginUrl

    return <></>
  }

  return (
    <>
      <AuthHeader />

      <Page hideWatermark narrow>
        <div className={styles.formHeader}>
          <div className={styles.head}>{t('auth.header.welcome')}</div>
          <div className={styles.sub}>{t('auth.header.login')}</div>
        </div>

        <form
          method='post'
          action='#'
          className={styles.formContainer}
          onSubmit={onSubmitClicked}
        >
          <AuthAlert />

          {ssoSettings.idps.length > 0 && (
            <div className={styles.ssoButtons}>
              {ssoSettings.idps.map((idp) => (
                <Fragment key={idp.loginUrl}>
                  <PrimaryButton
                    component='a'
                    className={styles.ssoButton}
                    href={idp.loginUrl}
                  >
                    <FontAwesomeIcon icon={faLock} />{' '}
                    {getCorrectTranslation(idp.displayName, [
                      commonStore.language,
                      environment.language,
                    ])}
                  </PrimaryButton>

                  {idp.description && (
                    <p className={styles.idpDescription}>
                      {getCorrectTranslation(idp.description, [
                        commonStore.language,
                        environment.language,
                      ])}
                    </p>
                  )}
                </Fragment>
              ))}
            </div>
          )}

          {(!ssoSettings.idpOnly || idpOnlyOverride) &&
            ssoSettings.idps.length > 0 && <hr />}

          {(!ssoSettings.idpOnly ||
            ssoSettings.idps.length === 0 ||
            idpOnlyOverride) && (
            <>
              <div className={styles.formGroup + ' ' + styles.prepend}>
                <div className={styles.prependIcon}>
                  <FontAwesomeIcon icon={emailAddressFieldIcon} />
                </div>
                <input
                  autoComplete='username'
                  className={styles.formControl + ' ' + styles.prependPadding}
                  placeholder={emailAddressPlaceholder}
                  name='emailaddress'
                  onChange={onUsernameChanged}
                  type={environment.loginWithCompanyUserId ? 'text' : 'email'}
                  value={authStore.authUsername}
                />
              </div>

              <div className={styles.formGroup + ' ' + styles.prepend}>
                <div className={styles.prependIcon}>
                  <FontAwesomeIcon icon={faLock} />
                </div>
                <input
                  autoComplete='current-password'
                  className={styles.formControl + ' ' + styles.prependPadding}
                  placeholder={t('auth.field.password')}
                  name='password'
                  onChange={onPasswordChanged}
                  type='password'
                  value={authStore.authPassword}
                />
              </div>

              <div
                className={`${styles.customControl} ${styles.customCheckbox} ${styles.checkboxLg}`}
              >
                <input
                  className={styles.customControlInput}
                  checked={authStore.authRememberMe}
                  id='rememberMeCheckbox'
                  name='rememberMe'
                  onChange={onRememberMeChanged}
                  type='checkbox'
                />
                <label
                  htmlFor='rememberMeCheckbox'
                  className={styles.customControlLabel}
                >
                  {t('auth.field.rememberMe')}
                </label>
              </div>

              <div className={styles.formFooter}>
                <PrimaryButton
                  disabled={!submittable}
                  className={styles.primaryButton}
                  type='submit'
                >
                  {t('auth.logintext')}
                </PrimaryButton>

                <div>
                  <Link className={styles.formFooterLink} to='/password/remind'>
                    {t('auth.passwords.forgot')}
                  </Link>
                </div>
              </div>
            </>
          )}
        </form>
      </Page>

      <AuthFooter />
    </>
  )
})

const neutral = {
  app: {
    name: '{{companyName}} PowerApp',
  },
  profile: {
    'language_bg-BG': 'български език',
    'language_cs-CZ': 'Čeština',
    'language_de-DE': 'Deutsch',
    'language_el-GR': 'Ελληνικά',
    'language_en-US': 'English',
    'language_fa-IR': 'فارسی',
    'language_fr-FR': 'Français',
    'language_hu-HU': 'Magyar',
    'language_it-IT': 'Italiano',
    'language_nl-NL': 'Nederlands',
    'language_pl-PL': 'Polski',
    'language_pt-BR': 'Português brasileiro',
    'language_ro-RO': 'Română',
  },
  faq: {
    q: 'Q',
    a: 'A',
  },
}

export default neutral

export type Neutral = typeof neutral

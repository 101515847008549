import { observer } from 'mobx-react'
import { ReactElement } from 'react'

import onfireLogo from '../../../../../images/logo/white-color-onfire.png'
import powerappLogo from '../../../../../images/logo/white.svg'
import { useEnvironment } from '../../App'
import { Header } from '../../containers/Header'

import styles from './AuthHeader.scss'

export const AuthHeader = observer(function AuthHeader(): ReactElement {
  const environment = useEnvironment()

  // TODO: Do we need a combined logo for 'both'?
  const logo = environment.product === 'ONFIRE' ? onfireLogo : powerappLogo
  // TODO: Do we need to determine the lightness of the background here like in
  // the navigation bar, and if so, how? It's a bit more complicated here since
  // it's not just one color, but a gradient on top of an image.
  const uploadedLogo =
    environment.appLogos.whiteLogoUrl ?? environment.appLogos.fullColorLogoUrl

  return (
    <Header flat>
      <div className={styles.logoLoginContainer}>
        {(uploadedLogo || !environment.organizationLogoUrl) && (
          <img
            alt={environment.pageTitle}
            className={styles.logo}
            src={uploadedLogo || logo}
          />
        )}

        {!uploadedLogo && environment.organizationLogoUrl && (
          <img
            className={styles.logo}
            alt={environment.organization}
            src={environment.organizationLogoUrl}
          />
        )}
      </div>
    </Header>
  )
})

import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy'
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'relay-hooks'
import { graphql } from 'relay-runtime'
import { DuelVersusHeader_left$key } from '../../generated/DuelVersusHeader_left.graphql'
import { DuelVersusHeader_right$key } from '../../generated/DuelVersusHeader_right.graphql'
import { classNames } from '../../utils/classNames'
import { Avatar, AvatarContext } from '../common/Avatar'
import { NumberBubble } from '../common/NumberBubble'

import styles from './DuelVersusHeader.scss'
import { useEnvironment } from '../../App'

type DuelVersusHeaderProps =
  | {
      type: 'ACTIVE'
      round: number
      left: DuelVersusHeader_left$key
      right: DuelVersusHeader_right$key
    }
  | {
      type: 'PLAY' | 'WAITING' | 'YOURTURN'
      left: DuelVersusHeader_left$key
      right: DuelVersusHeader_right$key
      round: number
    }
  | {
      type: 'INVITED'
      left: DuelVersusHeader_left$key
      right: DuelVersusHeader_right$key
      round: number
    }
  | {
      type: 'WON' | 'LOST' | 'DRAW'
      left: DuelVersusHeader_left$key
      right: DuelVersusHeader_right$key
      round: number
    }

export function DuelVersusHeader(
  props: PropsWithChildren<DuelVersusHeaderProps & { className?: string }>
): ReactElement {
  const { t, i18n } = useTranslation()
  const environmentSettings = useEnvironment()

  const left = useFragment(
    graphql`
      fragment DuelVersusHeader_left on Player {
        rank
        user {
          id
          nameForDuelling
          isMe
          profileImageForDuelling
          hasAnonymousDuellingEnabled
        }
        ... on DuelPlayer {
          timedOut
        }
      }
    `,
    props.left
  )
  const right = useFragment(
    graphql`
      fragment DuelVersusHeader_right on Player {
        rank
        user {
          id
          nameForDuelling
          isMe
          profileImageForDuelling
          hasAnonymousDuellingEnabled
        }
        ... on DuelPlayer {
          timedOut
        }
      }
    `,
    props.right
  )

  let leftHasAttachment = false
  let rightHasAttachment = false
  switch (props.type) {
    case 'ACTIVE':
    case 'PLAY':
    case 'INVITED':
      leftHasAttachment = !!left.rank
      rightHasAttachment = !!right.rank
      break
    case 'WON':
      rightHasAttachment = true
      break
    case 'LOST':
      leftHasAttachment = true
      break
    case 'DRAW':
      leftHasAttachment = rightHasAttachment = true
  }

  const getPlayerName = (user: {
    isMe: boolean
    hasAnonymousDuellingEnabled: boolean
    nameForDuelling: string
  }) => {
    return user.isMe &&
      (environmentSettings.anonymousDuelling === 'ALL' ||
        (environmentSettings.anonymousDuelling === 'USER' &&
          user.hasAnonymousDuellingEnabled))
      ? t('streamItem.duelItem.yourName', { name: user.nameForDuelling })
      : user.nameForDuelling
  }

  const leftPlayerName = getPlayerName(left.user)
  const rightPlayerName = getPlayerName(right.user)

  return (
    <div
      className={classNames(
        styles.versusHeader,
        styles[props.type.toLowerCase()],
        props.className
      )}
    >
      {props.type !== 'ACTIVE' && (
        <div className={styles.status}>
          <h1 id='popup-label'>
            {props.type === 'INVITED' && t('streamItem.duelItem.duelRequest')}
            {props.type === 'PLAY' && t('streamItem.duelItem.yourTurn')}
            {props.type === 'WON' && t('streamItem.duelItem.youWon')}
            {props.type === 'LOST' && t('streamItem.duelItem.youLost')}
            {props.type === 'DRAW' &&
              (left.timedOut && right.timedOut
                ? t('streamItem.duelItem.tooLate')
                : t('streamItem.duelItem.draw'))}
          </h1>
          <p>
            {props.type === 'INVITED' &&
              t('streamItem.duelItem.duelRequestText', {
                name: rightPlayerName,
              })}
            {props.type === 'PLAY' &&
              t('streamItem.duelItem.subheading.yourTurn')}
            {props.type === 'WON' &&
              (left.timedOut
                ? t('streamItem.duelItem.subheading.timeOutOpponent')
                : t('streamItem.duelItem.subheading.wellDone'))}
            {props.type === 'LOST' &&
              (right.timedOut
                ? t('streamItem.duelItem.subheading.timeOutPlayer')
                : t('streamItem.duelItem.subheading.betterNextTime'))}
            {
              props.type === 'DRAW' &&
                (left.timedOut && right.timedOut
                  ? t('streamItem.duelItem.subheading.timeOutBoth')
                  : t(
                      'streamItem.duelItem.subheading.betterNextTime'
                    )) /* TODO: Is this right? */
            }
          </p>
        </div>
      )}

      <div className={styles.players}>
        <div className={styles.player}>
          <div
            className={classNames(styles.avatar, {
              [styles.withAttachment]: leftHasAttachment,
            })}
          >
            <Avatar
              filename={left.user.profileImageForDuelling}
              name={left.user.nameForDuelling}
              userId={left.user.id}
              context={AvatarContext.duelling}
              hasAnonymousDuellingEnabled={
                left.user.hasAnonymousDuellingEnabled
              }
            />

            {leftHasAttachment &&
              ((props.type === 'ACTIVE' ||
                props.type === 'PLAY' ||
                props.type === 'INVITED') &&
              left.rank ? (
                <NumberBubble
                  className={styles.attachment}
                  text={left.rank}
                  sup='#'
                  scaling
                />
              ) : (
                <div className={styles.attachment}>
                  {props.type === 'LOST' && <FontAwesomeIcon icon={faTrophy} />}
                  {props.type === 'DRAW' &&
                    (left.timedOut ? (
                      <FontAwesomeIcon icon={faStopwatch} />
                    ) : (
                      <div className={styles.halfIcon}>
                        <FontAwesomeIcon icon={faTrophy} />
                      </div>
                    ))}
                </div>
              ))}
          </div>

          <div
            className={classNames(styles.name, {
              [styles.withAttachment]: leftHasAttachment,
            })}
          >
            {leftPlayerName}
          </div>
        </div>

        <div className={styles.middle}>
          {props.type === 'ACTIVE' ? (
            <>
              <span>
                {t('streamItem.duelItem.roundNumber', {
                  number: Intl.NumberFormat(i18n.language).format(props.round),
                })}
              </span>
              <span className={styles.vs}>
                {t('streamItem.duelItem.versusLabel')}
              </span>
              <span>{props.children}</span>
            </>
          ) : (
            <span className={styles.vs}>
              {t('streamItem.duelItem.versusLabel')}
            </span>
          )}
        </div>

        <div className={styles.player}>
          <div
            className={classNames(styles.avatar, {
              [styles.withAttachment]: rightHasAttachment,
            })}
          >
            <Avatar
              filename={right.user.profileImageForDuelling}
              name={right.user.nameForDuelling}
              userId={right.user.id}
              context={AvatarContext.duelling}
              hasAnonymousDuellingEnabled={
                right.user.hasAnonymousDuellingEnabled
              }
            />

            {rightHasAttachment &&
              ((props.type === 'ACTIVE' ||
                props.type === 'PLAY' ||
                props.type === 'INVITED') &&
              right.rank ? (
                <NumberBubble
                  className={styles.attachment}
                  text={right.rank}
                  sup='#'
                  scaling
                />
              ) : (
                <div className={styles.attachment}>
                  {props.type === 'WON' && <FontAwesomeIcon icon={faTrophy} />}
                  {props.type === 'DRAW' &&
                    (right.timedOut ? (
                      <FontAwesomeIcon icon={faStopwatch} />
                    ) : (
                      <div className={styles.halfIcon}>
                        <FontAwesomeIcon icon={faTrophy} />
                      </div>
                    ))}
                </div>
              ))}
          </div>

          <div
            className={classNames(styles.name, {
              [styles.withAttachment]: rightHasAttachment,
            })}
          >
            {rightPlayerName}
          </div>
        </div>
      </div>
    </div>
  )
}

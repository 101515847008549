import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ComponentProps,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../stores'
import { Popup as PopupType } from '../../stores/commonStore'
import { never } from '../../utils/assert'
import { classNames } from '../../utils/classNames'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

import { AboutDuelsPopup } from '../duels/AboutDuelsPopup'
import { DuelFinalizingPopup } from '../duels/DuelFinalizingPopup'
import { DuellingNotAvailablePopup } from '../duels/DuellingNotAvailablePopup'
import { DuelRequestPopup } from '../duels/DuelRequestPopup'
import { NewDuelPopup } from '../duels/NewDuelPopup'
import { PlayDuelRound } from '../duels/PlayDuelRound'
import { ConfirmStopPracticingPopup } from '../learn/ConfirmStopPracticingPopup'
import { AvatarCropPopup } from '../profile/AvatarCropPopup'
import { AvatarPopup } from '../profile/AvatarPopup'
import { ComeBackLaterPopup } from './ComeBackLaterPopup'
import { ConfirmYesNoPopup } from './ConfirmYesNoPopup'
import { FinishedOnboardingPopup } from './FinishedOnboardingPopup'

import { InternetRequiredPopup } from './InternetRequiredPopup'
import { MaintenanceModePopup } from './MaintenanceModePopup'
import styles from './Popup.scss'
import { PrivacyPolicyPopup } from './PrivacyPolicyPopup'
import { TertiaryButton } from './TertiaryButton'
import { VersionConflictPopup } from './VersionConflictPopup'
import { RepetitionStateInfoPopup } from './RepetitionStateInfoPopup'

const POPUPS_WITHOUT_ATTACHMENT: Array<PopupType['type']> = [
  'confirm-stop-practicing',
  'duel-finalizing',
  'maintenance',
  'new-version',
  'come-back-later',
  'finished-onboarding',
  'privacy-policy',
]
const POPUPS_WITHOUT_CLOSE_BUTTON: Array<PopupType['type']> = [
  'about-duels',
  'duel-request',
]

export function Popup(props: PopupType): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const attachment = useRef<ReactElement | null>(null)
  const dialogElement = useRef<HTMLDivElement>(null)

  const [shown, setShown] = useState(false)

  useEffect(() => {
    document.body.classList.add(styles.modalOpen)
    setShown(true)

    return (): void => {
      if (commonStore.popups.length === 0) {
        document.body.classList.remove(styles.modalOpen)
      }
    }
  }, [commonStore.popups.length])

  const closePopup = useCallback((): void => {
    const closeFuction =
      'onClose' in props && props.onClose
        ? props.onClose
        : commonStore.closePopup
    if (commonStore.popups.length > 1) {
      // Then fading out would look jittery.
      closeFuction()

      return
    }

    setShown(false)

    setTimeout(closeFuction, 350)
  }, [commonStore.closePopup, commonStore.popups.length, props])
  const closePopupEnterKey = useEnterKeyHandler(closePopup)

  const closePopupBackdropClick = useCallback(
    (event: MouseEvent): void => {
      if (
        !(event.target instanceof Node) ||
        !dialogElement.current ||
        dialogElement.current.contains(event.target)
      ) {
        return
      }

      closePopup()
    },
    [closePopup]
  )

  const setAttachment = useCallback(
    (
      children: ReactElement,
      attachmentProps: Partial<
        Exclude<ComponentProps<typeof TertiaryButton>, 'component'>
      > = {}
    ): void => {
      const buttonProps: ComponentProps<typeof TertiaryButton> = {
        component: 'button',
        'aria-label': t('common.Close'),
        icon: true,
        onClick: closePopup,
        onKeyPress: closePopupEnterKey,
        tabIndex: 0,
        ...attachmentProps,
        className: classNames(attachmentProps.className, styles.attachment),
      }

      attachment.current = (
        <TertiaryButton {...buttonProps}>{children}</TertiaryButton>
      )
    },
    [closePopup, closePopupEnterKey, t]
  )

  let innerElement: ReactElement
  switch (props.type) {
    case 'about-duels':
      innerElement = <AboutDuelsPopup setAttachment={setAttachment} />
      break
    case 'avatar':
      innerElement = <AvatarPopup />
      break
    case 'confirm-stop-practicing':
      innerElement = <ConfirmStopPracticingPopup {...props} />
      break
    case 'confirm-yes-no':
      innerElement = <ConfirmYesNoPopup {...props} />
      break
    case 'crop-avatar':
      innerElement = (
        <AvatarCropPopup setAttachment={setAttachment} {...props} />
      )
      break
    case 'duelling-not-available':
      innerElement = <DuellingNotAvailablePopup />
      break
    case 'duel-request':
      innerElement = (
        <DuelRequestPopup setAttachment={setAttachment} {...props} />
      )
      break
    case 'duel-finalizing':
      innerElement = <DuelFinalizingPopup {...props} />
      break
    case 'internet-required':
      innerElement = <InternetRequiredPopup />
      break
    case 'maintenance':
      innerElement = <MaintenanceModePopup />
      break
    case 'new-duel':
      innerElement = <NewDuelPopup {...props} />
      break
    case 'new-version':
      innerElement = <VersionConflictPopup />
      break
    case 'play-duel-round':
      innerElement = <PlayDuelRound {...props} />
      break
    case 'come-back-later':
      innerElement = <ComeBackLaterPopup {...props} />
      break
    case 'finished-onboarding':
      innerElement = <FinishedOnboardingPopup {...props} />
      break
    case 'privacy-policy':
      innerElement = <PrivacyPolicyPopup />
      break
    case 'repetition-state-info':
      innerElement = <RepetitionStateInfoPopup />
      break
    default:
      never(props, `Invalid popup type: ${JSON.stringify(props)}`)
  }

  const popupHasAttachment = !POPUPS_WITHOUT_ATTACHMENT.includes(props.type)
  const popupHasCloseButton = !POPUPS_WITHOUT_CLOSE_BUTTON.includes(props.type)
  if (!attachment.current && popupHasAttachment && popupHasCloseButton) {
    setAttachment(<FontAwesomeIcon icon={faTimes} />)
  }

  return (
    <>
      <div
        aria-labelledby='popup-label'
        aria-modal={true}
        className={classNames(styles.modal, styles.fade, {
          [styles.show]: shown,
        })}
        onClick={popupHasCloseButton ? closePopupBackdropClick : undefined}
        role='dialog'
        tabIndex={-1}
      >
        <div className={styles.modalDialog} ref={dialogElement} role='document'>
          <div className={styles.modalContentWrapper}>
            <div
              className={classNames(styles.modalContent, {
                [styles.withAttachment]: popupHasAttachment,
              })}
            >
              {innerElement}
            </div>

            {attachment.current}
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.modalBackdrop, styles.fade, {
          [styles.show]: shown,
        })}
      />
    </>
  )
}

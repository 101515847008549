/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NewDuelPopupQuickDuelMutationVariables = {};
export type NewDuelPopupQuickDuelMutationResponse = {
    readonly startQuickDuel: {
        readonly __typename: string;
    };
};
export type NewDuelPopupQuickDuelMutation = {
    readonly response: NewDuelPopupQuickDuelMutationResponse;
    readonly variables: NewDuelPopupQuickDuelMutationVariables;
};



/*
mutation NewDuelPopupQuickDuelMutation {
  startQuickDuel {
    __typename
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "QuickDuelResponse",
            "kind": "LinkedField",
            "name": "startQuickDuel",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "NewDuelPopupQuickDuelMutation",
            "selections": (v0 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "NewDuelPopupQuickDuelMutation",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "cf4637bcb1c71ceb770be94b8b01d6bb",
            "id": null,
            "metadata": {},
            "name": "NewDuelPopupQuickDuelMutation",
            "operationKind": "mutation",
            "text": "mutation NewDuelPopupQuickDuelMutation {\n  startQuickDuel {\n    __typename\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1f6948e9b32f9710169cc55cf8603fd2';
export default node;

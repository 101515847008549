/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlayDuelRound_duel = {
    readonly ownRoundNumber: number;
    readonly players: ReadonlyArray<{
        readonly user: {
            readonly isMe: boolean;
        };
        readonly rounds: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_left" | "DuelRoundsOverview_right">;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"DuelVersusHeader_left" | "DuelVersusHeader_right">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"DuelRoundsOverview_duel">;
    readonly " $refType": "PlayDuelRound_duel";
};
export type PlayDuelRound_duel$data = PlayDuelRound_duel;
export type PlayDuelRound_duel$key = {
    readonly " $data"?: PlayDuelRound_duel$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlayDuelRound_duel">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlayDuelRound_duel",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownRoundNumber",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "DuelPlayer",
            "kind": "LinkedField",
            "name": "players",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isMe",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DuelPlayerRound",
                    "kind": "LinkedField",
                    "name": "rounds",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_left"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DuelRoundsOverview_right"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_left"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DuelVersusHeader_right"
                }
            ],
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DuelRoundsOverview_duel"
        }
    ],
    "type": "Duel",
    "abstractKey": null
} as any;
(node as any).hash = 'd836883a3d1e9618c9973b7896405bb6';
export default node;

import { observer } from 'mobx-react'
import { PropsWithChildren, ReactElement } from 'react'

import { classNames } from '../utils/classNames'

import styles from './Page.scss'
import { useEnvironment } from '../App'

type PageProps = { hideWatermark?: boolean } & (
  | {
      wide?: boolean
    }
  | {
      narrow?: boolean
    }
)

export const Page = observer(function Page(
  props: PropsWithChildren<PageProps>
): ReactElement {
  const environment = useEnvironment()

  const wide = 'wide' in props && props.wide
  const narrow = !wide && 'narrow' in props && props.narrow

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          className={classNames({
            [styles.pageWide]: wide,
            [styles.pageNarrow]: narrow,
            [styles.page]: !wide && !narrow,
          })}
        >
          {props.children}
        </div>
      </div>

      {!props.hideWatermark && environment.organizationWatermarkLogoUrl && (
        <div className={styles.watermark}>
          <img
            alt={environment.organization}
            aria-hidden={true}
            src={environment.organizationWatermarkLogoUrl}
          />
        </div>
      )}
    </div>
  )
})

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { AuthStore } from '../stores/authStore'
import { CommonStore } from '../stores/commonStore'
import { handleThrottling } from '../utils/handleThrottling'
import Singleton from '../utils/Singleton'
import { url } from '../utils/url'

export class TokenChangedEvenet {
  public readonly token: string

  constructor(newToken: string) {
    this.token = newToken
  }
}

export class WebService extends Singleton {
  authStore?: AuthStore
  basePath: string
  commonStore?: CommonStore
  csrfTokenRetrieved: boolean | Promise<void> = false
  host: string
  maxRetries = 2
  timeOutDelay?: number
  versionNumber?: string

  constructor() {
    super()

    this.basePath = '/webservice/'
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.host = process.env.APP_URL!
    this.timeOutDelay = 5000
    if (process.env.SENTRY_RELEASE) {
      this.versionNumber = `${process.env.VERSION}-${process.env.SENTRY_RELEASE}`
    }
  }

  /* --- Request handling --- */

  public async sendRequest<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    // If not using API authentication, start off getting the CSRF token.
    if (!process.env.WEBPACK_DEV_SERVER && this.csrfTokenRetrieved !== true) {
      if (typeof this.csrfTokenRetrieved === 'boolean') {
        this.csrfTokenRetrieved = axios.get(url('/sanctum/csrf-cookie'), {
          withCredentials: true,
        })
        this.csrfTokenRetrieved.catch(
          (error: AxiosError) =>
            error.response?.status == 503 && this.handleMaintenanceMode()
        )
      }

      await this.csrfTokenRetrieved

      this.csrfTokenRetrieved = true
    }

    return new Promise<AxiosResponse<T>>((resolve, reject): void => {
      axios(config)
        .then((response: AxiosResponse<T>) => {
          const versionNumber: string | undefined =
            response.headers['x-app-version']
          if (versionNumber) {
            if (!this.versionNumber) {
              this.versionNumber = String(versionNumber)
            }

            // We need a non-strict comparison here because the version returned
            // by the server may be either a string or a number (e.g. if the
            // version is 2.36, which JSON determines to be numeric).
            if (this.versionNumber != versionNumber) {
              if (!this.commonStore?.hasPopup('new-version')) {
                this.commonStore?.openPopup({ type: 'new-version' }, true)
              }

              // After the app is initialized (and pop-ups can be shown), stop processing
              // request response
              if (this.commonStore && this.commonStore.appInitialized) {
                return
              }
            }
          }

          resolve(response)
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                // Check if we need a new CSRF token.
                if (
                  typeof error.response.data === 'object' &&
                  error.response.data !== null && // typeof null is equal to object,
                  'errorCode' in error.response.data && // and 'key' in null is a TypeError
                  error.response.data.errorCode === 'ValidationTokenExpired'
                ) {
                  if (this.csrfTokenRetrieved === true) {
                    this.csrfTokenRetrieved = false
                  }

                  resolve(this.sendRequest(config))
                }

                break

              case 401:
                this.authStore?.setToken(undefined)
                // TODO: Clear user in GraphQL?
                // this.authStore?.setUser(undefined)

                reject(error)

                return

              case 429:
                // Means we exceeded the limit of request throttling, retry when
                // we get credits again.
                resolve(
                  handleThrottling(error.response).then(() =>
                    this.sendRequest(config)
                  )
                )

                return

              case 503:
                // This means the server is down for maintenance. Refresh so the
                // maintenance page is shown.
                this.handleMaintenanceMode()

                return
            }
          }

          // We don't know how to handle this error
          reject(error)
        })
    })
  }

  public handleMaintenanceMode(): void {
    if (!this.commonStore?.hasPopup('maintenance')) {
      this.commonStore?.openPopup(
        {
          type: 'maintenance',
        },
        true
      )
    }
  }
}

import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { fetchQuery } from 'relay-runtime'
import { environment } from '../../environment'
import {
  PasswordInputValidationQuery,
  PasswordInputValidationQueryResponse,
} from '../../generated/PasswordInputValidationQuery.graphql'

import { classNames } from '../../utils/classNames'
import { useDebounce } from '../../utils/hooks/useDebounce'

import styles from './PasswordInput.scss'

import { ProgressBar } from './ProgressBar'

interface PasswordInputProps {
  email?: string
  token?: string
  withOldPassword?: boolean
  onOldPasswordChanged?(value: string): void
  onPasswordChanged(value: string): void
  onPasswordConfirmationChanged(value: string): void
  onPasswordsValidated(canSubmit: boolean): void
}

type StrengthPasswordValidation =
  PasswordInputValidationQueryResponse['validatePassword'][0] & {
    __typename: 'StrengthPasswordValidation'
  }

// @observer
// class PasswordInputComponent extends Component<
//   PasswordInputProps,
//   PasswordInputState
// > {
//   public componentDidMount(): void {
//     this.setState({
//       languageUpdaterDispose: reaction(
//         () => this.props.commonStore.language,
//         this.onLanguageChanged
//       )
//     })
//   }
//
//   public componentWillUnmount(): void {
//     if (this.state.languageUpdaterDispose) {
//       this.state.languageUpdaterDispose()
//     }
//   }
//
//   private onLanguageChanged = (): void => {
//     if (this.props.password || this.props.passwordConfirmation) {
//       this.verifyPassword(this.props.password, this.props.passwordConfirmation)
//     }
//   }
// }

export function PasswordInput(props: PasswordInputProps): ReactElement {
  const [feedback, setFeedback] = useState<[boolean | null, string][]>([])
  const [validPassword, setValidPassword] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [validNotReused, setValidNotReused] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [oldPassword, setOldPassword] = useState('')

  const { t, i18n } = useTranslation()
  const {
    email,
    onPasswordChanged,
    onPasswordConfirmationChanged,
    onPasswordsValidated,
    onOldPasswordChanged,
    token,
    withOldPassword,
  } = props

  const oldPasswordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      !!onOldPasswordChanged && onOldPasswordChanged(event.target.value)
      setOldPassword(event.target.value)

      onPasswordsValidated(
        event.target.value.length > 0 &&
          validPassword &&
          passwordsMatch &&
          validNotReused
      )
    },
    [
      onOldPasswordChanged,
      onPasswordsValidated,
      validPassword,
      passwordsMatch,
      validNotReused,
    ]
  )

  const query = graphql`
    query PasswordInputValidationQuery(
      $password: String!
      $password2: String
      $email: String
      $token: String
    ) {
      validatePassword(
        email: $email
        token: $token
        password: $password
        passwordConfirmation: $password2
      ) {
        message
        valid
        __typename
        ... on StrengthPasswordValidation {
          strength
          strengthDescription
        }
        ... on ConfirmationPasswordValidation {
          __typename
        }
        ... on ReusePasswordValidation {
          __typename
        }
      }
    }
  `

  const verifyPassword = useCallback(
    (newPassword: string, newPasswordConfirmation?: string): void => {
      fetchQuery<PasswordInputValidationQuery>(environment, query, {
        password: newPassword,
        password2: newPasswordConfirmation,
        email,
        token,
      }).subscribe({
        next(response) {
          onPasswordsValidated(
            (!withOldPassword || oldPassword.length > 0) &&
              (newPasswordConfirmation?.length || 0) > 0 &&
              response.validatePassword.filter(
                (validation) => !validation.valid
              ).length === 0
          )
          setFeedback(
            response.validatePassword
              .filter((validation) => !!validation.message)
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map((validation) => [validation.valid, validation.message!])
          )
          setValidPassword(
            response.validatePassword.filter((validation) => !validation.valid)
              .length === 0
          )
          setPasswordStrength(
            response.validatePassword
              .filter(
                (validation): validation is StrengthPasswordValidation =>
                  validation.__typename === 'StrengthPasswordValidation'
              )
              .map((validation) => validation.strength)[0] || 0
          )
          setPasswordStrengthMessage(
            response.validatePassword
              .filter(
                (validation): validation is StrengthPasswordValidation =>
                  validation.__typename === 'StrengthPasswordValidation'
              )
              .map((validation) => validation.strengthDescription)[0] || ''
          )
          setPasswordsMatch(
            response.validatePassword
              .filter(
                (validation) =>
                  validation.__typename === 'ConfirmationPasswordValidation'
              )
              .map((validation) => validation.valid)[0] || false
          )
          setValidNotReused(
            response.validatePassword
              .filter(
                (validation) =>
                  validation.__typename === 'ReusePasswordValidation'
              )
              .map((validation) => validation.valid)[0] ?? true
          )
        },
      })
    },
    [
      email,
      oldPassword.length,
      onPasswordsValidated,
      query,
      token,
      withOldPassword,
    ]
  )

  useDebounce(
    350,
    () => {
      if (password || passwordConfirmation) {
        verifyPassword(password, passwordConfirmation)
      }
    },
    [password, passwordConfirmation, i18n.language]
  )

  const passwordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onPasswordChanged(event.target.value)
      setPassword(event.target.value)
    },
    [onPasswordChanged]
  )
  const confirmPasswordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      onPasswordConfirmationChanged(event.target.value)
      setPasswordConfirmation(event.target.value)
    },
    [onPasswordConfirmationChanged]
  )

  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formGroup}>
          {withOldPassword && (
            <div className={styles.formGroup + ' ' + styles.prepend}>
              <div className={styles.prependIcon}>
                <FontAwesomeIcon icon={faLock} />
              </div>
              <input
                autoComplete='current-password'
                className={styles.formControl + ' ' + styles.prependPadding}
                name='oldPassword'
                onChange={oldPasswordChanged}
                placeholder={t('auth.field.oldPassword')}
                type='password'
              />
            </div>
          )}

          {/* <!-- Password input--> */}
          <div className={styles.formGroup + ' ' + styles.prepend}>
            <div className={styles.prependIcon}>
              <FontAwesomeIcon icon={faLock} />
            </div>
            <input
              autoComplete='new-password'
              className={styles.formControl + ' ' + styles.prependPadding}
              name='password'
              onChange={passwordChanged}
              placeholder={t('auth.action.newPassword')}
              type='password'
            />
          </div>

          {/* <!-- Repeat password input--> */}
          <div className={styles.formGroup + ' ' + styles.prepend}>
            <div className={styles.prependIcon}>
              <FontAwesomeIcon icon={faLock} />
            </div>
            <input
              autoComplete='new-password'
              className={styles.formControl + ' ' + styles.prependPadding}
              name='repeatPassword'
              onChange={confirmPasswordChanged}
              placeholder={t('auth.action.repeatNewPassword')}
              type='password'
            />
          </div>

          {password.length > 0 && (
            <div className={styles.bubbleList}>
              <div className={styles.header}>
                {t('auth.action.newPasswordPolicyIntro')}
              </div>
              <div role='list'>
                {feedback.map(
                  (item, index): ReactElement => (
                    <div key={index} className={styles.listItem}>
                      <span
                        className={classNames(styles.bubble, {
                          [styles.bubbleCorrect]: item[0],
                          [styles.bubbleFalse]: item[0] === false,
                          [styles.bubbleWarn]: item[0] === null,
                        })}
                        aria-hidden={true}
                      >
                        <FontAwesomeIcon
                          icon={
                            item[0]
                              ? faCheckCircle
                              : item[0] === false
                              ? faTimesCircle
                              : faExclamationCircle
                          }
                        />
                      </span>
                      <span
                        className={styles.bubbleContent}
                        role='listitem'
                        dangerouslySetInnerHTML={{ __html: item[1] }}
                      />
                    </div>
                  )
                )}
              </div>

              <ProgressBar
                percentage={passwordStrength * 25}
                alternativeTextStyle={styles.header}
                alternativeText={passwordStrengthMessage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export type Booleanable = boolean | number | string | null | undefined
export type ClassNameInput =
  | ClassNameInput[]
  | Record<string, Booleanable>
  | string
  | null
  | undefined

export function classNames(...input: ClassNameInput[]): string {
  return input
    .map(transformClassName)
    .filter((value): value is string => typeof value === 'string')
    .join(' ')
}

function transformClassName(input: ClassNameInput): string | false {
  if (typeof input === 'string') {
    return input
  }

  if (typeof input === 'undefined' || input === null) {
    return false
  }

  if (Array.isArray(input)) {
    return classNames(...input)
  }

  // Otherwise it's an object.
  return Object.entries(input)
    .filter(([, booleanable]) => !!booleanable)
    .map(([key]) => key)
    .join(' ')
}

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LoginType = "LTI" | "SAML" | "WEB";
export type AuthenticationLogoutPageUserQueryVariables = {};
export type AuthenticationLogoutPageUserQueryResponse = {
    readonly me: {
        readonly loginType: LoginType;
    } | null;
};
export type AuthenticationLogoutPageUserQuery = {
    readonly response: AuthenticationLogoutPageUserQueryResponse;
    readonly variables: AuthenticationLogoutPageUserQueryVariables;
};



/*
query AuthenticationLogoutPageUserQuery {
  me {
    loginType
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loginType",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthenticationLogoutPageUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AuthenticationLogoutPageUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "50043bc3d5e70d2d8f77500496acc28f",
            "id": null,
            "metadata": {},
            "name": "AuthenticationLogoutPageUserQuery",
            "operationKind": "query",
            "text": "query AuthenticationLogoutPageUserQuery {\n  me {\n    loginType\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3e473635def316db9a9b46e63aa1bfb5';
export default node;

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Sentry from '@sentry/browser'
import { Component, ErrorInfo, ReactElement } from 'react'
import { ChildrenProps } from '../../utils/types'

import styles from './ErrorBoundary.scss'
import { PrimaryButton } from './PrimaryButton'

interface ErrorBoundaryState {
  didCatch: boolean
}

export class ErrorBoundary extends Component<
  ChildrenProps,
  ErrorBoundaryState
> {
  constructor(props: ChildrenProps) {
    super(props)

    this.state = {
      didCatch: false,
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    if (process.env.NODE_ENV === 'development') {
      console.error('An error was caught by the ErrorBoundary.', error, info)
    } else {
      // Send to Sentry.
      Sentry.captureException(error)
    }

    this.setState({ didCatch: true })
  }

  render(): ReactElement {
    if (!this.state.didCatch) {
      return <>{this.props.children}</>
    }

    // This text is not translated. Probably better to let this component depend
    // on as few external things as possible.
    return (
      <div className={styles.errorContainer}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>

        <h1 className={styles.heading}>Something went wrong.</h1>
        <p>
          It seems we&apos;ve encountered a problem that prevented the app from
          carrying on. Don&apos;t worry, the error has been reported and will be
          investigated.
        </p>

        <PrimaryButton onClick={this.reload}>Restart the app</PrimaryButton>
      </div>
    )
  }

  private reload = (): void => {
    location.href = '/app'
  }
}

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  RepetitionStateIndicator,
  REPETITION_STATE,
} from './RepetitionStateIndicator'

import styles from './RepetitionStateInfoPopup.scss'
import { useEnvironment } from '../../App'

const REPETITION_STATE_INDICATOR_SIZE = 80

export function RepetitionStateInfoPopup(): ReactElement {
  const { t } = useTranslation()
  const environment = useEnvironment()

  return (
    <div className={styles.content}>
      <h1>{t('popup.repetitionStateInfo.heading')}</h1>
      <p>{t('popup.repetitionStateInfo.explanation.general')}</p>
      <ul>
        <li className={styles.infoContainer}>
          <RepetitionStateIndicator
            className={styles.repetitionStateIndicator}
            size={REPETITION_STATE_INDICATOR_SIZE}
            repetitionState={REPETITION_STATE.ALERT}
          />
          <p className={styles.itemExplanation}>
            {t('popup.repetitionStateInfo.explanation.alert')}
          </p>
        </li>
        <li className={styles.infoContainer}>
          <RepetitionStateIndicator
            className={styles.repetitionStateIndicator}
            size={REPETITION_STATE_INDICATOR_SIZE}
            repetitionState={REPETITION_STATE.WARNING}
          />
          <div>
            <p className={styles.itemExplanation}>
              {t('popup.repetitionStateInfo.explanation.warning')}
            </p>
          </div>
        </li>
        {!environment.customUpToDateScoreThreshold && (
          <li className={styles.infoContainer}>
            <RepetitionStateIndicator
              className={styles.repetitionStateIndicator}
              size={REPETITION_STATE_INDICATOR_SIZE}
              repetitionState={REPETITION_STATE.COMPLETE}
            />
            <p className={styles.itemExplanation}>
              {t('popup.repetitionStateInfo.explanation.complete')}
            </p>
          </li>
        )}
        {environment.customUpToDateScoreThreshold && (
          <li className={styles.infoContainer}>
            <RepetitionStateIndicator
              className={styles.repetitionStateIndicator}
              size={REPETITION_STATE_INDICATOR_SIZE}
              repetitionState={REPETITION_STATE.SUFFICIENT}
            />
            <p className={styles.itemExplanation}>
              {t('popup.repetitionStateInfo.explanation.sufficient')}
            </p>
          </li>
        )}
      </ul>
    </div>
  )
}

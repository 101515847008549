const translation = {
  popup: {
    confirm: {
      delete: 'Effacer',
      keep: 'Terminer',
      cancelDuelInviteTitle: 'Effacer?',
      cancelDuelInviteMessage: 'Êtes-vous sûr?',
    },
    repetitionStateInfo: {
      heading: "Indice d'entrainement",
      explanation: {
        general:
          "L'indice d'entrainement vous permet de savoir immédiatement s'il est temps de s'entrainer ou non. Il a trois couleurs différentes. Comme un feu de signalisation.",
        alert:
          "Rouge : Vos connaissances se sont dégradées. Il est temps de s'entrainer.",
        warning:
          "Orange : Des questions vous attendent encore. Dès que l'indice devient vert, vos connaissances sont à jour.",
        complete:
          "Vert : C'est parfait, vous êtes complètement à jour ! Veuillez revenir ultérieurement.",
      },
    },
  },
  header: {
    'welcome-message': 'Bonjour, {{name}} !',
    welcome: 'Bienvenue.',
    welcomeBack: 'Bon retour.',
  },
  learn: {
    instruction:
      'Sélectionnez les rubriques avec lesquelles vous voulez jouer.',
    practiceSelection: 'S’exercer avec cette sélection',
    selectAll: 'Tout sélectionner',
    deselectAll: 'Supprimer la sélection',
    wellDone: 'Bravo !',
    topicCategorySelectionIndicator: 'Thème {{current}}/{{countFormatted}}',
    practiceAllNecessary: {
      message:
        '<p><b>Vous souhaitez mettre à jour rapidement vos connaissances ?</b></p><p>Répondez à cette série de questions pour atteindre le statut vert le plus vite possible.</p>',
      upToDateMessage:
        '<p><b>Vous vous en sortez très bien !</b></p><p>Souhaitez-vous rester informé ? Continuer à vous entrainer !</p>',
      practiceTopics: "S'entrainer avec des séries pré-choisies",
    },
    topics: {
      toDo: 'À faire',
      upToDate: 'Mise à jour',
      practiceCheckbox: 'Entrainement',
      extraPracticeButton: 'Entrainement supplémentaire',
    },
    repetitionState: {
      header: {
        complete: 'Bravo. Vous êtes à jour !',
        warning: 'Continuer à vous entrainer pour rester à jour.',
        alert: "Il est temps de s'entrainer.",
        alertFirstLogin: "Vous pouvez commencer l'entrainement maintenant.",
        showScore: 'Votre score total : {{score}}%',
      },
    },
    streamBatchProgress: {
      message: {
        batchCompleted: 'Vous êtes à jour !',
        upToDate: 'Vous êtes déjà à jour',
        nrToUpToDate: '{{nr}} à faire',
      },
    },
    questionStatusLabel: {
      repeatQuestion: 'Répété',
      newQuestion: 'Nouveau !',
    },
  },
  duels: {
    title: 'Évaluation du duel : {{score}}',
    readyToDuel: 'Êtes-vous prêt(e) à commencer le duel ?',
    roundsOverview: 'Aperçu des tours du duel',
  },
  streamItem: {
    brainSnack: {
      defaultTitle: 'Brainsnack',
      browsePrevious: 'Page précédente',
      doneText: 'Brainsnack terminé',
      browseNext: 'Page suivante',
    },
    brainItem: {
      correct: 'Vrai',
      incorrect: 'Faux',
      completed: 'Vous avez terminé une question',
      doneText: 'Terminé',
      openItem: 'Commencer',
      closeItem: 'Fermer',
      topics: 'Les sujets',
      nextItem: 'Continuer à s’exercer',
      showProgress: 'Afficher vos progrès',
    },
    order: {
      questionTitle: 'Ordre',
      helpText: 'Faites glisser les réponses dans le bon ordre',
      correct: '{{num}} sur {{max}} correct',
    },
    match: {
      questionTitle: 'Question d’appariement',
      showingCorrectAnswer: 'Voir les bonnes réponses.',
      helpText: 'Faites glisser les réponses au bon endroit',
      correct: '{{num}} sur {{max}} correct',
      showCorrectAnswer: 'Montrer la bonne réponse',
      showMyAnswer: 'Montre ma réponse',
    },
    slider: {
      doneText: 'Terminé',
      questionTitle: 'Curseur',
      helpText: 'Faites glisser le curseur à la bonne position',
    },
    poll: {
      questionTitle: 'Sondage',
      resultinfo: 'This is what others have answered',
      doneText: 'Sondage terminé',
      helpText: 'Choisir une réponse',
    },
    notification: {
      doneText: 'Notification lue',
      title: 'Notification',
      pauze: 'Puis-je continuer ?',
      continueanyway: 'Continuer malgré tout',
      continueLearningText:
        'Vous vous exercez avec des questions supplémentaires. Vous recevrez moins de points pour celles-ci car vous êtes déjà à jour.',
      panelbuttonlabel: "Arrêter l'exercice supplémentaire",
    },
    multipleselect: {
      correct: '{{num}} de {{max}} correct',
      incorrect: ', {{incorrect}} incorrect(s)',
      doneText: 'Question terminée',
      questionTitle: 'Question',
      helpText: 'Choisir une ou plusieurs réponses',
    },
    multiplechoice: {
      doneText: 'Question terminée',
      questionTitle: 'Question',
      helpText: 'Choisir une réponse',
    },
    duelItem: {
      duel: 'Duel',
      draw: 'Match nul',
      invite: 'Invitation à un duel',
      acceptDuel: 'Accepter duel',
      declineDuel: 'Refuser duel',
      yourTurn: 'À vous de jouer',
      opponentTurn: "Tour de l'adversaire",
      youWon: 'Vous avez gagné !',
      youLost: 'Vous avez perdu',
      cancelled: 'Le duel est annulé',
      closeItem: 'Fermer',
      showDetails: 'Détails de la partie',
      playItem: 'Jouer partie {{roundnumber}}',
      duelAccepted: 'Vous avez accepté une invitation à un duel de {{name}}.',
      duelDeclined: 'Vous avez refusé une invitation à un duel de {{name}}.',
      duelStarted: 'Que le duel avec {{name}} commence. Bonne chance !',
      duelInfoInvitation: 'Vous avez été défié(e) en duel.',
      duelInfoMain:
        'Une courte bataille des connaissances en 5 parties. Pour gagner, répondez vite et bien. Gagnez le plus de parties, remportez le duel et augmentez votre évaluation. La limite pour jouer une partie est de 72 heures.',
      viewInvitation: "Voir l'invitation",
      versusLabel: 'contre',
      started: 'Début de la partie',
      doneText: 'Duel terminé',
      tooLate: 'Délai dépassé !',
      practiceWhileWaiting: 'Pratique en attendant',
      practice: 'Continuez à pratiquer',
      subheading: {
        waitForOpponent: 'Attendez que votre adversaire réponde',
        yourTurn: 'Es-tu prêt?',
        timeOutOpponent: 'Votre adversaire a tardé à réagir',
        timeOutPlayer: 'Vous avez pris trop de temps pour répondre',
        timeOutBoth: 'Vous avez pris trop de temps à réagir tous les deux',
        wellDone: 'Bien fait, continuez comme ça!',
        betterNextTime: 'Plus de chance la prochaine fois',
      },
      roundNumber: 'Tour {{number}}',
      duelRequest: 'Nouveau duel !',
      duelRequestText: '{{name}} vous défie en duel',
      tooLateTurn: 'Trop tard',
    },
    topicPin: {
      title: 'Épingler un sujet',
      pinenabled:
        "Vous avez épinglé {{- topiclabel}} et ne recevez donc que des éléments à ce sujet. Les éléments associés à ce sujet sont répétés plus souvent de manière à ce que vous puissiez vous exercer. Appuyez une nouvelle fois sur le sujet épinglé pour obtenir également les questions relatives à d'autres sujets.",
      pindisabled:
        "Le sujet n'est plus épinglé. À partir de maintenant, vous recevez à nouveau des éléments sur tous les sujets.",
      continue: 'Continuer',
      dontshowagain: 'Ne plus afficher',
      selectTopics: 'Sélectionner les rubriques',
      selectTopicsPractice:
        'Sélectionner les rubriques avec lesquelles vous souhaitez vous exercer.',
    },
    topicInfoHeader: 'Rubriques',
  },
  login: {
    succesful: 'Connexion réussie.',
  },
  menu: {
    menu: 'Menu',
    duel: 'Duel',
    duels: 'Duels',
    newDuel: 'Nouveau duel',
    activeDuels: 'Duels actifs',
    duelVersus: 'VS',
    ranking: 'Classement du duel',
    rating: 'Évaluation du duel',
    profile: 'Profil',
    autoDuelLabel: 'Duels automatiques',
    'help-and-settings': 'Aide et paramètres',
    challengeplayer: 'Défier un(e) collègue',
    pickplayer: 'Choisir un collègue',
    randomplayer: 'J’ai de la chance',
    duelWaiting: 'Recherche d’adversaires…',
    quickDuelFailed: 'Vous avez déjà des invitations en attente',
    duelsNotAvailable: 'Les duels ne sont pas encore disponibles pour vous.',
    duelsNotAvailableExplanation:
      'Souhaitez-vous défier vos collègues en duel ? Faites-en la demande auprès de votre superviseur.',
    duelsNotEnabled:
      'Souhaitez-vous défier vos collègues en duel ? Activez l’option de duel ci-dessous.',
    duelsNotEnabledDisclaimer:
      'Vos collègues pourront voir votre photo et votre nom.',
    enableDuelling: 'Activez le duel avec vos collègues !',
    duellingEnabledLabel: 'Participer à des duels',
    completedDuels: 'Effectué',
    select: 'Sélectionner',
    settings: 'Paramètres',
    practice: 'S’exercer',
    aboutDuels: {
      heading: 'Qu’est-ce qu’une invitation à un duel ?',
      explanation1: 'Vous avez été défié en duel.',
      explanation2:
        'Un duel est un bref questionnaire des connaissances en 5 tours face à un collègue. Vous gagnez le tour si vous répondez correctement à la question, plus vite que votre collègue. Si vous gagnez plus de tours que votre adversaire, vous gagnez le duel et montez dans le classement.',
      attention: 'Remarque :',
      explanation3: 'Effectuez tous les tours en 7 jours !',
    },
    leaveAlert: {
      title: 'Êtes-vous sûr(e) ?',
      text: '<p>Êtes-vous sûr(e) de vouloir arrêter de vous exercer ?</p><p>Vos progrès ont été enregistrés.</p>',
      confirm: 'Arrêter',
      continue: 'Continuer à s’exercer',
    },
    info: 'Info',
  },
  ranking: {
    title: 'Classement du duel',
    subtitle:
      'Cette évaluation indique votre classement par rapport à vos collègues.',
    yourPosition: 'Votre position ({{rank}})',
    topPositions: 'Premières positions',
    loadMore: 'Voir la suite',
    empty: 'Il n’y a pas de classement. Commencez un duel !',
    notRanked: 'Terminez un duel pour figurer dans le classement !',
  },
  searchFeedback: {
    title: 'Chercher collègue',
    oneResult: '{{amount}} collègue trouvé(e).',
    searchMessage: '{{amount}} collègues trouvé(e)s.',
    overflowMessage:
      'Seuls les {{amount}} meilleurs résultats correspondants de la recherche sont affichés.',
  },
  searchDuel: {
    title: 'Chercher opposant(e)',
    rankingposition: 'Position au classement {{rank}}',
    noduelpossible: 'Duel impossible',
    duelstillactive: 'Duel déjà activé',
    invitationsentondate: 'Invitation envoyée le {{date}}',
    searchPlaceholder: 'Chercher un nom',
    randomOpponent:
      'Nous cherchons votre prochain adversaire. Vous pouvez fermer cet écran. Nous vous informerons lorsque votre duel sera prêt.',
    emptyListSearch: 'Votre recherche n’a donné aucun résultat. ',
    emptyList:
      'Malheureusement, personne n’est disponible.<br>Réessayez plus tard. ',
  },
  error: {
    internetdown: 'Connexion Internet interrompue !',
    versionConflict: {
      title: 'Nouvelle version',
      text: 'Une nouvelle version de cette application est disponible. Nous allons vous mettre à jour maintenant vers la version la plus récente afin de nous assurer que tout le monde utilise la dernière version. ',
      refresh: 'Aller à la version la plus récente ',
    },
    refreshButton: 'Cliquer ici pour actualiser',
    internetup: 'Connecté à Internet.',
    timeoutajax: 'Délai demande serveur dépassé.',
    404: 'Serveur introuvable (HTTP 404).',
    500: 'Erreur interne serveur (HTTP 500).',
    unknown: 'Erreur inconnue',
    tryReconnect: 'Tentative de reconnexion en cours',
    alreadyAnswered: 'Vous avez déjà répondu à cet élément.',
    unavailableOffline: {
      title: 'Aucune connexion',
      subtitle: 'Il n’y a actuellement aucune connexion',
      explanation:
        'Une connexion au serveur est nécessaire pour utiliser l’application. Il n’y a actuellement aucune connexion. Vérifiez si vous pouvez vous reconnecter à Internet, ou réessayez plus tard.',
    },
  },
  activeDuels: {
    title: 'Jouer à un duel disponible.',
    subtitle: '{{num}}/{{max}} duels sont actifs',
    status: {
      draw: 'Match nul',
      lost: 'Perdu',
      won: 'Gagné',
      expired: 'Expiré',
      invited: 'Invité',
      waiting: 'En attente',
      yourturn: 'Jouer',
    },
    emptyListNew: 'Aucun duel. Commencez-en un nouveau !',
    emptyListCompleted:
      'Vous avez terminé tous les duels, félicitations !<br>Dégustez une bonne tasse de café en guise de récompense. ',
  },
  completedDuels: {
    title: 'Voir les duels que vous avez terminés.',
    empty: 'Vous n’avez terminé aucun duel.<br>Commencez un nouveau duel !',
  },
  common: {
    CheckAnswer: 'Vérifier la réponse',
    CheckAnswers: 'Vérifier les réponses',
    Answer: 'Enregistrer ma réponse',
    Hello: 'Bonjour',
    back: 'Précédent',
    Emailaddress: 'Adresse électronique',
    Name: 'Nom',
    Checking: 'Vérification en cours',
    Close: 'Fermer',
    browsePrevious: 'Précédent',
    browseNext: 'Suivant',
    Accept: 'Accepter',
    'Log out': 'Se déconnecter',
    Help: 'Aide',
    Correct: 'Vrai',
    Incorrect: 'Faux',
    CorrectNotChosen: 'Aurait été correct',
    Round: 'Partie',
    Version: 'Version',
    selected: 'Sélectionné(e)',
    'No Rating': 'Pas encore évalué(e)',
    'No Rank': 'Pas de classement',
    NoUserSelection: 'Utilisateurs introuvables',
    monthNames: 'Jan Fév Mar Avr Mai Jun Jul Aoû Sep Oct Nov Déc',
    or: 'ou',
    on: 'activé',
    off: 'désactivé',
  },
  profile: {
    title: 'Profil',
    subtitle: 'Modifiez votre profil ici.',
    pictureEdit: 'Affichez ou modifiez votre photo.',
    'picture-submit': 'Utilisez cette photo recadrée',
    pictureConfirmDelete:
      'Êtes-vous sûr(e) de vouloir supprimer votre photo de profil ?',
    profilePageTitle: 'Gérer votre profil.',
    noEditDataText: 'Vous ne pouvez pas modifier ces données.',
    'file-submit': 'Charger',
    pictureCancel: 'Annuler',
    'picture-nofileselected': "Vous n'avez pas sélectionné de fichier.",
    'picture-nofile': "Il n'y a pas de fichier.",
    'picture-invalidsize':
      'Cette image est trop grande. La largeur ou hauteur maximale ne peut pas dépasser 2 600 pixels.',
    'picture-notimage':
      "Le fichier que vous avez sélectionné n'est apparemment pas une image.",
    passwordEdit: 'Changer de mot de passe',
    'picture-saved': 'La photo de votre profil est enregistrée !',
    passwordTitle: 'Changer de mot de passe',
    passwordText:
      'Pour changer votre mot de passe, entrez votre mot de passe actuel et 2 fois votre nouveau mot de passe. Le nouveau mot de passe doit comporter au moins 6 caractères.',
    'password-old': 'Mot de passe actuel',
    'password-new': 'Nouveau mot de passe',
    'password-repeat': 'Confirmer nouveau mot de passe',
    'password-submit': 'Enregistrer nouveau mot de passe',
    'password-oldpasswordmismatch': 'Votre mot de passe actuel était incorrect',
    'password-newpasswordmismatch':
      'Le mot de passe confirmé ne correspondait pas.',
    'password-passwordtooshort':
      'Le nouveau mot de passe doit comporter au moins 6 caractères.',
    'password-passwordsaved': 'Votre mot de passe a été enregistré !',
    profilePictureTitle: 'Photo du profil',
    profilePictureText: 'Vos collègues pourront voir cette photo.',
    generalDataTitle: 'Informations personnelles.',
    generalDataText:
      'Vous ne pouvez pas modifier toutes les données personnelles vous-même.',
    profileLanguagePreference: 'Préférence linguistique',
    profileLanguageChoice: 'Choix de la langue',
  },
  auth: {
    header: {
      login: 'Connexion',
      expired: 'Lien expiré',
      remind: 'Vous avez oublié votre mot de passe ?',
      create: 'Activer votre compte',
      reset: 'Mettre à jour votre mot de passe',
      welcome: 'Bienvenue !',
    },
    field: {
      email: 'Adresse électronique',
      password: 'Mot de passe',
      oldPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      newRepeatPassword: 'Répéter le nouveau mot de passe',
      rememberMe: 'Se souvenir de moi',
    },
    action: {
      itemcompleted: 'Vous avez terminé un élément',
      updateprofile: 'Mettre à jour le profil',
      sendLink: 'Envoyez-moi un lien pour réinitialiser mon mot de passe',
      backToLogin: 'Je souhaite me connecter',
      sendActivateLink: 'Activer le compte',
      alreadyActivatedLogin:
        'J’ai déjà activé le compte et je souhaite me connecter',
      savePassword: 'Enregistrer mon nouveau mot de passe',
      newPassword: 'Votre nouveau mot de passe',
      repeatNewPassword: 'Répéter votre nouveau mot de passe',
      newPasswordPolicyIntro: 'Force du mot de passe',
    },
    selfActivate: {
      header: 'Activer votre compte',
    },
    logintext: 'Connexion',
    remindText:
      'Aucun problème. Nous vous enverrons un e-mail pour que vous puissiez configurer un nouveau mot de passe.',
    resetText: 'Vous pouvez définir votre nouveau mot de passe ici.',
    createText:
      'Bienvenue ! Créez un mot de passe, et vous serez prêt(e) à vous connecter.',
    passwords: {
      user: 'Désolé, impossible de trouver un utilisateur avec cette adresse électronique.',
      token: 'Ce jeton de réinitialisation du mot de passe n’est pas valide.',
      sent: ' Un e-mail contenant un lien de réinitialisation vous a été envoyé.',
      reset: 'Votre mot de passe a été réinitialisé !',
      forgot: 'Je ne connais pas mon mot de passe',
      passwordExpiredTitle: 'Mot de passe expiré',
      submitPasswordExpiredForm: 'Enregistrer le nouveau mot de passe',
      passwordExpiredText:
        'Votre mot de passe a plus de {{days}} jours et a expiré. Vous devrez définir un nouveau mot de passe au moyen du formulaire ci-dessous afin de pouvoir continuer à apprendre dans la PowerApp',
    },
  },
  faq: {
    header: 'Questions fréquentes',
    q1: 'La PowerApp est une application de micro-apprentissage. Qu’est-ce que cela signifie ?',
    a1: 'Le micro-apprentissage signifie qu’il est plus efficace d’apprendre par brèves sessions si votre but est de retenir davantage de connaissances avec le temps.',
    q2: 'Pourquoi mon score est-il parfois plus bas que ce à quoi je m’attendais ?',
    a2: 'Votre score diminue, car avec le temps, vous oubliez ce que vous avez appris.',
    q3: 'Quand mon score diminue-t-il ?',
    a3: 'Lorsque vous avez mal répondu à des questions ou lorsque vous avez été inactif/-ve pendant un certain temps.',
    q4: 'Pourquoi les mêmes éléments se répètent-ils ?',
    a4: 'Car la répétition espacée est la meilleure manière d’apprendre.',
    q5: 'Qu’est-ce qu’un duel ?',
    a5: 'Un questionnaire des connaissances en 5 tours, qui vous permet de défier un collègue.',
    q6: 'Pourquoi dois-je attendre mon tour lorsque je joue à un duel ?',
    a6: 'Vous devez attendre que votre collègue ait terminé son tour',
    q7: 'Où puis-je trouver davantage d’informations et des réponses à mes questions ?',
    a7: 'Le bouton ci-dessous vous redirigera vers notre site Web.',
    toTheFaq: 'Vers la FAQ',
    toTheWebsite: 'Vers le site Web',
  },
}

export default translation

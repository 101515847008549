/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DuelFinalizingPopupFinalizeDuelMutationVariables = {
    id: string;
    duelsConnectionIds: Array<string>;
};
export type DuelFinalizingPopupFinalizeDuelMutationResponse = {
    readonly finalizeDuel: {
        readonly result: {
            readonly id: string;
        } | null;
    };
};
export type DuelFinalizingPopupFinalizeDuelMutation = {
    readonly response: DuelFinalizingPopupFinalizeDuelMutationResponse;
    readonly variables: DuelFinalizingPopupFinalizeDuelMutationVariables;
};



/*
mutation DuelFinalizingPopupFinalizeDuelMutation(
  $id: ID!
) {
  finalizeDuel(id: $id) {
    result {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "duelsConnectionIds"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "DuelFinalizingPopupFinalizeDuelMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DuelResult",
                    "kind": "LinkedField",
                    "name": "finalizeDuel",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Duel",
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "DuelFinalizingPopupFinalizeDuelMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DuelResult",
                    "kind": "LinkedField",
                    "name": "finalizeDuel",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Duel",
                            "kind": "LinkedField",
                            "name": "result",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "filters": null,
                                    "handle": "deleteEdge",
                                    "key": "",
                                    "kind": "ScalarHandle",
                                    "name": "id",
                                    "handleArgs": [
                                        {
                                            "kind": "Variable",
                                            "name": "connections",
                                            "variableName": "duelsConnectionIds"
                                        }
                                    ]
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "8ef6b205902d6b1765c38f6190e86290",
            "id": null,
            "metadata": {},
            "name": "DuelFinalizingPopupFinalizeDuelMutation",
            "operationKind": "mutation",
            "text": "mutation DuelFinalizingPopupFinalizeDuelMutation(\n  $id: ID!\n) {\n  finalizeDuel(id: $id) {\n    result {\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '2a37dc61dd435f97ba4b6f422821fcc6';
export default node;
